import React, { useRef } from 'react';
import { FaArrowUp, FaArrowDown, FaRegEdit } from 'react-icons/fa';
import { Dropdown } from 'react-bootstrap';
import { IconButton } from './forms';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FaTrashCan } from 'react-icons/fa6';
import { HiOutlineDocumentDuplicate } from "react-icons/hi2";
import { FaCheckCircle } from "react-icons/fa";
import { GoCircleSlash } from "react-icons/go";
import { formatNumber } from '../../../functions';
import { EVENT_TYPE_LABELS, SCHEDULE_TYPE_LABELS } from '../constant';
import { SUBSCRIPTION_PAGE_FILTER_OBJECT } from '../../subscription/utils';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    ref={ref}
    className="btn btn-link text-decoration-none shadow-none border-0 bg-transparent p-0"
    style={{
      width: '20px',
      minWidth: '20px',
      maxWidth: '20px',
      padding: 0,
      textAlign: 'center'
    }}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <BsThreeDotsVertical className="text-primary" size={20} />
    {children}
  </button>
));

const ScheduleCard = ({
  schedule,
  onMoveUp,
  onMoveDown,
  onEdit,
  onDelete,
  onDuplicate,
  onEnable,
  index,
  isFirst,
  isLast
}) => {
  const cardRef = useRef(null);

  return (
    <div
      className="card mb-2 position-relative"
      style={{ overflow: 'hidden', borderRadius: '0.5rem' }}
      ref={cardRef}
    >
      <div className="card-body d-flex justify-content-between align-items-center p-3">
        <div className="d-flex flex-column">
          <p className={`fw-bold mb-0 ${schedule.isEnable ? 'text-primary' : 'text-secondary'}`} style={{ fontSize: '1rem' }}>
            {schedule.isEnable ? <FaCheckCircle className='pr-1' size={20} /> : <GoCircleSlash className='pr-1' size={22} />}{schedule.subject}
          </p>
          <p className="text-muted mb-0" style={{ fontSize: '0.75rem' }}>
            {SUBSCRIPTION_PAGE_FILTER_OBJECT[schedule.userType]?.title}
          </p>
          <p className="text-muted mb-0" style={{ fontSize: '0.75rem' }}>
            Event: {EVENT_TYPE_LABELS[schedule.eventType]}
          </p>
          <p className="text-muted mb-0" style={{ fontSize: '0.75rem' }}>
            Schedule Type: {SCHEDULE_TYPE_LABELS[schedule.scheduleType]}
          </p>
          <p className="text-muted mb-0" style={{ fontSize: '0.75rem' }}>
            Time: {`${schedule.day} days ${formatNumber(schedule.hour)}:${formatNumber(schedule.minute)}:${formatNumber(schedule.second)}`}
          </p>
        </div>

        <div className="d-flex align-items-center justify-content-between gap-2">
          <div className="d-flex flex-column gap-4 me-2">
            <IconButton onClick={() => onMoveUp(index)} disabled={isFirst}>
              <FaArrowUp className={isFirst ? 'text-muted' : 'text-primary'} />
            </IconButton>
            <IconButton onClick={() => onMoveDown(index)} disabled={isLast}>
              <FaArrowDown className={isLast ? 'text-muted' : 'text-primary'} />
            </IconButton>
          </div>
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id={`dropdown-${schedule._id}`} />
            <Dropdown.Menu align="end" popperConfig={{ strategy: 'fixed' }} style={{ minWidth: 'max-content', padding: '4px 8px 4px 8px' }}>
              <Dropdown.Item onClick={() => onEdit(schedule)}>
                <div className='d-flex align-items-center'>
                  <FaRegEdit size={16} className="pr-1" /><span style={{ fontSize: "14px" }}>Edit</span>
                </div>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => onDuplicate(schedule)}>
                <div className='d-flex align-items-center'>
                  <HiOutlineDocumentDuplicate size={20} className="pr-1" /><span style={{ fontSize: "14px" }}>Duplicate</span>
                </div>
              </Dropdown.Item>
              {
                schedule.isEnable ? (
                  <Dropdown.Item onClick={() => onEnable(schedule, false)}>
                    <div className='d-flex align-items-center text-secondary'>
                      <GoCircleSlash size={18} className="pr-1" /><span style={{ fontSize: "14px" }}>Disable</span>
                    </div>
                  </Dropdown.Item>) : (
                  <Dropdown.Item onClick={() => onEnable(schedule, true)}>
                    <div className='d-flex align-items-center text-primary'>
                      <FaCheckCircle size={20} className="pr-1" /><span style={{ fontSize: "14px" }}>Enable</span>
                    </div>
                  </Dropdown.Item>
                )
              }
              <Dropdown.Item onClick={() => onDelete(schedule)}>
                <div className='d-flex align-items-center text-danger'>
                  <FaTrashCan size={16} className="pr-1" /><span style={{ fontSize: "14px" }}>Delete</span>
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default ScheduleCard;