import React, { useEffect, useState, useRef } from 'react';
import { Table, Badge, Button, Col } from 'react-bootstrap';
import AdminWrapper from '../admin/adminwrapper/wrapper';
import ReactPaginate from 'react-paginate';
import { SearchBar } from '../emailScheduling/components/forms';
import DatePicker from 'react-datepicker';
import './watertracker.css';
import SelectField from '../chatbotSettings/components/selectField';
import { userInstance } from '../../config/axios';
import moment from 'moment';
import { BsThreeDotsVertical } from 'react-icons/bs';
import styled from 'styled-components';
import { Dropdown, Modal, Form } from 'react-bootstrap';
import { FadeLoader } from 'react-spinners';

const StyledModal = styled(Modal)`
	& .modal-content {
		padding-bottom: 0 !important;
		min-height: calc(80% - 2rem);
		height: 450px;
		overflow-y: auto;
		justify-content: center;
		overflow-x: hidden; 
		width: 65%;
		scrollbar-width: none;
		border-radius: 12px !important;
		border: none;
    padding-left: 16px;
	}
	& .modal-dialog {
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		margin: 1rem auto;
	}
  & .modal-header .close {
    width: max-content;
    padding-right: 16px;
  }
	/* Prevent body scrolling when modal is open */
	&.show {
		overflow: hidden !important;
		padding-left: 4px !important;
	}
`;

const WaterTracker = () => {
	const [search, setSearch] = useState('');
	const [frequency, setFrequency] = useState('All Test Frequencies');
	const [userType, setUserType] = useState('All User Types');
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [userList, setUserList] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [totalCount, setTotalCount] = useState(0);
	const [showModal, setShowModal] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);
	const [loadingFlag, setLoadingFlag] = useState(false);
	const limit = 10;
	const tableRef = useRef();
	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<button
			ref={ref}
			className='btn btn-link text-decoration-none shadow-none border-0 bg-transparent p-0'
			style={{
				width: '20px',
				minWidth: '20px',
				maxWidth: '20px',
				padding: 0,
				textAlign: 'center',
			}}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}>
			<BsThreeDotsVertical
				className='text-primary'
				size={20}
			/>
			{children}
		</button>
	));

	const handleModal = (index) => {
		setSelectedUser(userList[index]);
		setShowModal(true);
	};
	useEffect(() => {
		setCurrentPage(0);
	}, [search, startDate, endDate, userType, frequency]);
	useEffect(() => {
		setLoadingFlag(true);
		const getUserList = async () => {
			const user = userType === 'All User Types' ? '' : userType;
			const freq = frequency === 'All Test Frequencies' ? '' : frequency;
			const response = await userInstance().get(
				`/watertest/tracking?search=${search}&startDate=${startDate}&endDate=${endDate}&userTypes=${user}&frequency=${freq}&limit=${limit}&page=${currentPage + 1
				}`
			);
			setUserList(response.data.data);
			setTotalCount(response.data.total);
			setLoadingFlag(false);
		};

		getUserList();
	}, [currentPage, search, startDate, endDate, userType, frequency]);
	const frequencyList = [
		{
			id: 'daily-tester',
			label: 'Daily Tester',
			value: 'Daily Tester',
		},
		{
			id: 'weekly-tester',
			label: 'Weekly Tester',
			value: 'Weekly Tester',
		},
		{
			id: 'inactive-tester',
			label: 'Inactive Tester',
			value: 'Inactive Tester',
		},

		{
			id: 'all-test-frequencies',
			label: 'All Test Frequencies',
			value: 'All Test Frequencies',
		},
	];

	const userTypeList = [
		{
			id: 'subscriber',
			label: 'Subscriber',
			value: 'subscriber',
		},
		{
			id: 'trialist',
			label: 'Trialist',
			value: 'trialist',
		},
		{
			id: 'all-user-type',
			label: 'All User Types',
			value: 'All User Types',
		},
	];

	const getTestResultBadge = (trend) => {
		switch (trend) {
			case 'Stable':
				return 'secondary';
			case 'Improving':
				return 'success';
			case 'Declining':
				return 'danger';
			default:
				return 'light';
		}
	};

	const getStatusBadge = (status) => {
		switch (status) {
			case 'Active':
				return 'success';
			case 'Inactive':
				return 'warning';
			case 'No Tests':
				return 'secondary';
			default:
				return 'light';
		}
	};

	const handleChangeFrequency = (frequency) => {
		setFrequency(frequency);
	};
	const handleChangeUserTypes = (userType) => {
		setUserType(userType);
	};

	const handlePageClick = ({ selected }) => {
		setCurrentPage(selected);
	};

	return (
		<AdminWrapper>
			<h3 className='p-4'>Water Test Tracking</h3>

			<div className='container-fluid px-4 d-flex flex-column'>
				<div className='filter-date d-flex flex-row gap-2 py-4'>
					<SearchBar
						placeholder='Search by name, email'
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						className='w-100'
					/>

					<DatePicker
						dateFormat='d MMM, yyyy'
						selected={startDate}
						onChange={(date) => setStartDate(date)}
					/>
					<DatePicker
						dateFormat='d MMM, yyyy'
						selected={endDate}
						onChange={(date) => setEndDate(date)}
					/>

					<SelectField
						data={frequencyList}
						selectedValue={frequency}
						handleChangeSelect={handleChangeFrequency}
					/>
					<SelectField
						data={userTypeList}
						selectedValue={userType}
						handleChangeSelect={handleChangeUserTypes}
					/>
				</div>
				{loadingFlag && <FadeLoader width={3} height={12} className='fade-loader' color='#0077c0' />}
				{userList.length && !loadingFlag ? (
					<>
						<Table
							ref={tableRef}
							striped
							bordered
							hover
							responsive>
							<thead style={{ position: 'sticky', top: '0', zIndex: '10' }}>
								<tr className='text-center'>
									<th className='text-left'>Name</th>
									<th className='text-left'>User Type</th>
									<th className='text-left'>Total Tests</th>
									<th className='text-left'>Tests This Month</th>
									<th className='text-left'>Test Frequency</th>
									<th className='text-left'>Avg Time Between Tests</th>
									<th className='text-left'>Last Test Date</th>
									<th className='text-left'>Test Result Trend</th>
									<th className='text-left'>Status</th>
									<th className='text-left'>Action</th>
								</tr>
							</thead>
							<tbody>
								{userList.length &&
									userList.map((user, index) => (
										<tr
											className='text-center'
											style={{ height: '56px' }}
											key={index}>
											<td className='text-left align-content-center'>
												{user.name}
											</td>
											<td className='align-content-center'>{user?.trial === 'trial' ? 'Trialist' : 'Subscriber'}</td>
											<td className='align-content-center'>
												{user.totalTests}
											</td>
											<td className='align-content-center'>
												{user.testsThisMonth}
											</td>
											<td className='align-content-center'>
												{user.testFrequency}
											</td>
											<td className='align-content-center'>
												{user.avgTimeBetweenTests} Days
											</td>
											<td className='align-content-center'>
												{moment(user.lastTestDate).format('YYYY.MM.DD')}
											</td>
											<td className='align-content-center'>
												<Badge
													variant={getTestResultBadge(user.testResultTrend)}>
													{user.testResultTrend}
												</Badge>
											</td>
											<td className='align-content-center'>
												<Badge variant={getStatusBadge(user.status)}>
													{user.status}
												</Badge>
											</td>
											<td className='align-content-center'>
												<Dropdown>
													<Dropdown.Toggle
														as={CustomToggle}
														id={`dropdown-menu`}
													/>
													<Dropdown.Menu
														align='end'
														popperConfig={{ strategy: 'fixed' }}
														style={{
															minWidth: 'max-content',
															padding: '4px 8px 4px 8px',
														}}>
														<Dropdown.Item>
															<div
																className='d-flex align-items-center'
																onClick={() => handleModal(index)}>
																<span style={{ fontSize: '14px' }}>
																	View Detail
																</span>
															</div>
														</Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
											</td>
										</tr>
									))}
							</tbody>
						</Table>
						<div className='user-pagination'>
							<ReactPaginate
								previousLabel='← Previous'
								nextLabel='Next →'
								pageCount={Math.ceil(totalCount / limit)}
								onPageChange={handlePageClick}
								forcePage={currentPage}
								containerClassName='pagination-chk'
								previousLinkClassName='pagination__link'
								nextLinkClassName='pagination__link'
								disabledClassName='pagination__link--disabled'
								activeClassName='pagination__link--active'
								breakLabel='...'
								marginPagesDisplayed={-2}
								pageRangeDisplayed={3}
							/>
						</div>
					</>
				) : (
					!loadingFlag && <p className='text-center'>No Data</p>
				)}
			</div>
			{selectedUser && (
				<StyledModal
					show={showModal}
					centered
					onHide={() => setShowModal(false)}>
					<Modal.Header className='pt-2' closeButton>
						<h5 style={{ marginTop: '36px' }}>User Details</h5>
					</Modal.Header>
					<Modal.Body className='d-flex flex-column gap-3'>
						<Col xs={24}>
							<p>
								<strong>User Information</strong>
							</p>
							<p>Name: {selectedUser.name}</p>
							<p>Email: {selectedUser.email}</p>
							<p>User Type: {selectedUser.userType}</p>
						</Col>
						<Col xs={24}>
							<p>
								<strong>Testing Statistics</strong>
							</p>
							<p>Total Tests: {selectedUser.totalTests}</p>
							<p>Tests This Month: {selectedUser.testsThisMonth}</p>
							<p>Test Frequency: {selectedUser.testFrequency}</p>
							<p>
								Average Time Between Tests: {selectedUser.avgTimeBetweenTests}
							</p>
						</Col>
					</Modal.Body>
				</StyledModal>
			)}
		</AdminWrapper>
	);
};

export default WaterTracker;
