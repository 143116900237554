/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
import moment from "moment-timezone";

let globalTimeZone = "";

export const updateTimeZone = (timeZone) => {
  if (timeZone) globalTimeZone = timeZone;
};

export const getTimeZone = (timeZone, date) =>
  moment(date).tz(timeZone).format("YYYY-MM-DD HH:mm:ss");
export const getTodayTime = (timeZone) =>
  moment().tz(timeZone).format("YYYY-MM-DD HH:mm:ss");
export const getTodayTimeZone = (timeZone) =>
  moment().tz(timeZone).format("YYYY-MM-DDTHH:mm:ssZ");

export const convertDateTime = (date) => moment.parseZone(date);

export const udpateTime = (date, time, timeobj, addDays) => {
  let splitTime = time.split(":");
  const start = moment.parseZone(date);
  if (addDays) start.add(addDays, "days");
  start.hour(splitTime[0]);
  start.minute(splitTime[1]);
  start.second(0);
  if (timeobj) return start;
  return start.format("YYYY-MM-DDTHH:mm:ssZ");
};

export const updateEndTime = (date, duration, timeobj) => {
  const end = date.minute(date.minutes() + parseFloat(duration));
  if (timeobj) return end;
  return end.format("YYYY-MM-DDTHH:mm:ssZ");
};
export const updateStartTime = (date, duration) =>
  date.minute(date.minutes() + parseFloat(duration));
export const addTimeZone = (timeZone, date, time) => {
  const splitDate = date.split("T")[0];
  // Combine date, time, and time zone
  const dateTimeWithTimeZone = moment.tz(`${splitDate} ${time}:00`, timeZone);
  return dateTimeWithTimeZone;
};

export const displayTime = (timeZone, startTime, timeDisplayFormat) => {
  const time = timeDisplayFormat
    ? moment(startTime).tz(timeZone).format(timeDisplayFormat)
    : moment(startTime).tz(timeZone).format("HH:mm");
  return time;
};

export const getTimeZone2 = (timeZone, date) =>
  moment(date).tz(timeZone).format("YYYY-MM-DDTHH:mm:ssZ");

export const mutateAppointmentServices = (
  appointmentArr,
  timeZone,
  dbTimeZone,
) => {
  if (dbTimeZone === timeZone) return appointmentArr;
  const updatedAppointment = appointmentArr?.map((elem) => {
    elem.date = convertDateTime(elem.date).format("YYYY-MM-DD");
    elem.service = elem.service.map((el) => {
      el.start = getTimeZone2(timeZone, el.start);
      el.end = getTimeZone2(timeZone, el.end);
      el.startTime = displayTime(timeZone, el.start);
      el.endTime = displayTime(timeZone, el.end);
      return el;
    });
    return elem;
  });
  return updatedAppointment;
};

export const calculateRemainingMonths = (startDate, endDate, currentDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const current = new Date(currentDate);
  let totalMonths =
    (end.getFullYear() - start.getFullYear()) * 12 +
    (end.getMonth() - start.getMonth());

  let usedMonths =
    (current.getFullYear() - start.getFullYear()) * 12 +
    (current.getMonth() - start.getMonth());

  if (current.getDate() > start.getDate()) {
    usedMonths += 1;
  }

  const remainingMonths = totalMonths - usedMonths;

  return remainingMonths > 0 ? remainingMonths : 0;
};

export const formatDate = (date) => {
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, "0");
  const month = d.toLocaleString("en-US", { month: "short" }); // Short month name (e.g., "Nov")
  const year = d.getFullYear();

  return `${day}-${month}-${year}`;
};

export const formatDateWithTime = (date) => {
  const d = new Date(date);
  let formatedDate = moment(d).format('DD MMM YYYY');
  let formatedTime = moment(d).format('h:mma');
  return { formatedDate, formatedTime };
};
