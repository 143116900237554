export const UNSUBSCRIBE_STEP = {
  CONFIRM: 0,
  FEEDBACK: 1,
  FINAL: 2
}

export const FEEDBACK_EDITOR_ID = "zQ3vH1dM8j";

export const FEEDBACK_OPTIONS = [
  {
    id: "jK8lZxP0qN",
    content: "I receive too many emails",
  },
  {
    id: "xV4bL5wK9y",
    content: "The content is not relevant to me"
  },
  {
    id: "pD9sJ6zA3v",
    content: "I made a purchase and no longer need updates"
  },
  {
    id: "gR2xK4lP7n",
    content: "I prefer not to receive promotional emails"
  },
  {
    id: FEEDBACK_EDITOR_ID,
    content: "Others:"
  },
]



export const RESUBSCRIBE_URL = "https://poolwater-pro.com/10-days-free-trial-no-card-required/?open=subscribe-now";
export const PRIVACY_POLICY_URL = "https://poolwater-pro.com/privacy-policy/";