import React, { useState, useEffect, useContext } from "react";
import { Dropdown } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useHistory, useLocation } from "react-router-dom";
import Adminwrapper from "../admin/adminwrapper/wrapper";
import showmore from "../../assets/home/showmoreblue.png";
import target from "../../assets/home/icon/target.png";
import InvoiceDetail from "./invoicedetail";
import "./invoice.css";
import "./web.css";
// import arrow from '../../assets/home/arrowdown.png';
import InvoicesList from "./invoicesList";
import SearchInvoices from "./searchInvoices";
import { userInstance } from "../../config/axios";
import NewInvoice from "./newInvoice";
import { queryString, getCalendarPath } from "../../functions/index";
import SearchPayments from "../payments/searchpayment";
import PaymentList from "./paymentList";
import EditInvoice from "./editInvoice";
import { AuthDataContext } from "../../contexts/AuthDataContext";
import Loader from "../loader/loader";
import InvoiceDetailsOption from "./invoiceDetailsOption";
import PendingInvoicesList from "./pendingInvoices";
// import Quotes from './quotes';
// import PreviewInvoicePopup from './previewInvoicePopup';

const useQuery = () => new URLSearchParams(useLocation().search);
const Invoice = () => {
  const { client, to, from, typeRoute, namee, skipValue, clienttype, type } =
    queryString();
  const query = useQuery();
  const country = query.get("country");
  const invId = localStorage.getItem("invId");
  const history = useHistory();
  const { userData } = useContext(AuthDataContext);
  const [invoiceDetail, setInvoiceDetail] = useState(false);
  const [allInvoices, setAllInvoices] = useState({});
  const [allPayments, setAllPayments] = useState([]);
  const [detailedInvoice, setDetailedInvoice] = useState({});
  const [skip, setSkip] = useState(0);
  const [pageCounts, setPageCounts] = useState(1);
  const limit = 4;
  const [skip2, setSkip2] = useState(0);
  const [pageCounts2, setPageCounts2] = useState(1);
  const limit2 = 4;
  const [view, setView] = useState(to || "invoiceList");
  const [showInvoice, setShowInvoice] = useState(false);
  const [loader, setLoader] = useState(true);
  const [invoice, setInvoice] = useState({});
  const [invoiceData, setInvoiceData] = useState({});
  const [activeLink, setActiveLink] = useState("invoiceList");
  const searchType = "search";
  const CurrencyBycountry = country === "Australia" ? "AU" : "US";
  const handleInvoicePopup = () => {
    setShowInvoice(!showInvoice);
  };
  const updateView = (key) => {
    setView(key);
    setActiveLink(key);
    setInvoiceDetail(false);
  };

  const openDirectInvoice = () => {
    getInvoiceById(invId);
    setLoader(false);
  };

  useEffect(() => {
    if (invId) {
      openDirectInvoice();
    }
  }, []);
  useEffect(() => {
    if (clienttype) {
      setView("newInvoice");
    }
  }, [clienttype]);
  useEffect(() => {
    if (type === "newInvoice") {
      setView("newInvoice");
    }
    if (type === "paymentList") {
      setView("paymentList");
    }
  }, [type]);

  const renderView = () => {
    let componentToRender = null;
    switch (view) {
      case "invoiceList":
        componentToRender = !invoiceDetail ? (
          <InvoicesList
            handleInvoice={handleInvoice}
            allInvoices={allInvoices}
            handlePageClick={handlePageClick}
            pageCount={pageCount}
            country={CurrencyBycountry}
          />
        ) : (
          <EditInvoice
            invoiceData={detailedInvoice}
            setEditInvoice={setInvoiceDetail}
            customerAllDue={customerAllDue}
            searchType={searchType}
            frompage="invoice"
          />
        );
        break;
      case "searchinvoices":
        componentToRender = !invoiceDetail ? (
          <SearchInvoices handleInvoice={handleInvoice} />
        ) : (
          <EditInvoice
            invoiceData={detailedInvoice}
            setEditInvoice={setInvoiceDetail}
            customerAllDue={customerAllDue}
            searchType={searchType}
            frompage="invoice"
          />
        );
        break;
      case "invoicedetail":
        componentToRender = <InvoiceDetail />;
        break;
      case "newInvoice":
        componentToRender = (
          <NewInvoice
            setLoader={setLoader}
            setView={setView}
            updateData={updateData}
            clienttype={clienttype}
          />
        );
        break;
      case "paymentList":
        componentToRender = (
          <PaymentList
            handleInvoice={goToPayments}
            allInvoices={allPayments}
            handlePageClick={handlePageClick2}
            pageCount={pageCount2}
          />
        );
        break;
      case "searchpayments":
        componentToRender = <SearchPayments />;
        break;
      case "chemicalDetails":
        componentToRender = <InvoiceDetailsOption />;
        break;
      case "pendinginvoces":
        componentToRender = (
          <PendingInvoicesList
            handleInvoice={handleInvoice}
            allInvoices={allInvoices}
            handlePageClick={handlePageClick}
            pageCount={pageCount}
            setView={setView}
          />
        );
        break;
      default:
        break;
    }
    return componentToRender;
  };

  const getInvoices = async () => {
    try {
      const response = await userInstance().get(
        `/transaction/getPaymentListById?id=${client}`,
      );
      let _allInvoices = response.data?.paymentdetail ? response.data?.paymentdetail : [];
      setAllInvoices(_allInvoices);

      // setPageCounts(count);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  const getPayments = async () => {
    try {
      const response = await userInstance().get(
        `/payment/getPayments?limit=${limit2}&skip=${skip2}&client=${client}`,
      );
      const { payments, count } = response.data;
      // console.log('payments', count);
      setAllPayments(payments);
      setPageCounts2(count);
    } catch (error) {
      console.log(error);
      //     console.log(error);
    }
  };
  const goToPayments = (info) => {
    // window.location.href = `/invoicespayment?data=${info}&from=invoices`;
    history.push(`/invoicespayment?data=${info}&from=invoices`);
  };

  useEffect(() => {
    if (view === "invoiceList") {
      getInvoices();
    }
  }, [skip, limit, view]);

  useEffect(() => {
    getPayments();
  }, [skip2, limit2]);

  const pageCount = Math.ceil(pageCounts / limit);
  const handlePageClick = ({ selected }) => {
    setSkip(selected * limit);
  };

  const pageCount2 = Math.ceil(pageCounts2 / limit2);
  const handlePageClick2 = ({ selected }) => {
    setSkip2(selected * limit2);
  };

  const handleInvoice = (id) => {
    setLoader(true);
    getInvoiceById(id);
    // history.push(`/approvalappointments?clientid=${clientid}&viewtype=detailPage&from=${from}`);
  };
  const [customerAllDue, setCustomerAllDue] = useState(0);
  const getInvoiceById = async (data) => {
    try {
      const response = await userInstance().get(
        `/invoice/getInvoiceById?id=${data}`,
      );
      const { invoicedetail } = response.data;
      setDetailedInvoice(invoicedetail);
      const balancresponse = await userInstance().get(
        `/invoice/getCustomerDues?id=${invoicedetail?.customer?._id}`,
      );
      const { totalBalanceDue } = balancresponse.data;
      setCustomerAllDue(totalBalanceDue);
      if (response.status === 200) {
        setLoader(false);
        setInvoiceDetail(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGoBack = () => {
    history.goBack();
  }

  const moveToApproval = () => {
    const url = client
      ? `/approvalappointments?clientid=${client}`
      : "/approvalappointments";
    history.push(url);
  };

  const updateData = () => {
    console.log("helllo");
  };

  return (
    <Adminwrapper>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className=" pool-set-up invoices-list new-invoice">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  {client && (
                    <div className="back-option">
                      <span />

                      <i
                        className="fa fa-times"
                        onClick={() => handleGoBack()}
                        aria-hidden="true"
                      />
                    </div>
                  )}
                  <div className="main-titles-mob">
                    <h3>Payments</h3>
                    {/* <div className="content-dropdown">
                      <div className="client-option service-edit-icon">
                        <Dropdown>
                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <img src={showmore} alt="show" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item>
                              <div className="action" onClick={() => updateView('newInvoice')}>
                                <span>
                                  <img src={target} alt="edit" />
                                </span>
                                <span> New Invoice</span>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <div className="action" onClick={() => moveToApproval()}>
                                <span>
                                  <img src={target} alt="edit" />
                                </span>
                                <span> Awaiting Send Approval</span>
                              </div>
                            </Dropdown.Item>

                            <Dropdown.Item>
                              <div className="action" onClick={() => updateView('pendinginvoces')}>
                                <span>
                                  <img src={target} alt="edit" />
                                </span>
                                <span>Pending Invoices</span>
                              </div>
                            </Dropdown.Item>

                            <Dropdown.Item>
                              <div className="action" onClick={() => updateView('invoiceList')}>
                                <span>
                                  <img src={target} alt="edit" />
                                </span>
                                <span> Saved Invoices</span>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <div className="action" onClick={() => updateView('paymentList')}>
                                <span>
                                  <img src={target} alt="edit" />
                                </span>
                                <span> Receive Payments</span>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <div className="action" onClick={() => updateView('searchinvoices')}>
                                <span>
                                  <img src={target} alt="edit" />
                                </span>
                                <span> Search Invoices</span>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <div className="action" onClick={() => updateView('searchpayments')}>
                                <span>
                                  <img src={target} alt="edit" />
                                </span>
                                <span>Search Payments</span>
                              </div>
                            </Dropdown.Item>
                            {userData?.industryId?.name === 'Pool Servicing' && (
                            <>
                              <Dropdown.Item>
                                <div className="action" onClick={() => updateView('chemicalDetails')}>
                                  <span>
                                    <img src={target} alt="edit" />
                                  </span>
                                  <span> Chemicals Detail to Display in Invoices</span>
                                </div>
                              </Dropdown.Item>
                            </>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div> */}
                  </div>
                  <div className="main-titles-desktop">
                    <div className="dropdown-option-section">
                      <div
                        className={`option-list ${activeLink === "newInvoice" ? "active-menu" : ""}`}
                        onClick={() => updateView("newInvoice")}
                      >
                        <img src={target} alt="edit" />
                        New Invoice
                      </div>
                      <div
                        className={`option-list ${activeLink === "moveToApproval" ? "active-menu" : ""}`}
                        onClick={() => moveToApproval()}
                      >
                        <img src={target} alt="edit" />
                        Awaiting Send Approval
                      </div>
                      <div
                        className={`option-list ${activeLink === "pendinginvoces" ? "active-menu" : ""}`}
                        onClick={() => updateView("pendinginvoces")}
                      >
                        <img src={target} alt="edit" />
                        Pending Invoices
                      </div>
                      <div
                        className={`option-list ${activeLink === "invoiceList" ? "active-menu" : ""}`}
                        onClick={() => updateView("invoiceList")}
                      >
                        <img src={target} alt="edit" />
                        Saved Invoices
                      </div>
                      <div
                        className={`option-list ${activeLink === "paymentList" ? "active-menu" : ""}`}
                        onClick={() => updateView("paymentList")}
                      >
                        <img src={target} alt="edit" />
                        Receive Payments
                      </div>
                      <div
                        className={`option-list ${activeLink === "searchinvoices" ? "active-menu" : ""}`}
                        onClick={() => updateView("searchinvoices")}
                      >
                        <img src={target} alt="edit" />
                        Search Invoices
                      </div>
                      <div
                        className={`option-list ${activeLink === "searchpayments" ? "active-menu" : ""}`}
                        onClick={() => updateView("searchpayments")}
                      >
                        <img src={target} alt="edit" />
                        Search Payments
                      </div>
                      <div
                        className={`option-list ${activeLink === "chemicalDetails" ? "active-menu" : ""}`}
                        onClick={() => updateView("chemicalDetails")}
                      >
                        <img src={target} alt="edit" />
                        Chemicals Detail to Display in Invoices
                      </div>
                    </div>
                  </div>
                  {renderView()}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Adminwrapper>
  );
};
export default Invoice;
