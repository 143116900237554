import { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import axios from "axios";
import ChatBot from "../chatBot";
import { chatPath } from "../../config/keys";
import NoLoginHeader from "../header/noLoginHeader";
import Headroom from "react-headroom";
import { Helmet } from "react-helmet";
import calendar from '../../assets/home/icon/icon-1.png';
import reports from '../../assets/home/icon/icon-2.png';
import alert from '../../assets/home/icon/bell-icon.png';
import calculator from '../../assets/home/icon/icon-3.png';
import reportsblue from '../../assets/home/icon-2.png';
import alertblue from '../../assets/home/bell-icon-blue.png';
import calculatorblue from '../../assets/home/icon-3.png';
import calendarblue from '../../assets/home/icon-1.png';

const defaultChatbotInfos = {
  name: "HelpBot",
  avatar:
    "https://poolwater-pro.com/wp-content/uploads/2024/05/bot-e1716661303394.jpeg",
  greeting:
    "Hello!\nAsk me any questions about our\nPoolWater-Pro software, or get\nhelp to solve your pool issues.",
};

const NoLoginChatbot = () => {
  let history = useHistory();
  const [countryName, setCountryName] = useState(null);
  const [chatbotInfos, setChatbotInfos] = useState(defaultChatbotInfos);

  const handleClose = () => {
    history.push('/login');
  }

  const getLocation = async () => {
    try {
      const response = await axios.get("https://ipapi.co/json/");
      setCountryName(response.data.country_name);
    } catch (err) {
      console.log("Unable to fetch detailed location information.");
    }
  };

  const getAISettings = async () => {
    try {
      const response = await axios.get(
        `${chatPath}/chatbotSettings/getChatbotInfos?scope=webapp`,
      );
      if (response.status === 200) {
        setChatbotInfos(response.data.chatbotInfos);
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    getLocation();
    getAISettings();
  }, []);

  return (

    <div className="wrapper">
      <Headroom>
        <div className="header-view">
          <NoLoginHeader />
        </div>
      </Headroom>
      {
        countryName !== null &&
        <div>
          <div className="chatbot-popup-overlap" onClick={handleClose} />
          <ChatBot
            handleClose={handleClose}
            countryName={countryName}
            chatbotInfos={chatbotInfos}
          />
        </div>
      }
      <div className="footer-wrapper">
        <Headroom>
          <Helmet>
            <script src='inobounce.js' />
          </Helmet>
          <div className='sticky-footer'>
            <div className='sticky-content'>
              {history.location.pathname === '/calendar' ? (
                <div className="calendar">
                  <img src={calendar} alt='calendar' />
                  <img src={calendarblue} alt='calendar' />
                  <h6>Today</h6>
                </div>
              ) : (
                <div className="calendar">
                  <Link to="/calendar">
                    <img src={calendar} alt='calendar' />
                    <img src={calendarblue} alt='calendar' />
                    <h6>Today</h6>
                  </Link>
                </div>
              )}
              <div className={`calendar  blocked-time`}>
                <Link to='/calculator'>
                  <img src={calculator} alt='calendar' />
                  <img src={calculatorblue} alt='calendar' />
                  <h6>Calculator</h6>
                </Link>
              </div>
              <div className={`calendar`}>
                <img src={alert} alt='calendar' />
                <img src={alertblue} alt='calendar' />
                <h6>Reminders </h6>
              </div>
              <div className={`calendar`}>
                <Link to='/reports'>
                  <img src={reports} alt='calendar' />
                  <img src={reportsblue} alt='calendar' />
                  <h6>Reports</h6>
                </Link>
              </div>
            </div>
          </div>
        </Headroom>
      </div>
    </div>
  )
}

export default NoLoginChatbot;