import React from "react";
import { useHistory } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { UNSUBSCRIBE_STEP } from "./constants";
import "./index.css";
import { appTokenInstance } from "../../config/axios";

const UnsubscribeConfirm = ({
  token,
  setUnsubscribeStep,
  setIsLoading
}) => {
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Add logic for unsubscribe action here
    try {
      setIsLoading(true);
      let response = await appTokenInstance(token).get('/user/email-unsubscribe');
      console.log('response', response.data);

      setUnsubscribeStep(UNSUBSCRIBE_STEP.FEEDBACK);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    history.push("/login");
  };

  return (
    <Form>
      <p className="form-label">Are you sure you want to unsubscribe from your current subscription?</p>
      <div className="unsubscribe-popup-btn">
        <Button
          type="button"
          className="btn btn-edit"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          className="btn btn-primary"
          onClick={handleSubmit}
        >
          Confirm
        </Button>
      </div>
    </Form>
  );
};

export default UnsubscribeConfirm;