/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-quotes */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Nav,
  NavDropdown,
  Button,
  Navbar,
  Container,
} from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import logowhite from '../../assets/home/pool-logo-white.png';
import cancel from '../../assets/home/cross-icon.png';
import openheader from '../../assets/home/hamburger-icon.png';
import openheaderblue from '../../assets/home/hamburger-icon-blue.png';
import mobilelogo from '../../assets/home/pool-white1.png';
import mobilelogoblue from '../../assets/home/pool-blue1.png';
import { getCalendarPath } from '../../functions/index';
import './webfile.css';
import './header.css';

const defaultUserData = {
  "_id": "67a321d56f8dbdd928d933e0",
  "firstLogin": false,
  "isOwner": true,
  "istwilio": false,
  "isNumberVerify": false,
  "isEmail": false,
  "isEmailVerify": false,
  "chemicalsToDisplay": true,
  "email": "nologinuser@gmail.com",
  "accessLevel": "subscriber",
  "firstname": "Joshua",
  "lastname": "Petty",
  "mobileNo": "425156547",
  "userid": "67a321d56f8dbdd928d933db",
  "streetAddress": "Test",
  "suburb": "Test",
  "postcode": "10245",
  "state": "ACT",
  "duration": 0,
  "subscriptionStatus": "trial",
  "numberUsers": 2,
  "numberOfPools": 1,
  "totalCost": 0,
  "startsubscriptionDate": "2025-02-05T08:31:17.519Z",
  "endsubscriptionDate": "2025-02-15T08:31:17.519Z",
  "cardDetails": {},
  "subscriptionId": "sub_1Qp40uJYCAG32lQB8awnDTOh",
  "role": "subscriber",
  "country": "Australia",
  "auth": true
}

const defaultUserPermissionsData = [
  {
    "_id": "63636a5be61b6203b34ae772",
    "level": [
      "low",
      "medium",
      "high"
    ],
    "action": "Calendar",
    "category": "Calendar",
    "class": "main-menu",
    "route": "/calendar",
    "view": "",
    "counter": 2,
    "__v": 0,
    "createdAt": "2022-11-03T07:14:35.613Z",
    "updatedAt": "2022-11-03T07:14:35.613Z"
  },
  {
    "_id": "63636a5be61b6203b34ae773",
    "level": [
      "low",
      "medium",
      "high"
    ],
    "action": "Calculator",
    "category": "Calculator",
    "class": "main-menu",
    "route": "/calculator",
    "view": "",
    "counter": 3,
    "__v": 0,
    "createdAt": "2022-11-03T07:14:35.614Z",
    "updatedAt": "2022-11-03T07:14:35.614Z"
  },
  {
    "_id": "63fde593a8b3df312071bffb",
    "level": [
      "low",
      "medium",
      "high"
    ],
    "action": "Admin",
    "category": "Admin",
    "class": "main-menu",
    "route": "/a",
    "view": "",
    "counter": 3,
    "__v": 0,
    "createdAt": "2022-11-03T07:14:35.614Z",
    "updatedAt": "2022-11-03T07:14:35.614Z"
  },
  {
    "_id": "63636a5be61b6203b34ae774",
    "level": [
      "low",
      "medium",
      "high"
    ],
    "action": "How To Add Chemicals To Your Pool",
    "category": "Calculator",
    "class": "submenu-item",
    "route": "/how-to-add-chemicals-to-your-pool",
    "view": "",
    "counter": 4,
    "__v": 0,
    "createdAt": "2022-11-03T07:14:35.614Z",
    "updatedAt": "2022-11-03T07:14:35.614Z"
  },
  {
    "_id": "63636a5be61b6203b34ae775",
    "level": [
      "low",
      "medium",
      "high"
    ],
    "action": "Permissions & Access Level",
    "category": "Admin",
    "class": "main-menu",
    "route": "/userPermissions",
    "view": "",
    "counter": 5,
    "__v": 0,
    "createdAt": "2022-11-03T07:14:35.614Z",
    "updatedAt": "2022-11-03T07:14:35.614Z"
  },
  {
    "_id": "63636a5be61b6203b34ae776",
    "level": [
      "low",
      "medium",
      "high"
    ],
    "action": "Users",
    "category": "Admin",
    "class": "main-menu",
    "route": "/users",
    "view": "",
    "counter": 6,
    "__v": 0,
    "createdAt": "2022-11-03T07:14:35.614Z",
    "updatedAt": "2022-11-03T07:14:35.614Z"
  },
  {
    "_id": "63636a5be61b6203b34ae777",
    "level": [
      "low",
      "medium",
      "high"
    ],
    "action": "Pool Set-up",
    "category": "Pool Set-up",
    "class": "main-menu",
    "route": "/a",
    "view": "",
    "counter": 7,
    "__v": 0,
    "createdAt": "2022-11-03T07:14:35.614Z",
    "updatedAt": "2022-11-03T07:14:35.614Z"
  },
  {
    "_id": "63cb8f1bf45be8478cccda5b",
    "level": [
      "low",
      "medium",
      "high"
    ],
    "action": "My Pool Size & Chlorination",
    "category": "Pool Set-up",
    "class": "submenu-item",
    "route": "/poolsetup",
    "view": "userPool",
    "counter": 8,
    "__v": 0,
    "createdAt": "2022-11-03T07:14:35.614Z",
    "updatedAt": "2022-11-03T07:14:35.614Z"
  },
  {
    "_id": "63636a5be61b6203b34ae779",
    "level": [
      "low",
      "medium",
      "high"
    ],
    "action": "Calculator Chemicals to Display",
    "category": "Pool Set-up",
    "class": "submenu-item",
    "route": "/poolsetup",
    "view": "chemicalsDisplay",
    "counter": 9,
    "__v": 0,
    "createdAt": "2022-11-03T07:14:35.614Z",
    "updatedAt": "2022-11-03T07:14:35.614Z"
  },
  {
    "_id": "63636a5be61b6203b34ae778",
    "level": [
      "low",
      "medium",
      "high"
    ],
    "action": "Chemistry Target Levels",
    "category": "Pool Set-up",
    "class": "submenu-item",
    "route": "/poolsetup",
    "view": "chemicalTargetLevels",
    "counter": 9,
    "__v": 0,
    "createdAt": "2022-11-03T07:14:35.614Z",
    "updatedAt": "2022-11-03T07:14:35.614Z"
  },
  {
    "_id": "63636a5be61b6203b34ae77a",
    "level": [
      "low",
      "medium",
      "high"
    ],
    "action": "Chemical Costs",
    "category": "Pool Set-up",
    "class": "submenu-item",
    "route": "/poolsetup",
    "view": "chemicalCosts",
    "counter": 10,
    "__v": 0,
    "createdAt": "2022-11-03T07:14:35.614Z",
    "updatedAt": "2022-11-03T07:14:35.614Z"
  },
  {
    "_id": "63636a5be61b6203b34ae77b",
    "level": [
      "low",
      "medium",
      "high"
    ],
    "action": "Chlorinator Model",
    "category": "Pool Set-up",
    "class": "submenu-item",
    "route": "/poolsetup",
    "view": "chlorinatorModels",
    "counter": 11,
    "__v": 0,
    "createdAt": "2022-11-03T07:14:35.615Z",
    "updatedAt": "2022-11-03T07:14:35.615Z"
  },
  {
    "_id": "63636a5be61b6203b34ae77c",
    "level": [
      "low",
      "medium",
      "high"
    ],
    "action": "Drainage Times",
    "category": "Pool Set-up",
    "class": "submenu-item",
    "route": "/poolsetup",
    "view": "drainageSetup",
    "counter": 12,
    "__v": 0,
    "createdAt": "2022-11-03T07:14:35.615Z",
    "updatedAt": "2022-11-03T07:14:35.615Z"
  },
  {
    "_id": "63636a5be61b6203b34ae77d",
    "level": [
      "low",
      "medium",
      "high"
    ],
    "action": "Mineral Pool Settings",
    "category": "Pool Set-up",
    "class": "submenu-item",
    "route": "/poolsetup",
    "view": "calciumAdjustments",
    "counter": 13,
    "__v": 0,
    "createdAt": "2022-11-03T07:14:35.615Z",
    "updatedAt": "2022-11-03T07:14:35.615Z"
  },
  {
    "_id": "63636a5be61b6203b34ae77e",
    "level": [
      "low",
      "medium",
      "high"
    ],
    "action": "Chemicals Used to Balance Water",
    "category": "Pool Set-up",
    "class": "submenu-item",
    "route": "/poolsetup",
    "view": "chemicalBalanceWater",
    "counter": 14,
    "__v": 0,
    "createdAt": "2022-11-03T07:14:35.615Z",
    "updatedAt": "2022-11-03T07:14:35.615Z"
  },
  {
    "_id": "63636a5be61b6203b34ae77f",
    "level": [
      "low",
      "medium",
      "high"
    ],
    "action": "Green Pool Calculations",
    "category": "Pool Set-up",
    "class": "submenu-item",
    "route": "/poolsetup",
    "view": "greenpoolcalculation",
    "counter": 15,
    "__v": 0,
    "createdAt": "2022-11-03T07:14:35.615Z",
    "updatedAt": "2022-11-03T07:14:35.615Z"
  },
  {
    "_id": "63636a5be61b6203b34ae780",
    "level": [
      "low",
      "medium",
      "high"
    ],
    "action": "Black-Spot Algae Calculations",
    "category": "Pool Set-up",
    "class": "submenu-item",
    "route": "/poolsetup",
    "view": "blackSpotAlgae",
    "counter": 16,
    "__v": 0,
    "createdAt": "2022-11-03T07:14:35.615Z",
    "updatedAt": "2022-11-03T07:14:35.615Z"
  },
  {
    "_id": "63636a5be61b6203b34ae781",
    "level": [
      "low",
      "medium",
      "high"
    ],
    "action": "Drain, Pressure Wash, Chlorine & Acid Wash Calculations",
    "category": "Pool Set-up",
    "class": "submenu-item",
    "route": "/poolsetup",
    "view": "acidWashCalculation",
    "counter": 17,
    "__v": 0,
    "createdAt": "2022-11-03T07:14:35.615Z",
    "updatedAt": "2022-11-03T07:14:35.615Z"
  },
  {
    "_id": "63636a5be61b6203b34ae782",
    "level": [
      "low",
      "medium",
      "high"
    ],
    "action": "Sand Filter Media Replacement",
    "category": "Pool Set-up",
    "class": "submenu-item",
    "route": "/poolsetup",
    "view": "sandFilterMedia",
    "counter": 18,
    "__v": 0,
    "createdAt": "2022-11-03T07:14:35.615Z",
    "updatedAt": "2022-11-03T07:14:35.615Z"
  },
  {
    "_id": "63636a5be61b6203b34ae783",
    "level": [
      "low",
      "medium",
      "high"
    ],
    "action": "Calendar Settings & Unit Of Measurement",
    "category": "Pool Set-up",
    "class": "submenu-item",
    "route": "/calendarSettings",
    "view": "unitofmeasurement",
    "counter": 19,
    "__v": 0,
    "createdAt": "2022-11-03T07:14:35.615Z",
    "updatedAt": "2022-11-03T07:14:35.615Z"
  },
  {
    "_id": "63636a5be61b6203b34ae784",
    "level": [
      "low",
      "medium",
      "high"
    ],
    "action": "Water Test History",
    "category": "Admin",
    "class": "main-menu",
    "route": "/watertesthistory",
    "view": "",
    "counter": 20,
    "__v": 0,
    "createdAt": "2022-11-03T07:14:35.616Z",
    "updatedAt": "2022-11-03T07:14:35.616Z"
  },
  {
    "_id": "63636a5be61b6203b34ae785",
    "level": [
      "low",
      "medium",
      "high"
    ],
    "action": "Inventory",
    "category": "Admin",
    "class": "main-menu",
    "route": "/inventory",
    "view": "",
    "counter": 21,
    "__v": 0,
    "createdAt": "2022-11-03T07:14:35.616Z",
    "updatedAt": "2022-11-03T07:14:35.616Z"
  },
  {
    "_id": "63636a5be61b6203b34ae786",
    "level": [
      "low",
      "medium",
      "high"
    ],
    "action": "My Account",
    "category": "My Account",
    "class": "main-menu",
    "route": "",
    "view": "",
    "counter": 22,
    "__v": 0,
    "createdAt": "2022-11-03T07:14:35.617Z",
    "updatedAt": "2022-11-03T07:14:35.617Z"
  },
  {
    "_id": "63636a5be61b6203b34ae787",
    "level": [
      "low",
      "medium",
      "high"
    ],
    "action": "My Profile",
    "category": "My Account",
    "class": "submenu-item",
    "route": "/myprofile",
    "view": "",
    "counter": 23,
    "__v": 0,
    "createdAt": "2022-11-03T07:14:35.617Z",
    "updatedAt": "2022-11-03T07:14:35.617Z"
  },
  {
    "_id": "66cc498b9c9448fd226f3f6b",
    "level": [
      "owner",
      "premium",
      "high"
    ],
    "action": "Billing Management",
    "category": "My Account",
    "__v": 0,
    "route": "/subscription-management",
    "counter": 24,
    "class": "submenu-item yellow-bg",
    "updatedAt": "2022-07-13T10:55:09.731Z"
  },
  {
    "_id": "642a99190944c135485d76e9",
    "level": [
      "owner",
      "premium",
      "high"
    ],
    "action": "Calculator-Pro Billing",
    "category": "My Account",
    "__v": 0,
    "route": "/billing",
    "counter": 24,
    "class": "submenu-item yellow-bg",
    "updatedAt": "2022-07-13T10:55:09.731Z"
  },
  {
    "_id": "63636a5be61b6203b34ae789",
    "level": [
      "low",
      "medium",
      "high"
    ],
    "action": "Help Centre",
    "category": "Help Centre",
    "class": "main-menu",
    "route": "",
    "view": "",
    "counter": 25,
    "__v": 0,
    "createdAt": "2022-11-03T07:14:35.617Z",
    "updatedAt": "2022-11-03T07:14:35.617Z"
  },
  {
    "_id": "63636a5be61b6203b34ae78a",
    "level": [
      "low",
      "medium",
      "high"
    ],
    "action": "Getting Started",
    "category": "Help Centre",
    "class": "submenu-item disable-menu",
    "route": "/help-center",
    "view": "",
    "counter": 25,
    "__v": 0,
    "createdAt": "2022-11-03T07:14:35.617Z",
    "updatedAt": "2022-11-03T07:14:35.617Z"
  },
  {
    "_id": "63636a5be61b6203b34ae78b",
    "level": [
      "low",
      "medium",
      "high"
    ],
    "action": "Video Tutorials",
    "category": "Help Centre",
    "class": "submenu-item",
    "route": "/video-tutorials",
    "view": "",
    "counter": 26,
    "__v": 0,
    "createdAt": "2022-11-03T07:14:35.617Z",
    "updatedAt": "2022-11-03T07:14:35.617Z"
  },
  {
    "_id": "643fad1316d8ce2064a8f143",
    "level": [
      "low",
      "medium",
      "high"
    ],
    "action": "Chatbot",
    "category": "Help Centre",
    "class": "submenu-item chatbot-popup",
    "route": "/chatBot",
    "view": "",
    "counter": 27,
    "__v": 0,
    "createdAt": "2022-11-03T07:14:35.617Z",
    "updatedAt": "2022-11-03T07:14:35.617Z"
  },
  {
    "_id": "63636a5be61b6203b34ae78c",
    "level": [
      "low",
      "medium",
      "high"
    ],
    "action": "Contact",
    "category": "Contact",
    "class": "main-menu",
    "route": "/contact",
    "view": "",
    "counter": 28,
    "__v": 0,
    "createdAt": "2022-11-03T07:14:35.617Z",
    "updatedAt": "2022-11-03T07:14:35.617Z"
  },
  {
    "_id": "63636a5be61b6203b34ae78d",
    "level": [
      "low",
      "medium",
      "high"
    ],
    "action": "Logout",
    "category": "Logout",
    "class": "main-menu",
    "route": "/watertesthistory",
    "view": "",
    "counter": 29,
    "__v": 0,
    "createdAt": "2022-11-03T07:14:35.617Z",
    "updatedAt": "2022-11-03T07:14:35.617Z"
  },
  {
    "_id": "636a40ccd251e62d2a93d1f5",
    "level": [
      "low",
      "medium",
      "high"
    ],
    "action": "Add Scheduler",
    "category": "Calendar",
    "class": "submenu-item",
    "route": "/addappointment",
    "view": "",
    "counter": 30,
    "__v": 0,
    "createdAt": "2022-11-03T07:14:35.617Z",
    "updatedAt": "2022-11-03T07:14:35.617Z"
  },
  {
    "_id": "636a41f4d251e62d2a93d1f6",
    "level": [
      "low",
      "medium",
      "high"
    ],
    "action": "Finalize Visit",
    "category": "Calendar",
    "class": "submenu-item",
    "route": "/finlizevisitcost",
    "view": "",
    "counter": 31,
    "__v": 0,
    "createdAt": "2022-11-03T07:14:35.617Z",
    "updatedAt": "2022-11-03T07:14:35.617Z"
  },
  {
    "_id": "636a429fd251e62d2a93d1f7",
    "level": [
      "low",
      "medium",
      "high"
    ],
    "action": "Past and Future Maintenance",
    "category": "Calendar",
    "class": "submenu-item",
    "route": "/booking",
    "view": "",
    "counter": 32,
    "__v": 0,
    "createdAt": "2022-11-03T07:14:35.617Z",
    "updatedAt": "2022-11-03T07:14:35.617Z"
  },
  {
    "_id": "636ce811fa36160e10a020d3",
    "level": [
      "low",
      "medium",
      "high"
    ],
    "action": "4-Pad & 6-Pad Test Strip Reader",
    "category": "Calculator",
    "class": "submenu-item",
    "route": "/a",
    "view": "",
    "counter": 33,
    "__v": 0,
    "createdAt": "2022-11-03T07:14:35.617Z",
    "updatedAt": "2022-11-03T07:14:35.617Z"
  }
];

const Header = () => {
  let history = useHistory();
  let calcPath = getCalendarPath();
  const [userData, setUserData] = useState(defaultUserData);
  const [showHeader, setShowHeader] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [desktopHeader, setDesktopHeader] = useState();
  const [subsciption, setSubsciption] = useState(false);
  const [disableHeader, setDisableHeader] = useState(false);

  const [toggle, setToggle] = useState("");

  const ref = React.createRef();
  const watchScroll = () => {
    if (window.scrollY > 60) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  const [headerState, setHeaderState] = useState([]);
  const [desktopHeaderState, setDesktopHeaderState] = useState([]);

  useEffect(() => {
    let remove = [];
    if (userData?.role !== 'professional-user') {
      remove = [
        'Subscription Management',
        'Chemical costs for predictive ordering',
        'Chatbot Settings',
      ];
    }
    const newArray = [];
    const secondArray = [];
    let userPermissionsData = defaultUserPermissionsData;

    userPermissionsData.forEach((el, i, selfArr) => {
      if (
        ![
          'Water Test History',
          'Inventory',
          'Users',
          'Permissions & Access Level',
          'Chatbot Settings',
        ].includes(el.action) &&
        el.class === 'main-menu' &&
        i === selfArr.findIndex((t) => t._id === el._id)
      ) {
        newArray.push({
          ...el,
          child: userPermissionsData.filter(
            (elem, index, self) =>
              elem.action !== '4-Pad & 6-Pad Test Strip Reader' &&
              elem.category !== 'Calendar' &&
              elem.category === el.category &&
              (elem.route === '/subscriptions' ||
                elem.route === '/calculator' ||
                elem.action !== el.category) &&
              index === self.findIndex((t) => t._id === elem._id)
          ), // for removing duplicay
        });
      }
      if (!remove.includes(el.action)) {
        secondArray.push(el);
      }
    });

    const dataForHeaderState = secondArray.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t._id === value._id)
    );
    setHeaderState([...dataForHeaderState]);
    setDesktopHeaderState([...newArray]);
  }, [userData?.role]);

  const handleAction = (view) => {
    setUserData({
      ...userData,
      showHeader: false,
    });
  };

  const getNavName = (action, view) => {
    let name = '';
    switch (action) {
      case 'Subscription Management':
        name = action;
        break;
      case 'Calculator':
        name = action;
        break;
      case 'Staff':
        name = action;
        break;
      case 'Dashboard':
        name = action;
        break;

      case 'My Pool Size & Chlorination':
        name = action;
        break;

      case 'Chemistry Target Levels':
        name = action;
        break;
      case 'Calculator Chemicals to Display':
        name = action;
        break;
      case 'Pool Chemistry Inter-Relationship':
        name = action;
        break;

      case 'Tax Applicable':
        name = action;
        break;
      case 'Chemical Costs':
        name = action;
        break;
      case 'Chlorinator Model':
        name = action;
        break;
      case 'Drainage Times':
        name = action;
        break;
      case 'Mineral Pool Settings':
        name = action;
        break;
      case 'Chemicals Used to Balance Water':
        name = action;
        break;
      case 'Green Pool Calculations':
        name = action;
        break;
      case 'Black-Spot Algae Calculations':
        name = action;
        break;
      case 'Drain, Pressure Wash, Chlorine & Acid Wash Calculations':
        name = action;
        break;
      case 'Sand Filter Media Replacement':
        name = action;
        break;
      case 'Second Visit Costs':
        name = action;
        break;
      case 'Calendar Settings & Unit Of Measurement':
        name = action;
        break;

      case 'Pool Set-up':
        name = action;
        break;
      case 'Calendar':
        name = action;
        break;
      case 'Inventory':
        name = action;
        break;
      case 'Settings':
        name = action;
        break;
      case 'Calendar settings & Unit of Measurement':
        name = action;
        break;
      case 'My Account':
        name = action;
        break;
      case 'My Profile':
        name = action;
        break;
      case 'Billing Management':
        name = 'Subscription Management';
        break;
      // case 'Calculator-Pro Billing':
      //   name = 'PoolWater-Pro Billing';
      //   break;

      case 'Help Centre':
        name = action;
        break;
      case 'Getting Started':
        name = action;
        break;
      case 'Video Tutorials':
        name = action;
        break;
      case 'Contact':
        name = action;
        break;
      case 'Permissions & Access Level':
        name = action;
        break;
      case 'Water Test History':
        name = action;
        break;
      case 'Users':
        name = action;
        break;
      case 'Chatbot Settings':
        name = action;
        break;
      //  uncomment this when using predictive-ordering section
      // case 'Chemical costs for predictive ordering':
      //   name = 'Chemical Costs for Predictive Ordering';
      //   break;
      case 'Chatbot':
        name = 'Helpbot';
        break;
      // case 'Upload Videos':
      //   name = action;
      //   break;
      default:
        break;
    }

    return name;
  };

  useEffect(() => {
    if (window !== undefined && window.screen.width >= 768) {
      window.addEventListener('scroll', watchScroll);
    }
    return () => {
      window.removeEventListener('scroll', watchScroll);
    };
  }, []);

  const handleHeader = () => {
    setShowHeader(true);
  };
  const handleHideHeader = () => {
    setShowHeader(false);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const { pathname } = window.location;
    if (pathname === '/subscriptions') {
      setSubsciption(true);
    } else {
      setSubsciption(false);
    }

    if (pathname === '/invoice-details') {
      setDisableHeader(true);
    } else {
      setDisableHeader(false);
    }
  }, []);

  const handleLogin = () => {
    history.push('/login');
  };

  const checkPopUp = (routes) =>
    ['/chatBot', '/billing', '/video-tutorials', '/upload-videos'].includes(
      routes
    );

  return (
    <>
      <Helmet>
        <body
          lang='en'
          className={`${showHeader ? 'fixed-body-section' : ''} ${desktopHeader ? 'fixed-body-section' : ''
            } ${subsciption ? 'yellow-header' : ''} ${toggle === "Payments" ? 'paymentClass' : ''}  ${disableHeader ? 'disable-header' : ''
            }`}
        />
      </Helmet>
      <div
        className={`header mbl-header ${isSticky ? ' sticky' : ''} `}
        ref={ref}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='mobile-view'>
                <a href='https://poolwater-pro.com' className='navbar-brand'>
                  {subsciption ? (
                    <img
                      src={mobilelogoblue}
                      alt='logo'
                      className='logo-dark'
                    />
                  ) : (
                    <img src={mobilelogo} alt='logo' className='logo-dark' />
                  )}
                </a>

                <div
                  className={`cross-icon ${showHeader ? 'show-header' : ''}`}
                >
                  {subsciption ? (
                    <img
                      src={openheaderblue}
                      alt='open'
                      onClick={handleHeader}
                      role='presentation'
                      className='openheader g'
                    />
                  ) : (
                    <img
                      src={openheader}
                      alt='open'
                      onClick={handleHeader}
                      role='presentation'
                      className='openheader g'
                    />
                  )}
                </div>
              </div>
              {showHeader && (
                <div className='custom-header mob-header'>
                  <div className='custom-mob-section'>
                    <div className='cross-icon'>
                      <img
                        src={cancel}
                        alt='close'
                        onClick={handleHideHeader}
                        role='presentation'
                      />
                    </div>
                    <a
                      href='https://poolwater-pro.com'
                      className='navbar-brand'
                    >
                      <img src={mobilelogo} alt='logo' className='logo-dark' />
                      <img
                        src={logowhite}
                        alt='logo-white'
                        className='logo-light'
                      />
                    </a>
                    <div className='header-link'>
                      {userData?.auth ? (
                        <Nav className='ml-auto'>
                          {headerState.map((permission) =>
                            getNavName(permission.action) ? (
                              <>
                                {permission?.route &&
                                  permission?.route !== '/a' ? (
                                  <>
                                    {checkPopUp(permission?.route) ? (
                                      <>
                                        {userData?.role !== 'subscriber' &&
                                          permission.route ===
                                          '/billing' ? null : (
                                          <Nav.Item
                                            key={permission.route}
                                            className={`${permission.class}`}
                                          >
                                            <span className='nav-link'>
                                              {getNavName(permission.action)}
                                            </span>
                                          </Nav.Item>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {userData?.role !== 'subscriber' &&
                                          permission.route ===
                                          '/subscription-management' ? null : (
                                          <Nav.Item
                                            key={permission.route}
                                            className={`${permission.class} ${permission.route ===
                                              '/predictiveOrdering'
                                              ? 'subscription-link'
                                              : ''
                                              } ${permission.route ===
                                                '/subscriptions'
                                                ? 'subscription-link'
                                                : ''
                                              }`}
                                            onClick={() =>
                                              handleAction(permission.view)
                                            }
                                          >
                                            <Link
                                              to={
                                                permission.route === '/calendar'
                                                  ? calcPath
                                                  : permission?.view
                                                    ? `${permission?.route}?view=${permission?.view}`
                                                    : permission?.route
                                              }
                                              className={`nav-link ${getNavName(permission.action)}`}
                                            >
                                              {getNavName(permission.action)}
                                            </Link>
                                          </Nav.Item>
                                        )}
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <Nav.Item
                                    key={permission.route}
                                    className={`${permission.class} ${permission.route === '/predictiveOrdering'
                                      ? 'subscription-link'
                                      : ''
                                      } ${permission.route === '/subscriptions'
                                        ? 'subscription-link'
                                        : ''
                                      }`}
                                  >
                                    <Link
                                      to='/'
                                      className='nav-link disable-link'
                                    >
                                      {getNavName(permission.action)}
                                    </Link>
                                  </Nav.Item>
                                )}
                              </>
                            ) : null
                          )}

                          <Nav.Item
                            className='d-block d-xl-none mt-2 logout-btn'
                            onClick={() =>
                              setUserData({ ...userData, showHeader: false })
                            }
                          >
                            <Button
                              type='submit'
                              className='btn btn-danger'
                              onClick={handleLogout}
                            >
                              {' '}
                              Logout{' '}
                            </Button>
                          </Nav.Item>
                        </Nav>
                      ) : !userData.auth ? (
                        <Nav className='ml-auto mt-2'>
                          <Nav.Item className='submenu-item nav-item'>
                            <Link to='/' className='nav-link'>
                              Home
                            </Link>
                          </Nav.Item>
                          <Nav.Item className='submenu-item nav-item'>
                            <Link to='/contact' className='nav-link'>
                              Contact
                            </Link>
                          </Nav.Item>
                          <Nav.Item
                            className='d-block d-xl-none mt-2 logout-btn'
                            onClick={() =>
                              setUserData({ ...userData, showHeader: false })
                            }
                          >
                            <Button
                              type='submit'
                              className='btn btn-danger'
                              onClick={handleLogout}
                            >
                              {' '}
                              Login{' '}
                            </Button>
                          </Nav.Item>
                        </Nav>
                      ) : null}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`header web-header ${isSticky ? ' sticky' : ''} `}
        ref={ref}
      >
        <Container className='header-relative'>
          <Navbar collapseOnSelect expand='lg'>
            <div className='web-header-layout'>
              <Navbar.Brand href='https://poolwater-pro.com'>
                <img src={mobilelogo} alt='logo' className='logo-dark' />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls='responsive-navbar-nav' />
              <Navbar.Collapse id='responsive-navbar-nav'>

                <Nav className='ml-auto'>
                  {desktopHeaderState?.length > 0 &&
                    desktopHeaderState.map((el) => (
                      <>
                        {el?.child?.length === 0 ? (
                          <>
                            {!['Logout'].includes(el?.action) ? (
                              <Link className='nav-link' to={el?.route}>
                                {el?.action}
                              </Link>
                            ) : null}
                          </>
                        ) : !['Logout'].includes(el?.action) ? (
                          <>
                            <NavDropdown title={el?.action}>
                              {el.child.map((ele) => (
                                <>
                                  {ele?.route.includes('/') &&
                                    ele.route !== '/a' && (
                                      <>
                                        {
                                          ele?.route === '/video-tutorials' ||
                                            ele?.route === '/upload-videos' ? (
                                            <Nav.Item
                                              key={ele.route}
                                              className='chatdesk-icon'
                                            >
                                              <span
                                                className='dropdown-item'
                                              >
                                                {getNavName(ele.action)}
                                              </span>
                                            </Nav.Item>
                                          ) : (
                                            <div
                                              onClick={() =>
                                                handleAction(ele.view)
                                              }
                                              className={`${ele.class}`}
                                            >
                                              <Link
                                                to={
                                                  ele.view
                                                    ? `/${ele.view ===
                                                      'unitofmeasurement'
                                                      ? 'calendarSettings'
                                                      : 'poolsetup'
                                                    }?view=${ele.view}`
                                                    : ele.route
                                                }
                                                className='dropdown-item'
                                                void
                                              >
                                                {getNavName(ele.action)}
                                              </Link>
                                              {/* </NavDropdown.Item> */}
                                            </div>
                                          )}
                                      </>
                                    )}
                                </>
                              ))}
                            </NavDropdown>
                          </>
                        ) : null}
                      </>
                    ))}
                </Nav>
              </Navbar.Collapse>
            </div>
          </Navbar>
          <div className='auth-login'>
            <Button onClick={handleLogin}>Login</Button>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
