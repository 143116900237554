import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { IoIosSave } from "react-icons/io";
import TextField from "../../chatbotSettings/components/textField";
import Button from "../../chatbotSettings/components/button";
import LoadingButton from "../../chatbotSettings/components/loadingButton";
import toastMessage from "../../../functions/toastMessage";
import { server } from "../../../config/keys";
import { userInstance } from "../../../config/axios";
import { EVENT_TYPE_ARRAY, EVENT_TYPES, SCHEDULE_TYPE_ARRAY, SCHEDULE_TYPES, USER_TYPES_ARRAY } from "../constant";
import { NumberFieldinlineLabel } from "../components/forms";
import SelectField from "../../chatbotSettings/components/selectField";
import { USER_TYPES } from "../../subscription/utils";
import EmailEditor from 'react-email-editor'
import { isMobile } from "react-device-detect";
import blankDesign from "../../../config/sample.json"

const defaultUserType = USER_TYPES.allUsers;
const defaultScheduleType = SCHEDULE_TYPES.IMMEDIATELY;
const defaultTime = {
  day: 0,
  hour: 0,
  minute: 2,
  second: 0
}

const defaultEventType = EVENT_TYPES.USER_CREATED;

const mergeTags = [
  {
    name: 'First Name',
    value: '{{firstName}}',
    sample: 'John'
  },
  {
    name: 'Price Per Year',
    value: '{{pricePerYear}}',
    sample: 'AU$264.00'
  },
  {
    name: 'Price Per Month',
    value: '{{pricePerMonth}}',
    sample: 'AU$22.00'
  },
  {
    name: 'Unsubscribe URL',
    value: '{{{unsubscribeURL}}}',
    sample: 'https://app.poolwater-pro.com?token=123'
  }
]

const AddScheduleModal = (props) => {
  const {
    show,
    handleClose,
    emailSchedules,
    setEmailSchedules,
    selectedItem,
    isEditMode,
    filter
  } = props;
  const [isSaving, setIsSaving] = useState(false);
  const [subject, setSubject] = useState("");
  const [userType, setUserType] = useState(defaultUserType);
  const [scheduleType, setScheduleType] = useState(defaultScheduleType);
  const [scheduleTime, setScheduleTime] = useState(defaultTime);
  const [eventType, setEventType] = useState(defaultEventType);

  const emailEditorRef = useRef(null);

  const onDesignLoad = (data) => {
    console.log('onDesignLoad', data);
  };

  const onLoad = (unlayer) => {
    unlayer.addEventListener('design:loaded', onDesignLoad);
    if (selectedItem !== null && typeof selectedItem.ebody === "object") {
      unlayer.loadDesign(selectedItem.ebody);
    } else {
      unlayer.loadDesign(blankDesign);
    }
  };

  const onReady = (unlayer) => {
    console.log('onReady', unlayer);
  };

  const handleChnageSubject = (value) => {
    setSubject(value);
  };

  const handleSaveSchedule = async () => {
    if (subject === "") {
      toastMessage("error", "Please input the subject.");
      return;
    }

    const unlayer = emailEditorRef.current?.editor;

    unlayer?.exportHtml(async (data) => {
      const { design, html } = data;
      try {
        setIsSaving(true);

        if (!isEditMode) {
          let newSchedule = {
            subject,
            body: html,
            ebody: design,
            userType,
            scheduleType,
            eventType,
            day: scheduleTime.day,
            hour: scheduleTime.hour,
            minute: scheduleTime.minute,
            second: scheduleTime.second
          };
          console.log("newSchedule ===>", newSchedule);
          let response = await userInstance().post(
            `${server}/emailSchedules`,
            newSchedule,
          );
          const { msg } = response.data;
          if (response.status === 201) {
            const { emailSchedule } = response.data;
            let _schedules = emailSchedules.slice();
            _schedules.push(emailSchedule);
            setEmailSchedules(_schedules);
            toastMessage("success", msg);
          } else {
            toastMessage("error", msg);
          }
          initialize();
          handleClose();
        } else {
          let updatedSchedule = {
            subject,
            body: html,
            ebody: design,
            userType,
            scheduleType,
            eventType,
            day: scheduleTime.day,
            hour: scheduleTime.hour,
            minute: scheduleTime.minute,
            second: scheduleTime.second
          }
          let response = await userInstance().put(
            `${server}/emailSchedules/${selectedItem._id}`,
            updatedSchedule,
          );
          const { msg, emailSchedule } = response.data;
          if (response.status === 200) {
            let _schedules = emailSchedules.slice();
            let findIndex = _schedules.findIndex(
              (item) => item._id === emailSchedule._id,
            );
            if (findIndex !== -1) {
              _schedules[findIndex].subject = emailSchedule.subject;
              _schedules[findIndex].body = emailSchedule.body;
              _schedules[findIndex].ebody = emailSchedule.ebody;
              _schedules[findIndex].userType = emailSchedule.userType;
              _schedules[findIndex].scheduleType = emailSchedule.scheduleType;
              _schedules[findIndex].eventType = emailSchedule.eventType;
              _schedules[findIndex].day = emailSchedule.day;
              _schedules[findIndex].hour = emailSchedule.hour;
              _schedules[findIndex].minute = emailSchedule.minute;
              _schedules[findIndex].second = emailSchedule.second;
              setEmailSchedules(_schedules);
            }
            toastMessage("success", msg);
          } else {
            toastMessage("error", msg);
          }
          initialize();
          handleClose();
        }
      } catch (error) {
        console.log("error", error);
      }
      setIsSaving(false);
    });
  };
  const initialize = () => {
    setSubject("");
    setUserType(filter.userType);
    setScheduleType(defaultScheduleType);
    setEventType(defaultEventType);
    setScheduleTime(defaultTime);
  };

  useEffect(() => {
    if (selectedItem !== null) {
      if (isEditMode) {
        setSubject(selectedItem.subject);
        setUserType(selectedItem.userType);
        setScheduleType(selectedItem.scheduleType);
        setEventType(selectedItem.eventType);
        setScheduleTime({ day: selectedItem.day, hour: selectedItem.hour, minute: selectedItem.minute, second: selectedItem.second });
      } else {
        initialize();
      }
    } else {
      initialize();
    }
  }, [selectedItem, isEditMode, show, filter]);

  const handleChangeUserType = (value) => {
    setUserType(value);
  }

  const handleChangeScheduleType = (value) => {
    setScheduleType(Number(value));
  }

  const handleChangeEventType = (value) => {
    setEventType(Number(value));
  }

  const handleChangeScheduleTime = (value, key) => {
    let numericValue = value.replace(/\D/g, '');

    if (numericValue.length > 1 && numericValue.startsWith('0')) {
      numericValue = numericValue.replace(/^0+/, '');
    }

    setScheduleTime((prev) => ({
      ...prev,
      [key]: numericValue === '' ? 0 : parseInt(numericValue)
    }));
  };

  return (
    <>
      <Modal
        show={show}
        centered
        onHide={handleClose}
        dialogClassName="email-scheduling-modal-container modal-fullscreen"
        aria-labelledby="add-email-schedule-modal-styling-title"
      >
        <Modal.Header style={{ paddingBottom: 0 }} closeLabel="close" closeButton>
          <Modal.Title id="add-email-schedule-modal-styling-title">
            {isEditMode ? "Edit Schedule" : "Add Schedule"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column gap-2">
            <TextField
              type="text"
              label="Subject"
              placeholder=""
              value={subject}
              handleChangeValue={handleChnageSubject}
            />

            <div style={{ height: '50vh', width: '100%' }}>
              <EmailEditor
                ref={emailEditorRef}
                onLoad={onLoad}
                onReady={onReady}
                options={{
                  version: "latest",
                  appearance: {
                    theme: "modern_light"
                  },
                  mergeTags
                }}
              />
            </div>
            <div className="mt-4"></div>
            <SelectField data={USER_TYPES_ARRAY} label="User Type" selectedValue={userType} handleChangeSelect={handleChangeUserType} />
            <SelectField data={EVENT_TYPE_ARRAY} label="Event Type" selectedValue={eventType} handleChangeSelect={handleChangeEventType} />
            <SelectField data={SCHEDULE_TYPE_ARRAY} label="Schedule Type" selectedValue={scheduleType} handleChangeSelect={handleChangeScheduleType} />
            {
              scheduleType !== SCHEDULE_TYPES.IMMEDIATELY &&
              < div >
                <span style={{ fontSize: '14px' }}>Send Time</span>
                {
                  !isMobile ? (
                    <div className="d-flex flex-wrap gap-2 w-100">
                      <NumberFieldinlineLabel
                        inputProps={{
                          value: scheduleTime.day,
                          onChange: (e) => handleChangeScheduleTime(e.target.value, 'day'),
                          min: 0
                        }}
                        label="days"
                      />
                      <NumberFieldinlineLabel
                        label="hour"
                        inputProps={{
                          value: scheduleTime.hour,
                          onChange: (e) => handleChangeScheduleTime(e.target.value, 'hour'),
                          min: 0,
                        }}
                      />
                      <NumberFieldinlineLabel
                        label="minute"
                        inputProps={{
                          value: scheduleTime.minute,
                          onChange: (e) => handleChangeScheduleTime(e.target.value, 'minute'),
                          min: 0,
                        }}
                      />
                      <NumberFieldinlineLabel
                        label="second"
                        inputProps={{
                          value: scheduleTime.second,
                          onChange: (e) => handleChangeScheduleTime(e.target.value, 'second'),
                          min: 0,
                        }}
                      />
                    </div>
                  ) : (<div className="d-flex justify-content-between flex-wrap gap-2 w-100">
                    <div className="d-flex flex-column gap-1">
                      <NumberFieldinlineLabel
                        inputProps={{
                          value: scheduleTime.day,
                          onChange: (e) => handleChangeScheduleTime(e.target.value, 'day'),
                        }}
                        label="day"
                      />
                      <NumberFieldinlineLabel
                        label="hours"
                        inputProps={{
                          value: scheduleTime.hour,
                          onChange: (e) => handleChangeScheduleTime(e.target.value, 'hour'),
                          min: 0,
                        }}
                      />
                      <NumberFieldinlineLabel
                        label="minute"
                        inputProps={{
                          value: scheduleTime.minute,
                          onChange: (e) => handleChangeScheduleTime(e.target.value, 'minute'),
                          min: 0,
                        }}
                      />
                      <NumberFieldinlineLabel
                        label="second"
                        inputProps={{
                          value: scheduleTime.second,
                          onChange: (e) => handleChangeScheduleTime(e.target.value, 'second'),
                          min: 0,
                        }}
                      />
                    </div>
                  </div>)
                }
              </div>
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-2">
            <Button
              label="Close"
              handleClickBtn={handleClose}
              disabled={isSaving}
            />
            <LoadingButton
              isLoading={isSaving}
              icon={<IoIosSave size={18} style={{ marginRight: "16px" }} />}
              label="Save"
              handleClickBtn={handleSaveSchedule}
            />
          </div>
        </Modal.Footer>
      </Modal >
    </>
  );
};

export default AddScheduleModal;
