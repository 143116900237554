import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { HashLoader } from "react-spinners";
import logo from "../../assets/home/calculator-logo2.png";
import UnsubscribeConfirm from "./confirm";
import { UNSUBSCRIBE_STEP } from "./constants";
import Feedback from "./feedback";
import FinalMessage from "./final";
import { appTokenInstance } from "../../config/axios";
import toastMessage from "../../functions/toastMessage";
import ExpiredLinkPage from "../../pages/expiredLink";

import "./index.css";

const UnsubscribePage = () => {
  const history = useHistory();
  const location = useLocation();
  const [token, setToken] = useState("");
  const [userData, setUserData] = useState(null);
  const [unsubscribeStep, setUnsubscribeStep] = useState(UNSUBSCRIBE_STEP.CONFIRM);
  const [isLoading, setIsLoading] = useState(false);

  const getUserDataFromToken = async () => {
    const query = new URLSearchParams(location.search);
    const _token = query.get('token');
    if (_token) {
      try {
        setIsLoading(true);
        let response = await appTokenInstance(_token).get("/user/getUserDataFromToken");

        if (response.status === 200) {
          let _userData = response.data.userData;
          setUserData(_userData);
          if (_userData.subscriptionStatus === "canceled") {
            let nextStep = _userData.feedbacks && _userData.feedbacks.length > 0 ? UNSUBSCRIBE_STEP.FINAL : UNSUBSCRIBE_STEP.FEEDBACK;
            setUnsubscribeStep(nextStep);
          }
        } else {
          toastMessage("error", response.data.msg);
        }
        setToken(_token);

      } catch (error) {
        console.log('error', error);
      }
      setIsLoading(false);
    }
    else {
      history.push('/login');
      return;
    }
  }

  useEffect(() => {
    getUserDataFromToken();
  }, [location]);

  return (
    <div className="unsubscribe-container">
      {

        isLoading ?
          <div className="d-flex justify-content-center align-self-center">
            <HashLoader color="#0024fc" />
          </div> :
          token ?
            <div className="unsubscribe">
              <div className="logo-unsubscribe">
                <img src={logo} alt="logo" />
              </div>
              <h3 className="unsubscribe-title">Email Subscription Preferences</h3>
              <div className="unsubscribe-form">

                {
                  unsubscribeStep === UNSUBSCRIBE_STEP.CONFIRM &&
                  <UnsubscribeConfirm
                    token={token}
                    setUnsubscribeStep={setUnsubscribeStep}
                    setIsLoading={setIsLoading}
                  />
                }
                {
                  unsubscribeStep === UNSUBSCRIBE_STEP.FEEDBACK &&
                  <Feedback
                    token={token}
                    setUnsubscribeStep={setUnsubscribeStep}
                    setIsLoading={setIsLoading}
                  />
                }
                {
                  unsubscribeStep === UNSUBSCRIBE_STEP.FINAL &&
                  <FinalMessage />
                }

              </div>
            </div> :
            <ExpiredLinkPage />
      }

    </div>

  );
};

export default UnsubscribePage;