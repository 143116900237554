import React from "react";
import Modal from "react-bootstrap/Modal";
import LoadingButton from "../../chatbotSettings/components/loadingButton";
import Button from "../../chatbotSettings/components/button";

const RemoveModal = (props) => {
  const {
    isRemoving,
    show,
    handleClose,
    subject,
    icon,
    selectedItem,
    handleDeleteItem,
  } = props;

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        dialogClassName="chatbot-settings-remove-modal-container"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeLabel="close" closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            <div className="d-flex flex-column gap-0">
              <span style={{ fontSize: "18px" }}>Are you sure to remove this schedule?</span>
              <span style={{ fontSize: "16px" }}>{subject}</span>
            </div>

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-item-center align-self-center gap-2">
            <div>{icon}</div>
            <div>
              <span style={{ fontSize: "14px" }}>
                {selectedItem !== null ? selectedItem.subject : ""}
              </span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-2">
            <Button
              label="Cancel"
              handleClickBtn={handleClose}
              disabled={isRemoving}
            />
            <LoadingButton
              label="Remove"
              handleClickBtn={() =>
                handleDeleteItem(selectedItem !== null ? selectedItem._id : "")
              }
              isLoading={isRemoving}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RemoveModal;
