import React from "react";
import "./index.css";

const FinalMessage = () => {

  return (
    <>
      <div className="d-flex flex-column gap-1 pt-4 pb-4">
        <p className="final-content-text">Thank You for Your Feedback!</p>
        <p className="final-content-text">
          We appreciate the time you took to help us improve. We’re sorry to see you go, but your feedback is invaluable to us as we strive to enhance our services.
        </p>
        <p className="final-content-text mt-2">
          Best regards,
        </p>
        <p className="final-content-text">
          The PoolWater-Pro Team
        </p>
      </div>
    </>
  );
};

export default FinalMessage;