import logo from "../../assets/home/calculator-logo2.png";

const ExpiredLinkPage = () => {
  return (
    <div className="d-flex flex-column justify-content-center gap-2" style={{ marginTop: '24px' }}>
      <div className="logo-unsubscribe">
        <img src={logo} alt="logo" />
      </div>
      <div className="expired-text text-center">
        <h2>Oops, this link is expired</h2>
        <h5>This URL is not valid anymore.</h5>
      </div>
    </div>
  )
}

export default ExpiredLinkPage