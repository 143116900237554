import React, { useState } from "react";
import { Form, Button, FormControl } from "react-bootstrap";
import { FEEDBACK_EDITOR_ID, FEEDBACK_OPTIONS, PRIVACY_POLICY_URL, RESUBSCRIBE_URL, UNSUBSCRIBE_STEP } from "./constants";
import { appTokenInstance } from "../../config/axios";

const FeedbackPage = ({
  token,
  setUnsubscribeStep,
  setIsLoading
}) => {
  const [selectedOptions, setSelectedOptions] = useState({});
  const [additionalComment, setAdditionalComment] = useState('');

  const handleCheckboxChange = (id) => {
    setSelectedOptions(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  const handleTextAreaChange = (e) => {
    setAdditionalComment(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const feedbacks = FEEDBACK_OPTIONS.filter(option => selectedOptions[option.id]).map(option => {
      if (option.id === FEEDBACK_EDITOR_ID && selectedOptions[option.id]) {
        return additionalComment; // Use the additional comment if this specific checkbox is checked
      }
      return option.content;
    });

    console.log("Feedbacks submitted:", feedbacks);

    try {
      setIsLoading(true);
      let response = await appTokenInstance(token).post("/user/submit-feedback", { feedbacks });
      console.log(response.data); // Logging the response from the server
      setUnsubscribeStep(UNSUBSCRIBE_STEP.FINAL);
    } catch (error) {
      console.error('Error submitting feedbacks:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="d-flex flex-column gap-1">
        <p className="feedback-content-text">We’re Sorry to See You Go</p>
        <p className="feedback-content-text">Thank you for updating your email preferences. You have been successfully removed from our mailing list and will no longer receive promotional emails from PoolWater-Pro.</p>
      </div>
      <div className="d-flex flex-column mt-3">
        <p className="feedback-subtitle">Please Help Us Improve (optional)</p>
        <p className="feedback-content-text">Your feedback is important to us.</p>
        <p className="feedback-content-text">Please let us know why you’re unsubscribing:</p>
      </div>
      <div className="d-flex flex-column gap-1">
        <div className="d-flex flex-column gap-2">
          <div className="d-flex flex-column gap-2 mt-2">
            {FEEDBACK_OPTIONS.map((item) => (
              <div className="d-flex flex-column gap-2" key={item.id}>
                <div className="d-flex gap-2 align-items-center">
                  <Form.Check
                    type='checkbox'
                    name='feedback-checkboxes'
                    className='custom-checkbox'
                    checked={!!selectedOptions[item.id]}
                    onChange={() => handleCheckboxChange(item.id)}
                  />
                  <p className="feedback-content-text">{item.content}</p>
                </div>
                {item.id === FEEDBACK_EDITOR_ID && (
                  <div className="pl-4 pr-4">
                    <FormControl
                      className="p-1 m-0"
                      as="textarea"
                      disabled={!selectedOptions[item.id]}
                      value={additionalComment}
                      onChange={handleTextAreaChange}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="unsubscribe-popup-btn mt-2">
            <Button
              type="submit"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Submit Feedback
            </Button>
          </div>
          <div className="d-flex flex-column gap-2 mt-2">
            <p className="feedback-footer-text">
              If you unsubscribed by mistake or would like to rejoin our community later, you can <a href={RESUBSCRIBE_URL}>resubscribe here</a> at any time. We value your privacy and strive to provide you with the best experience possible.
            </p>
            <p className="feedback-footer-text">
              For any questions, please contact our support team at <a href="#">support@poolwater-pro.com</a>.
            </p>
            <p className="feedback-footer-text">
              Your privacy matters to us. For more information on how we handle your data, please visit our <a href={PRIVACY_POLICY_URL}>Privacy Policy</a>.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeedbackPage;