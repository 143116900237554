import React, { useEffect, useState } from "react";
import AdminWrapper from "../admin/adminwrapper/wrapper";
import ScheduleTab from "./schedules";
import StatusTrackerTab from "./statusTracker";
import {
  SCHEDULE_TAB,
  STATUS_TRACKER_TAB
} from "./constant";
import { server } from "../../config/keys";
import { userInstance } from "../../config/axios";

import "./index.css";

const EmailSchedulingPage = () => {
  const [pageTab, setPageTab] = useState(SCHEDULE_TAB);
  const [emailSchedules, setEmailSchedules] = useState([]);

  const getEmailSchedules = async () => {
    try {
      let response = await userInstance().get(
        `${server}/emailSchedules`,
      );
      if (response.status === 200) {
        let _emailSchedules = response.data.emailSchedules;
        if (_emailSchedules) {
          setEmailSchedules(_emailSchedules);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getEmailSchedules();
  }, [])

  const renderTabContent = () => {
    switch (pageTab) {
      case SCHEDULE_TAB:
        return <ScheduleTab emailSchedules={emailSchedules} setEmailSchedules={setEmailSchedules} getEmailSchedules={getEmailSchedules} />;
      case STATUS_TRACKER_TAB:
        return <StatusTrackerTab />;
      default:
        return null;
    }
  };

  return (
    <AdminWrapper>
      <div className="staff-container">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="main-titles-mob d-flex justify-content-center">
                <h3>Email Scheduling</h3>
              </div>
              <div className="tab-buttons">
                <button
                  type="button"
                  className={
                    pageTab === SCHEDULE_TAB
                      ? "email-scheduling-mode-button-selected"
                      : "email-scheduling-mode-button"
                  }
                  onClick={() => setPageTab(SCHEDULE_TAB)}
                >
                  Schedules
                </button>
                <button
                  type="button"
                  className={
                    pageTab === STATUS_TRACKER_TAB
                      ? "email-scheduling-mode-button-selected"
                      : "email-scheduling-mode-button"
                  }
                  onClick={() => setPageTab(STATUS_TRACKER_TAB)}
                >
                  Status Tracker
                </button>
              </div>
              <div className="email-scheduling-divider" />
              <div className="tab-content">
                {renderTabContent()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};

export default EmailSchedulingPage;