import { useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';

/**
 * Custom hook to dismiss keyboard on swipe down gesture
 * @returns {React.RefObject} - Ref to attach to the container element
 */
const useKeyboardDismiss = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (!isMobile) return;

    let startY = 0;
    const container = containerRef.current;

    const handleTouchStart = (e) => {
      startY = e.touches[0].clientY;
    };

    const handleTouchMove = (e) => {
      if (!startY) return;

      const moveY = e.touches[0].clientY;
      const diffY = moveY - startY;

      if (diffY > 40) {
        const activeElement = document.activeElement;
        if (activeElement && (
          activeElement.tagName === 'INPUT' ||
          activeElement.tagName === 'TEXTAREA'
        )) {
          activeElement.blur();
        }
        startY = 0;
      }
    };

    const handleTouchEnd = () => {
      startY = 0;
    };

    if (container) {
      container.addEventListener('touchstart', handleTouchStart, { passive: true });
      container.addEventListener('touchmove', handleTouchMove, { passive: true });
      container.addEventListener('touchend', handleTouchEnd, { passive: true });
    }

    return () => {
      if (container) {
        container.removeEventListener('touchstart', handleTouchStart);
        container.removeEventListener('touchmove', handleTouchMove);
        container.removeEventListener('touchend', handleTouchEnd);
      }
    };
  }, []);

  return containerRef;
};

export default useKeyboardDismiss;