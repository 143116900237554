/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable consistent-return */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState, useRef } from "react";
import { TimePicker } from "smart-webcomponents-react/timepicker";
import { Modal, Form, Button, FormControl, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import Calendar from "react-calendar";
import moment from "../../functions/moment";
import "react-calendar/dist/Calendar.css";
import { dateRange } from "../../functions/index";

const DateModal = (props) => {
  let increment = 1;
  let dayCount = 1;
  const {
    setRepeatFrequency,
    showDate,
    handleDateModal,
    handleChangeDateData,
    dateData,
    setDateData,
    setRepeatAppt,
    repeatAppt,
    repeatFrequency,
    handleSaveDate,
    handleFrequency,
    handleChangeDate,
    datePopupData,
    timepickerRef,
    handleRepeat,
    appointmentType,
    appointmentIndex,
    blocktime,
    closeBlockTimeModal,
    handleDurationPicker,
    bookingData,
    convertToReadableDuration,
    handleTimePicker,
    handleBookingData,
    staffList,
    setBlcoktimeName,
    blcoktimeName,
    saveBlockTime,
    blocktype,
    CrossDate,
    newAppointment,
    requiredDays,
    setRequiredDays,
    team,
    setTeam,
    error,
    isloader,
  } = props;

  const now = new Date(dateData.date);
  const now_utc = new Date(now.toUTCString().slice(0, -4));
  const timeInterval = (interval, type) => {
    if (type === "weekly") {
      let intervalDay = interval * 7;
      return intervalDay;
    }
    if (type === "daily") {
      let intervalDay = interval;
      return intervalDay;
    }
    if (type === "monthly") {
      let intervalDay = interval * 30;
      return intervalDay;
    }
  };
  const getDisabledDates = (calendarDate) => {
    const interval = timeInterval(
      dateData.interval_multiplier,
      dateData.interval_type,
    );
    localStorage.setItem("lastDate", dateData.specificDate);
    const specificDate = new Date(dateData.specificDate);
    const lastDate = new Date();
    lastDate.setDate(specificDate.getDate() + 365 * 50);
    const rangeArray = dateRange(
      new Date(),
      lastDate,
      parseInt(interval, 10),
    );
    // console.log('rangeArray', rangeArray);
    const findCheck = rangeArray.filter(
      (ele) =>
        new Date(ele).toDateString() === new Date(calendarDate).toDateString(),
    );
    if (findCheck.length <= 0) {
      return true;
    }
    return false;
  };
  const handleCalendarDate = (e) => {
    const timeDayInterval = timeInterval(
      dateData.interval_multiplier,
      dateData.interval_type,
    );

    const specificDate = new Date(e);
    specificDate.setDate(specificDate.getDate() + timeDayInterval);

    // const timeDayInterval = timeInterval(
    //   dateData.interval_multiplier,
    //   dateData.interval_type,
    // );
    setDateData({
      ...dateData,
      date: e,
      specificDate,
    });
    setCurrentDate(!currentDate);
    handleBlockedDate();
  };
  const handleCalendarPopup = (e) => {
    setDateData({ ...dateData, specificDate: e });
    setTimeout(() => {
      let lastDate = localStorage.getItem("lastDate");
      const disableDates = dateRange(new Date(e), new Date(lastDate), 5);
      console.log("disable Dates", disableDates);
    }, 500);
    handleDate();
  };
  const [currentDate, setCurrentDate] = useState();
  const [specificDate, setSpecificDate] = useState();
  const handleDate = () => {
    setSpecificDate(!specificDate);
  };
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (event.srcElement.className === "time-picker-modal time-popup") {
            setRepeatFrequency(false);
          }
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const [startBlocked, setStartBlocked] = useState();
  const handleBlockedDate = () => {
    setStartBlocked(!startBlocked);
  };
  return (
    <Modal
      size="md"
      show={showDate}
      onHide={
        () =>
          `${
            blocktime && blocktime === "open"
              ? closeBlockTimeModal("close")
              : CrossDate()
          }`
        // eslint-disable-next-line react/jsx-curly-newline
      }
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="search-modal time-popup apppt-time-popup"
    >
      {/* {console.log('dateData.occurenceMsg', dateData.occurenceMsg)} */}
      <div className="staff-close">
        <i
          className="fa fa-times"
          onClick={
            () =>
              `${
                blocktime && blocktime === "open"
                  ? closeBlockTimeModal("close")
                  : CrossDate()
              }`
            // eslint-disable-next-line react/jsx-curly-newline
          }
          aria-hidden="true"
        />
      </div>
      <Modal.Header>
        {blocktime &&
        blocktime === "open" &&
        localStorage.getItem("checkapptype") === "blockedtime"
          ? `${blocktype === "edit" ? "Edit Blocked Time" : "Set Blocked Time"}`
          : blocktime &&
              blocktime === "open" &&
              localStorage.getItem("checkapptype") === "personalappointment"
            ? `${
                blocktype === "edit"
                  ? "Edit Personal Appointment"
                  : "Add Personal Appointment"
              }`
            : "Pool Maintenance Date"}
      </Modal.Header>
      <Modal.Body>
        {blocktime && blocktime === "open" && (
          <div className="search-popup shift-container blocked-popup">
            <div className="select-time">
              <Form.Group
                controlId="formBasicHiddentime"
                className="italic-text"
              >
                <h6>
                  {localStorage.getItem("checkapptype") ===
                  "personalappointment"
                    ? "Name for Personal Appointment"
                    : "Name for Blocked Time"}
                </h6>
                <Form.Control
                  type="text"
                  name="blockname"
                  value={blcoktimeName}
                  onChange={(e) => setBlcoktimeName(e.target.value)}
                  placeholder={`Enter ${
                    localStorage.getItem("checkapptype") ===
                    "personalappointment"
                      ? "Name for Personal Appointment"
                      : "Name for Blocked Time"
                  }`}
                  className={`${error === "name" ? "error-text" : ""}`}
                />
              </Form.Group>
            </div>
            {/* <div className="select-time">
              <Form.Group controlId="formBasicHiddentime">
                <h6>Team Member</h6>
                <div className="select-box">
                  <Form.Control
                    as="select"
                    onChange={handleBookingData}
                    value={bookingData.staffName}
                    name="staffName"
                  >
                    {staffList.map((el) => (
                      <option
                        value={`${el.firstname} ${el.lastname}`}
                        key={el._id}
                      >
                        {`${el.firstname} ${el.lastname}`}
                      </option>
                    ))}
                  </Form.Control>
                </div>
              </Form.Group>
            </div> */}
            <div className="select-time">
              <Form.Group controlId="formBasicHiddentime">
                <h6>Start Date</h6>
                <div className="select-box">
                  <Button onClick={handleBlockedDate}>
                    {moment(dateData.date).format("dddd")}
                    {", "}
                    {moment(dateData.date).format("D MMMM YYYY")}
                  </Button>
                </div>
              </Form.Group>
            </div>
            <Modal
              size="md"
              show={startBlocked}
              onHide={() => setStartBlocked(false)}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              className="startdate-modal calendar-modal search-modal time-popup custom-time-popup"
            >
              <Modal.Header closeButton />
              <Modal.Body>
                <div className="search-popup shift-container">
                  <div className="time-calender">
                    <Calendar
                      onChange={handleCalendarDate}
                      value={new Date(dateData.date)}
                    />
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            <div className="blocked-time-duration">
              <div className="select-time">
                <Form.Group controlId="formBasicHiddentime">
                  <h6>Start Time</h6>
                  <Button onClick={handleTimePicker}>
                    {moment(bookingData.startTime, ["HH:mm"]).format("h:mm a")}
                  </Button>
                </Form.Group>
              </div>
              <div className="select-time">
                <Form.Group controlId="formBasicHiddentime">
                  <h6>Duration</h6>
                  <Button onClick={handleDurationPicker}>
                    {convertToReadableDuration(bookingData.duration)}
                  </Button>
                </Form.Group>
              </div>
            </div>
          </div>
        )}
        {blocktime !== "open" && (
          <div className="search-popup shift-container">
            <div className="time-calender">
              <Calendar
                onChange={handleCalendarDate}
                // value={new Date(dateData.date)}
                value={now_utc}
              />
            </div>
          </div>
        )}
        {blocktime === "open" && (
          <div className="search-popup shift-container shift-bottom">
            <div className="not-repeat">
              <h6>
                {`${repeatAppt ? "Repeat Appointment" : "Doesn't Repeat"}`}
              </h6>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check
                    type="checkbox"
                    onChange={handleRepeat}
                    checked={repeatAppt}
                  />
                </div>
              </div>
            </div>
            {(appointmentType === "oneteam_multiday" ||
              appointmentType === "multiteam_multiday") &&
            !repeatAppt ? (
              <div className="select-time">
                <Form.Group controlId="formBasicHiddentime">
                  <h6>Time Required to Complete</h6>
                  <div className="select-box">
                    <Form.Control
                      as="select"
                      name="requiredday"
                      onChange={handleChangeDateData}
                      value={dateData.requiredday ? dateData.requiredday : 2}
                    >
                      <option value="2" selected>
                        2 Days
                      </option>
                      <option value="3">3 Days</option>
                      <option value="4">4 Days</option>
                      <option value="5">5 Days</option>
                      <option value="6">6 Days</option>
                      <option value="7">7 Days</option>
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
            ) : (
              ""
            )}
            {repeatAppt && (
              <>
                {appointmentType === "oneteam_multiday" ||
                appointmentType === "multiteam_multiday" ? (
                  <div className="select-time">
                    <Form.Group controlId="formBasicHiddentime">
                      <h6>Time Required to Complete</h6>
                      <div className="select-box">
                        <Form.Control
                          as="select"
                          name="requiredday"
                          onChange={handleChangeDateData}
                          value={dateData.requiredday}
                        >
                          <option value="2" selected>
                            2 Days
                          </option>
                          <option value="3">3 Days</option>
                          <option value="4">4 Days</option>
                          <option value="5">5 Days</option>
                          <option value="6">6 Days</option>
                          <option value="7">7 Days</option>
                        </Form.Control>
                      </div>
                    </Form.Group>
                  </div>
                ) : (
                  ""
                )}
                <div className="select-time">
                  <h6>Repeat Frequency</h6>
                  <div className="select-box">
                    <Button
                      type="submit"
                      className="repeat-btn"
                      onClick={handleFrequency}
                    >
                      {`Repeats every ${datePopupData.interval_multiplier} ${
                        datePopupData.interval_multiplier > 1
                          ? datePopupData.interval_type === "monthly"
                            ? "Months"
                            : datePopupData.interval_type === "weekly"
                              ? "Weeks"
                              : datePopupData.interval_type === "daily"
                                ? "Days"
                                : ""
                          : datePopupData.interval_type === "monthly"
                            ? "Month"
                            : datePopupData.interval_type === "weekly"
                              ? "Week"
                              : datePopupData.interval_type === "daily"
                                ? "Day"
                                : ""
                      }`}
                    </Button>
                  </div>
                </div>
                <div className="select-time">
                  <Form.Group controlId="formBasicHiddenEmployees">
                    <h6>Repeat Ends</h6>
                    <div className="select-box">
                      <Form.Control
                        as="select"
                        name="occurence"
                        onChange={handleChangeDateData}
                        value={dateData.occurenceMsg}
                      >
                        <option value="Ongoing" key={dayCount} selected>
                          Ongoing
                        </option>
                        <option value="On a Specific Date" key={dayCount}>
                          On a Specific Date
                        </option>
                        {Array.from(Array(10).keys()).map(() => {
                          dayCount += 1;
                          return (
                            <option
                              value={`After ${dayCount} times`}
                              key={dayCount}
                            >
                              {`After ${dayCount} times`}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </div>
                  </Form.Group>
                </div>

                {dateData.occurenceMsg === "On a Specific Date" && (
                  <>
                    <div className="select-time">
                      <div className="select-box mydate">
                        <Form.Group controlId="formBasicHiddenEmployees">
                          <Button onClick={handleDate}>
                            {`${moment(dateData.specificDate || dateData.date).format(
                              "dddd",
                            )}, ${moment(dateData.specificDate || dateData.date).format(
                              "D MMMM YYYY",
                            )}`}
                          </Button>
                        </Form.Group>
                      </div>
                    </div>
                    <Modal
                      size="md"
                      show={specificDate}
                      onHide={() => setSpecificDate(false)}
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                      className="startdate-modal calendar-modal search-modal time-popup custom-time-popup"
                    >
                      <Modal.Header closeButton />
                      <Modal.Body>
                        <div className="search-popup shift-container">
                          <div className="time-calender">
                            <Calendar
                              onChange={handleCalendarPopup}
                              value={new Date(dateData.specificDate || dateData.date )}
                              tileDisabled={(ele) => {
                                const { date, view } = ele;
                                return view === "month"
                                  ? getDisabledDates(date)
                                  : false;
                              }}
                              minDate={new Date(dateData.date)}
                            />
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </>
                )}
              </>
            )}

            <div className="view-btn time-btn">
              <Button
                type="submit"
                className="btn btn-edit"
                onClick={() =>
                  `${
                    !blocktype && blocktime && blocktime === "open"
                      ? closeBlockTimeModal("close")
                      : blocktype && blocktype === "edit"
                        ? closeBlockTimeModal("deleted")
                        : handleDateModal("close")
                  }`
                }
              >
                {isloader ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : !blocktype && blocktime === "open" ? (
                  "Cancel"
                ) : (
                  "Delete"
                )}
              </Button>
              <Button
                type="submit"
                className="btn btn-save"
                onClick={() =>
                  `${
                    blocktime && blocktime === "open"
                      ? saveBlockTime()
                      : handleDateModal("add", appointmentIndex)
                  }`
                }
              >
                Save/ Update
              </Button>
            </div>
            {repeatFrequency && (
              <div className="time-picker-modal time-popup">
                <div>
                  <div
                    className="time-keeper-popup add-apppt-time"
                    ref={wrapperRef}
                  >
                    <div className="time-keeper">
                      <div className="time-keepr-repeat">
                        <h6>Repeats Every</h6>
                        <h4>
                          {`${datePopupData.interval_multiplier} ${
                            datePopupData.interval_multiplier > 1
                              ? datePopupData.interval_type === "monthly"
                                ? "Months"
                                : datePopupData.interval_type === "weekly"
                                  ? "Weeks"
                                  : datePopupData.interval_type === "daily"
                                    ? "Days"
                                    : ""
                              : datePopupData.interval_type === "monthly"
                                ? "Month"
                                : datePopupData.interval_type === "weekly"
                                  ? "Week"
                                  : datePopupData.interval_type === "daily"
                                    ? "Day"
                                    : ""
                          }`}
                        </h4>
                      </div>
                      <TimePicker
                        ref={timepickerRef}
                        format="12-hour"
                        onChange={(e) =>
                          handleChangeDate(
                            "interval_multiplier",
                            moment(new Date(e.detail.value).toISOString())
                              .format("h:mm")
                              .split(":")[0],
                          )
                        }
                        minuteInterval={5}
                      />
                    </div>
                    <div className="set-btn">
                      <Button
                        onClick={() =>
                          handleChangeDate("interval_type", "daily")
                        }
                        className={`${
                          datePopupData.interval_type === "daily"
                            ? "time-active-btn"
                            : ""
                        }`}
                      >
                        Daily
                      </Button>
                      <Button
                        onClick={() =>
                          handleChangeDate("interval_type", "weekly")
                        }
                        className={`${
                          datePopupData.interval_type === "weekly"
                            ? "time-active-btn"
                            : ""
                        }`}
                      >
                        Weekly
                      </Button>
                      <Button
                        onClick={() =>
                          handleChangeDate("interval_type", "monthly")
                        }
                        className={`${
                          datePopupData.interval_type === "monthly"
                            ? "time-active-btn"
                            : ""
                        }`}
                      >
                        Monthly
                      </Button>
                    </div>
                    <div className="time-keeper-btn">
                      <Button onClick={handleSaveDate}>Save/Update </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {blocktime !== "open" && (
          <div className="search-popup shift-container shift-bottom">
            <div className="not-repeat">
              <h6>
                {`${
                  newAppointment && newAppointment[appointmentIndex].repeatAppt
                    ? "Repeat Appointment"
                    : "Doesn't Repeat"
                }`}
              </h6>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check
                    type="checkbox"
                    onChange={handleRepeat}
                    checked={
                      newAppointment &&
                      newAppointment[appointmentIndex].repeatAppt
                    }
                  />
                </div>
              </div>
            </div>
            {(appointmentType === "oneteam_multiday" ||
              appointmentType === "multiteam_multiday") &&
            !newAppointment[appointmentIndex].repeatAppt ? (
              <div className="select-time">
                <Form.Group controlId="formBasicHiddentime">
                  <h6>Time Required to Complete</h6>
                  <div className="select-box">
                    <Form.Control
                      as="select"
                      name="requiredday"
                      onChange={(e) => setRequiredDays(e.target.value)}
                      value={requiredDays}
                    >
                      <option value="2" selected>
                        2 Days
                      </option>
                      <option value="3">3 Days</option>
                      <option value="4">4 Days</option>
                      <option value="5">5 Days</option>
                      <option value="6">6 Days</option>
                      <option value="7">7 Days</option>
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
            ) : (
              ""
            )}

            {(appointmentType === "multiteam_oneday" ||
              appointmentType === "multiteam_multiday") &&
            !newAppointment[appointmentIndex].repeatAppt ? (
              <div className="select-time">
                <Form.Group controlId="formBasicHiddentime">
                  <h6>No. of Team Members Needed</h6>
                  <div className="select-box">
                    <Form.Control
                      as="select"
                      name="teammember"
                      onChange={(e) => setTeam(e.target.value)}
                      value={team}
                    >
                      {Array.from(Array(49).keys()).map(() => {
                        dayCount += 1;
                        return (
                          <option value={dayCount} key={dayCount}>
                            {dayCount}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
            ) : (
              ""
            )}

            {newAppointment && newAppointment[appointmentIndex].repeatAppt && (
              <>
                {appointmentType === "multiteam_oneday" ||
                appointmentType === "multiteam_multiday" ? (
                  <div className="select-time">
                    <Form.Group controlId="formBasicHiddentime">
                      <h6>No. of Team Members Needed</h6>
                      <div className="select-box">
                        <Form.Control
                          as="select"
                          name="teammember"
                          onChange={(e) => setTeam(e.target.value)}
                          value={team}
                        >
                          {Array.from(Array(49).keys()).map(() => {
                            dayCount += 1;
                            return (
                              <option value={dayCount} key={dayCount}>
                                {dayCount}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </div>
                    </Form.Group>
                  </div>
                ) : (
                  ""
                )}
                {appointmentType === "oneteam_multiday" ||
                appointmentType === "multiteam_multiday" ? (
                  <div className="select-time">
                    <Form.Group controlId="formBasicHiddentime">
                      <h6>Time Required to Complete</h6>
                      <div className="select-box">
                        <Form.Control
                          as="select"
                          name="requiredday"
                          onChange={(e) => setRequiredDays(e.target.value)}
                          value={requiredDays}
                        >
                          <option value="2" selected>
                            2 Days
                          </option>
                          <option value="3">3 Days</option>
                          <option value="4">4 Days</option>
                          <option value="5">5 Days</option>
                          <option value="6">6 Days</option>
                          <option value="7">7 Days</option>
                        </Form.Control>
                      </div>
                    </Form.Group>
                  </div>
                ) : (
                  ""
                )}
                <div className="select-time">
                  <h6>Repeat Frequency</h6>
                  <div className="select-box">
                    <Button
                      type="submit"
                      className="repeat-btn"
                      onClick={handleFrequency}
                    >
                      {`Repeats every ${datePopupData.interval_multiplier} ${
                        datePopupData.interval_multiplier > 1
                          ? datePopupData.interval_type === "monthly"
                            ? "Months"
                            : datePopupData.interval_type === "weekly"
                              ? "Weeks"
                              : datePopupData.interval_type === "daily"
                                ? "Days"
                                : ""
                          : datePopupData.interval_type === "monthly"
                            ? "Month"
                            : datePopupData.interval_type === "weekly"
                              ? "Week"
                              : datePopupData.interval_type === "daily"
                                ? "Day"
                                : ""
                      }`}
                    </Button>
                  </div>
                </div>
                <div className="select-time">
                  {/* {console.log('dayCount', dayCount, dateData)} */}
                  <Form.Group controlId="formBasicHiddenEmployees">
                    <h6>Repeat Ends</h6>
                    <div className="select-box">
                      <Form.Control
                        as="select"
                        name="occurence"
                        onChange={handleChangeDateData}
                        value={dateData.occurenceMsg}
                      >
                        <option value="Ongoing" key={increment} selected>
                          Ongoing
                        </option>
                        <option value="On a Specific Date" key={increment}>
                          On a Specific Date
                        </option>
                        {Array.from(Array(10).keys()).map(() => {
                          increment += 1;
                          return (
                            <option
                              value={`After ${increment} times`}
                              key={increment}
                            >
                              {`After ${increment} times`}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </div>
                  </Form.Group>
                </div>

                {dateData.occurenceMsg === "On a Specific Date" && (
                  <>
                    <div className="select-time">
                      <div className="select-box mydate">
                        <Form.Group controlId="formBasicHiddenEmployees">
                          <Button onClick={handleDate}>
                            {`${moment(dateData.specificDate).format(
                              "dddd",
                            )}, ${moment(dateData.specificDate).format(
                              "D MMMM YYYY",
                            )}`}
                          </Button>
                        </Form.Group>
                      </div>
                    </div>
                    <Modal
                      size="md"
                      show={specificDate}
                      onHide={() => setSpecificDate(false)}
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                      className="startdate-modal calendar-modal search-modal time-popup custom-time-popup"
                    >
                      <Modal.Header closeButton />
                      <Modal.Body>
                        <div className="search-popup shift-container">
                          <div className="time-calender">
                            <Calendar
                              onChange={handleCalendarPopup}
                              value={new Date(dateData.specificDate)}
                              tileDisabled={(ele) => {
                                const { date, view } = ele;
                                return view === "month"
                                  ? getDisabledDates(date)
                                  : false;
                              }}
                              minDate={new Date(dateData.date)}
                            />
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </>
                )}
              </>
            )}

            <div
              className={`view-btn time-btn ${
                appointmentIndex === 0 ? "remove-delete-btn" : ""
              }`}
            >
              {(appointmentType === "oneteam_multiday" ||
                appointmentType === "multiteam_multiday" ||
                appointmentType === "multiteam_oneday") &&
                appointmentIndex !== 0 && (
                  <Button
                    type="submit"
                    className="btn btn-edit"
                    onClick={() =>
                      `${
                        blocktime && blocktime === "open"
                          ? closeBlockTimeModal("close")
                          : handleDateModal("close", appointmentIndex)
                      }`
                    }
                  >
                    Delete
                  </Button>
                )}
              <Button
                type="submit"
                className="btn btn-save"
                onClick={() =>
                  `${
                    blocktime && blocktime === "open"
                      ? saveBlockTime()
                      : handleDateModal("add", appointmentIndex)
                  }`
                }
              >
                Save/ Update
              </Button>
            </div>
            {repeatFrequency && (
              <div className="time-picker-modal time-popup">
                <div>
                  <div
                    className="time-keeper-popup add-apppt-time"
                    ref={wrapperRef}
                  >
                    <div className="time-keeper">
                      <div className="time-keepr-repeat">
                        <h6>Repeats Every</h6>
                        <h4>
                          {`${datePopupData.interval_multiplier} ${
                            datePopupData.interval_multiplier > 1
                              ? datePopupData.interval_type === "monthly"
                                ? "Months"
                                : datePopupData.interval_type === "weekly"
                                  ? "Weeks"
                                  : datePopupData.interval_type === "daily"
                                    ? "Days"
                                    : ""
                              : datePopupData.interval_type === "monthly"
                                ? "Month"
                                : datePopupData.interval_type === "weekly"
                                  ? "Week"
                                  : datePopupData.interval_type === "daily"
                                    ? "Day"
                                    : ""
                          }`}
                        </h4>
                      </div>
                      <TimePicker
                        ref={timepickerRef}
                        format="12-hour"
                        onChange={(e) =>
                          handleChangeDate(
                            "interval_multiplier",
                            moment(new Date(e.detail.value).toISOString())
                              .format("h:mm")
                              .split(":")[0],
                          )
                        }
                        minuteInterval={5}
                      />
                    </div>
                    <div className="set-btn">
                      <Button
                        onClick={() =>
                          handleChangeDate("interval_type", "daily")
                        }
                        className={`${
                          datePopupData.interval_type === "daily"
                            ? "time-active-btn"
                            : ""
                        }`}
                      >
                        Daily
                      </Button>
                      <Button
                        onClick={() =>
                          handleChangeDate("interval_type", "weekly")
                        }
                        className={`${
                          datePopupData.interval_type === "weekly"
                            ? "time-active-btn"
                            : ""
                        }`}
                      >
                        Weekly
                      </Button>
                      <Button
                        onClick={() =>
                          handleChangeDate("interval_type", "monthly")
                        }
                        className={`${
                          datePopupData.interval_type === "monthly"
                            ? "time-active-btn"
                            : ""
                        }`}
                      >
                        Monthly
                      </Button>
                    </div>
                    <div className="time-keeper-btn">
                      <Button onClick={handleSaveDate}>Save/Update </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

DateModal.propTypes = {
  showDate: PropTypes.func.isRequired,
  handleDateModal: PropTypes.func.isRequired,
  handleChangeDateData: PropTypes.func.isRequired,
  dateData: PropTypes.func.isRequired,
  setDateData: PropTypes.func.isRequired,
  setRepeatAppt: PropTypes.func.isRequired,
  repeatAppt: PropTypes.func.isRequired,
  handleSaveDate: PropTypes.func.isRequired,
  repeatFrequency: PropTypes.func.isRequired,
  handleFrequency: PropTypes.func.isRequired,
  handleChangeDate: PropTypes.func.isRequired,
  datePopupData: PropTypes.func.isRequired,
  timepickerRef: PropTypes.func.isRequired,
  handleRepeat: PropTypes.func.isRequired,
  appointmentType: PropTypes.string.isRequired,
  appointmentIndex: PropTypes.string.isRequired,
  setRepeatFrequency: PropTypes.string.isRequired,
  blocktime: PropTypes.string.isRequired,
  closeBlockTimeModal: PropTypes.func.isRequired,
  handleDurationPicker: PropTypes.func.isRequired,
  bookingData: PropTypes.func.isRequired,
  convertToReadableDuration: PropTypes.func.isRequired,
  handleTimePicker: PropTypes.func.isRequired,
  handleBookingData: PropTypes.func.isRequired,
  staffList: PropTypes.string.isRequired,
  setBlcoktimeName: PropTypes.func.isRequired,
  blcoktimeName: PropTypes.string.isRequired,
  saveBlockTime: PropTypes.func.isRequired,
  blocktype: PropTypes.string.isRequired,
  CrossDate: PropTypes.func.isRequired,
  newAppointment: PropTypes.arrayOf.isRequired,
  requiredDays: PropTypes.number.isRequired,
  setRequiredDays: PropTypes.func.isRequired,
  team: PropTypes.number.isRequired,
  setTeam: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  isloader: PropTypes.bool.isRequired,
};

export default DateModal;
