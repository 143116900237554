/* eslint-disable indent */
/* eslint-disable dot-notation */
/* eslint-disable operator-linebreak */
/* eslint-disable jsx-quotes */
/* eslint-disable consistent-return */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
import React, { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import moment from 'moment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import AdminWrapper from '../admin/adminwrapper/wrapper';
import showmore from '../../assets/home/showmoreblue.png';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import flag from '../../assets/admin/aus-flag.png';
import us from '../../assets/admin/us-flag.png';
import './subscription.css';
import EditBillingDetail from './EditBillingDetail';
import CancellationBilling from './CancellationBilling';
import Payments from './Payments';
import UpadateSubscription from './UpadateSubscription';
import CurrentSubscription from './CurrentSubscription';
import { countries, states } from '../country-state/country-state';
import { notifyAlert } from '../calculator/calculatorUtils';
import { userInstance } from '../../config/axios';
import { emailRegex } from '../../config/keys';
import { queryString, validateDataWithKey } from '../../functions';
import { billing_rules, calculateTotalCost } from '../subscription/utils';
import NewCreateSubscription from './newCreateSubscription';

function SubscribeBilling() {
  const { popup } = queryString();
  const { userData, setUserData, testingPurpose, setTestingPurpose } = useContext(AuthDataContext);
  const {
    userData: { industryId, calendarSettings },
  } = useContext(AuthDataContext);
  const { timeZone } = calendarSettings || {};
  const { userInformation } = userData || {};
  const [toggleData, setToggleData] = useState('Current Subscription');
  const [newUpgradeTotalAmount, setNewUpgradeTotalAmount] = useState(0);
  const [selectedCountry, setCountry] = useState('+61');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [mobileFormat, setMobileFormat] = useState('### ### ###');
  const [stateArray, setStateArray] = useState([]);
  const [error, setError] = useState('');
  const [subscriptionDetails, setSubcsriptionDetails] = useState({});
  const [dropdownOptions, setDropDownOptions] = useState({});
  const [allTransaction, setAllTransaction] = useState([]);

  const editUserProfile = async (e) => {
    e.preventDefault();
    const {
      firstname,
      lastname,
      email,
      accessLevel,
      location,
      mobileNo,
      gender,
      state,
      postcode,
      streetAddress,
      suburb,
    } = subscriptionDetails;

    try {
      const { isValid, emptyField } = validateDataWithKey({
        'first name': firstname.trim(),
        'last name': lastname.trim(),
        mobileNo,
        email,
        state,
        postcode,
        suburb,
        streetAddress,
      });

      if (isValid) {
        setError('');
        if (emailRegex.test(String(subscriptionDetails.email).toLowerCase())) {
          let isMobileValid = true;
          let isLandlineValid = true;
          // let mobileMsg = '';
          let errorMsg = '';
          // let landlineMsg = '';
          const payload = {
            ...subscriptionDetails,
            email: email.toLowerCase(),
          };

          // console.log('selectedCountry', selectedCountry);
          console.log('payload', payload.mobileNo.length);
          if (selectedCountry === '+61') {
            if (payload.mobileNo.startsWith('+61')) {
              payload.mobileNo = payload.mobileNo.replace('+61 ', '');
              console.log('payload.mobileNo', payload.mobileNo);
            }
            console.log('payload.mobileNo.charAt(0)', payload.mobileNo.charAt(0));
            console.log('payload.mobileNo.charAt(1)', payload.mobileNo.charAt(1));

            if (payload.mobileNo.charAt(0) !== '4') {

              isMobileValid = false;
              errorMsg = 'Please start mobile number with 4';
              setError('mobileno');
            }
            if (payload.mobileNo.trim().length !== 11) {
              setError('mobileno');
              isMobileValid = false;
              // mobileMsg = 'Invalid Mobile Number';
              errorMsg = 'Enter 9 Digit Mobile Number';
            }
          }

          if (selectedCountry === '+1') {
            if (payload.mobileNo.charAt(0) === '0') {
              payload.mobileNo = payload.mobileNo.replace('0', '');
            }

            if (payload.mobileNo.trim().length !== 12) {
              setError('mobileno');
              isMobileValid = false;
              // mobileMsg = 'Invalid Mobile Number';
              errorMsg = 'Enter 10 Digit Mobile Number';
            }
          }

          if (isMobileValid) {
            payload.mobileNo = `${selectedCountry} ${payload.mobileNo}`;
            const response = await userInstance().put(
              '/user/updateSubcriptionCardDetail',
              {
                ...payload,
              }
            );
            const { msg } = response.data;
            notifyAlert(msg, 'success');
            const copy = { ...userData };
            copy.userInformation = { ...copy.userInformation, ...payload };
            console.log({ copy });
            localStorage.setItem('apptData', JSON.stringify(copy));
            setUserData(copy);
          } else {
            notifyAlert(errorMsg, 'error');
          }
        } else {
          setError('email');
          notifyAlert('Invalid email', 'Error');
        }
      } else {
        setError(emptyField?.split(' ').join('').toLowerCase());
        notifyAlert(`Please fill ${emptyField}`, 'error');
      }
      setTimeout(() => {
        setError('');
      }, 1000);
    } catch (err) {
      // showErrMsg(err);
    }
  };

  const handleCountryStates = (compareValue) => {
    const fIndex = countries.findIndex((el) => el.name === compareValue);
    if (fIndex >= 0) {
      const copyStates = JSON.parse(JSON.stringify(states));
      const filteredArray = copyStates.filter(
        (el) => el.countryCode === countries[fIndex].isoCode
      );
      const list = filteredArray.sort((a, b) => a.name.localeCompare(b.name));
      setCountry(`+${countries[fIndex].phonecode}`);
      setStateArray(filteredArray);
    }
  };

  const handleToggleSubscription = (data) => {
    if (data === 'Edit Billing Details') {
      handleCountryStates(subscriptionDetails?.country);
    }
    const hh = testingPurpose;
    hh['toggle'] = data
    setToggleData(data);
    setTestingPurpose(hh)
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    if (userInformation?._id) {
      setSubcsriptionDetails(
        JSON.parse(
          JSON.stringify({
            ...userInformation,
            numberUsers: popup ? 2 : userInformation?.numberUsers,
            numberOfPools: popup ? 1 : userInformation?.numberOfPools,
            duration: popup ? 12 : userInformation?.duration,
            country: userInformation?.country,
          })
        )
      );
      setDropDownOptions({
        numberUsers: popup ? 2 : userInformation?.numberUsers,
        numberOfPools: popup ? 1 : userInformation?.numberOfPools,
        duration: popup ? 12 : userInformation?.duration,
        totalCost: 0,
        country: userInformation?.country,
      });
      if (popup === 'updateSubscription') {
        setToggleData('Add Subscription');
      }
    }

    return () => {
      setTestingPurpose({ ...testingPurpose, ['toggle']: '' })
    }
  }, [userInformation?._id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log('Value Check======>>', { name, value });

    const copy = { ...subscriptionDetails };

    if (name === 'country') {
      if (value === 'Australia') {
        setMobileFormat('### ### ###');
      }
      if (value === 'United States') {
        setMobileFormat('### ### ####');
      }
      handleCountryStates(value);
      copy.state = '';
    }

    const validateConditionalFields = () => {
      // Assuming userInformation contains the current duration
      const currentDuration = Number(userInformation?.duration) || 0;
      const currentNumberOfPool = Number(userInformation?.numberOfPools) || 0;
      const currentNumberOfUser = Number(userInformation?.numberUsers) || 0;

      if (
        name === 'duration' &&
        currentDuration &&
        Number(value) < currentDuration
      ) {
        console.log(
          "Selected duration can't be less than or equal to the current duration."
        );
        return false;
      }

      if (
        name === 'numberOfPools' &&
        currentNumberOfPool &&
        Number(value) < currentNumberOfPool
      ) {
        console.log(
          "Selected duration can't be less than or equal to the current numberOfPools."
        );
        return false;
      }

      if (
        name === 'numberUsers' &&
        currentNumberOfUser &&
        Number(value) < currentNumberOfUser
      ) {
        console.log(
          "Selected duration can't be less than or equal to the current numberUsers."
        );
        return false;
      }
      if (name === 'postcode' && value.length >= 10) {
        return false;
      }

      copy[name] = value;

      if (name === 'duration') {
        if (currentDuration === Number(value)) {
          copy['numberUsers'] = userInformation?.numberUsers;
          copy['numberOfPools'] = userInformation?.numberOfPools;
        } else {
          copy['numberUsers'] = ['24', 24, '12', 12, 36, '36', 0, '0'].includes(
            value
          )
            ? '2'
            : '2';
          copy['numberOfPools'] = [
            '24',
            24,
            '12',
            12,
            36,
            '36',
            0,
            '0',
          ].includes(value)
            ? '1'
            : '1';
        }
      }

      if (name === 'duration') {
        const billingDetails = billing_rules[
          subscriptionDetails.country
        ].find((el) => el.duration === parseInt(Number(value)));
        copy['totalCost'] = billingDetails.cost

      }
      return true;
    };

    if (!validateConditionalFields()) return;
    setSubcsriptionDetails(copy);
    newPlanUpgrade(
      copy['duration'],
      copy['totalCost'],
      copy['numberOfPools'],
      copy['numberUsers']
    );
  };

  const history = useHistory();
  const handleClose = () => {
    history.push('/calendar');
  };
  const handleCancel = () => {
    setToggleData('Current Subscription');
  };
  // Force Moment.js to use the 'en' locale for consistency
  moment.locale("en");

  const startDate = moment(userInformation?.startsubscriptionDate).utc();
  const endDate = moment(userInformation?.endsubscriptionDate).utc();

  // Log the formatted start and end dates to check their consistency across browsers
  console.log("Formatted Start Date:", startDate.format("YYYY-MM-DD"));
  console.log("Formatted End Date:", endDate.format("YYYY-MM-DD"));
  const calculateRemainingMonths = (startDate, endDate, currentDate) => {
    const start = moment(startDate, "YYYY MM DD");
    const end = moment(endDate, "YYYY MM DD");
    const current = moment(currentDate);
    const months = end.diff(current, "months", true);
    return Math.max(months, 0);
  };
  const calculateTotal = (noOfPools, noOfUsers) => {
    const remainingMonths = calculateRemainingMonths(
      startDate.format("YYYY MM DD"),
      endDate.format("YYYY MM DD"),
      new Date()
    );

    let countryName = subscriptionDetails.country;
    const totalAmount = calculateTotalCost(0, remainingMonths, noOfPools, noOfUsers, countryName);
    setNewUpgradeTotalAmount(totalAmount);
  };
  const newPlanUpgrade = (
    duration,
    totalCost,
    noOfPool,
    noOfUsers,
    specificDate
  ) => {
    if (
      Number(userInformation?.duration) !==
      Number(duration || subscriptionDetails?.duration)
    ) {
      const baseAmount = Number(userInformation?.totalCost);
      let previousTotalAmount = 0;
      if (baseAmount > 0) {
        const monthlyDeduction = baseAmount / Number(userInformation?.duration);
        const remainingMonths = calculateRemainingMonths(
          moment(userInformation?.startsubscriptionDate).format('YYYY MM DD'),
          moment(userInformation?.endsubscriptionDate).format('YYYY MM DD'),
          specificDate ? specificDate : new Date()
        );
        previousTotalAmount = monthlyDeduction * remainingMonths;
      }

      const billingDetails = billing_rules[
        subscriptionDetails.country
      ].find((el) => el.duration === parseInt(duration));

      const { cost } = billingDetails;
      const productPrice = calculateTotalCost(cost, parseInt(duration), noOfPool, noOfUsers, subscriptionDetails.country);
      const newTotalAmount = parseFloat(productPrice - previousTotalAmount).toFixed(2);

      setNewUpgradeTotalAmount(newTotalAmount);
    } else {
      calculateTotal(
        Number(noOfPool || userInformation?.numberOfPools),
        Number(noOfUsers || userInformation?.numberUsers)
      );
    }
  };

  const getAllTransaction = async () => {
    console.log('userData===>', userData);
    try {
      const response = await userInstance().get(
        `/user/transactions?page=1&limit=100`
      );
      console.log('responsere ====>', response.data);
      if (response?.status === 200) {
        setAllTransaction(response?.data?.data);
      }
    } catch (e) {
      console.log('============>>', e);
    }
  };

  useEffect(() => {
    getAllTransaction();
    setNewUpgradeTotalAmount(
      userInformation?.subscriptionStatus === 'canceled'
        ? 264
        : userInformation?.totalCost
    );
  }, []);
  useEffect(() => {
    if (userInformation?.subscriptionStatus === 'canceled') {
      console.log('history======>>', history);
      if (
        history?.location?.pathname === '/subscription-management' &&
        history?.location?.search?.length === 0
      ) {
        console.log('history======>>AAAYYYYAYAY REE');
        window.location.href =
          '/subscription-management?popup=updateSubscription';
      }
    }
  });
  return (
    <AdminWrapper>
      <div className=' staff-container'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <>
                <div
                  className='back-option'
                  role='presentation'
                  onClick={handleClose}
                >
                  <span />
                  <i className='fa fa-times' aria-hidden='true' />
                </div>
                <div className='main-titles-mob'>
                  <h3>Billing</h3>
                </div>

                <div className='target-heading align-items-center'>
                  <h6 className='mb-0'>{toggleData} </h6>
                  {toggleData === 'Add Subscription' ? (
                    ''
                  ) : (
                    <div className='client-option service-edit-icon'>
                      <Dropdown
                        show={isDropdownOpen}
                        onToggle={(isOpen) => setIsDropdownOpen(isOpen)}
                      >
                        <Dropdown.Toggle variant='success' id='dropdown-basic'>
                          <img src={showmore} alt='show' />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <div
                            className='action'
                            onClick={() => {
                              handleToggleSubscription('Current Subscription');
                            }}
                            role='presentation'
                          >
                            <span>Current Subscription</span>
                          </div>
                          <div
                            className='action'
                            onClick={() => {
                              handleToggleSubscription('Update Subscription');
                            }}
                            role='presentation'
                          >
                            <span>Update Subscription</span>
                          </div>
                          <div
                            className='action'
                            onClick={() => {
                              handleToggleSubscription('Payments');
                            }}
                            role='presentation'
                          >
                            <span>Payments</span>
                          </div>
                          <div
                            className='action'
                            onClick={() => {
                              handleToggleSubscription('Cancel Subscription');
                            }}
                            role='presentation'
                          >
                            <span>Cancel Subscription</span>
                          </div>
                          <div
                            className='action'
                            onClick={() => {
                              handleToggleSubscription('Edit Billing Details');
                            }}
                            role='presentation'
                          >
                            <span>Edit Billing Details</span>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  )}
                </div>
                {toggleData === 'Current Subscription' && (
                  <CurrentSubscription subscriptionDetails={userInformation} />
                )}
                {toggleData === 'Update Subscription' && (
                  <>
                    <UpadateSubscription
                      handleChange={handleChange}
                      error={error}
                      subscriptionDetails={subscriptionDetails}
                      userInformation={userInformation}
                      handleClose={handleClose}
                      newPlanUpgrade={newPlanUpgrade}
                      handleToggleSubscription={handleToggleSubscription}
                      newUpgradeTotalAmount={newUpgradeTotalAmount}
                      handleCancel={handleCancel}
                    />
                  </>
                )}

                {toggleData === 'Add Subscription' && (
                  <>
                    <NewCreateSubscription
                      handleChange={handleChange}
                      error={error}
                      subscriptionDetails={subscriptionDetails}
                      userInformation={userInformation}
                      handleClose={handleClose}
                      newPlanUpgrade={newPlanUpgrade}
                      newUpgradeTotalAmount={newUpgradeTotalAmount}
                    />
                  </>
                )}

                {toggleData === 'Payments' && (
                  <Payments allTransaction={allTransaction} />
                )}

                {toggleData === 'Cancel Subscription' && (
                  <>
                    <CancellationBilling
                      userInformation={userInformation}
                      handleToggleSubscription={handleToggleSubscription}
                    />
                  </>
                )}

                {toggleData === 'Edit Billing Details' && (
                  <EditBillingDetail
                    selectedCountry={selectedCountry}
                    flag={flag}
                    us={us}
                    subscriptionDetails={subscriptionDetails}
                    setMobileFormat={setMobileFormat}
                    mobileFormat={mobileFormat}
                    countries={countries}
                    stateArray={stateArray}
                    error={error}
                    handleChange={handleChange}
                    editUserProfile={editUserProfile}
                    userInformation={userInformation}
                    handleCancel={handleCancel}
                  />
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
}

export default SubscribeBilling;
