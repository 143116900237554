import React, { useEffect, useState } from 'react';
import AdminWrapper from '../admin/adminwrapper/wrapper'; // Ensure correct path
import "./addChemicalPool.css";
import Accordion from 'react-bootstrap/Accordion';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported
import banner1 from "../../assets/desktop-view/banner_img2.webp"
import soda from "../../assets/desktop-view/soda.png"
import Muriatic_Acid from "../../assets/desktop-view/new-shutterstock.jpg"
import dry from "../../assets/desktop-view/Dry_Acid.webp"
import chlorine from "../../assets/desktop-view/chlorine.png"
import chlorineld from "../../assets/desktop-view/chlorinld.png"
import shockStatement from "../../assets/desktop-view/shock_treatment.jpg"
import potassiummono from "../../assets/desktop-view/Potassium-Mono.jpg"
import buffer from "../../assets/desktop-view/buffer.jpg"
import calciumIncrease from "../../assets/desktop-view/CalciumIncrease.jpg"
import calciumDecrease from "../../assets/desktop-view/CalciumDecrease.png"
import stabiliser from "../../assets/desktop-view/new-Stabiliser.jpg"
import salt from "../../assets/desktop-view/salt.jpg"
import minerals from "../../assets/desktop-view/Magnapool_Minerals.webp"
import phosphate from "../../assets/desktop-view/phosphate-remover.png"
import Algae from "../../assets/desktop-view/Algaecide.jpg"
import metal from "../../assets/desktop-view/metal-reducer.png"
import helpTips from "../../assets/desktop-view/helpTips.webp"
import { number } from 'prop-types';
import Loader from '../loader/loader';

const AddChemicalPools = () => {
    const [openIndex, setOpenIndex] = useState(null);
    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const [loading,setLoading]=useState(false);

    let chemicalArr = [
        {
            id: 0,
            title: "Soda Ash",
            content: {
                para1: ["Sodium carbonate, known as soda ash, is used to raise the pH of your pool water."],
                para_ul: [
                    "Add Soda Ash into a large plastic bucket, then add water and thoroughly mix.",
                    "Pour the mixture gently (to avoid splashing) around the perimeter of the pool, proportionally adding more in the deeper end than the shallow end."
                ],
                para_item2: []
            },
            img: soda
        },
        {
            id: 1,
            title: "Hydrochloric or Muriatic Acid",
            content: {
                para1: ["Hydrochloric and muriatic acid are both liquid products and must be handled with care. They are used to lower pH and alkalinity."],
                para_ul: [
                    "Pour gently (to avoid splashing) around the pool’s perimeter, proportionally distributing more in the deeper end than the shallow end.",
                    "If you get hydrochloric or muriatic acid on your skin or in your eyes, flush with water immediately and seek medical attention."
                ],
                para_item2: []
            },
            img: Muriatic_Acid
        },
        {
            id: 2,
            title: "Dry Acid",
            content: {
                para1: [
                    "Dry acid, also known as pH down or pH minus, is made from sodium bisulphate.",
                    "They’re safer and less corrosive to use than muriatic or hydrochloric acid and also used to lower pH and alkalinity."
                ],
                para_ul: [
                    "Add the dry acid to a large plastic bucket, then add water and thoroughly mix.",
                    "Pour the mixture gently (to avoid splashing) around the perimeter of the pool, proportionally adding more in the deeper end than the shallow end.",
                    "If you get hydrochloric or muriatic acid on your skin or in your eyes, flush with water immediately and seek medical attention."
                ],
                para_item2: []
            },
            img: dry
        },
        {
            id: 3,
            title: "Chlorine (granular)",
            content: {
                para1: ["Chlorine is the most common sanitizer used in pools because of its effectiveness in killing bacteria and preventing algae growth."],
                para_ul: [
                    "Place the granular chlorine into a large plastic bucket, then add water and thoroughly mix.",
                    "Mix thoroughly to fully dissolve to minimize clouding of the water.",
                    "Pour the mixture gently (to avoid splashing) around the pool’s perimeter, proportionally distributing more in the deeper end than the shallow end.",
                    "If you get chlorine on your skin or in your eyes, flush with water immediately and seek medical attention if irritation persists."
                ],
                para_item2: []
            },
            img: chlorine
        },
        {
            id: 4,
            title: "Chlorine (liquid)",
            content: {
                para1: ["Liquid chlorine is a better option to use than granular chlorine because it doesn’t cloud the water or leave residue on the floor of the pool."],
                para_ul: [
                    "When adding liquid chlorine, pour it gently from the container (to avoid splashing) around the pool’s perimeter, proportionally distributing more in the deeper end than the shallow end.",
                    "If you get chlorine on your skin or in your eyes, flush with water immediately and seek medical attention if irritation persists."
                ],
                para_item2: []
            },
            img: chlorineld
        },
        {
            id: 5,
            title: "Shock Treatment",
            content: {
                para1: ["Shock treatment, also known as super chlorination, is used to raise the chlorine level quickly in a pool to kill bacteria, algae, or other contaminants."],
                para_ul: [
                    "Granular chlorine, liquid chlorine, and other specialist products are options for shocking your pool.",
                    "Shock treatment is usually done periodically or to specifically resolve issues such as cloudy water, algae growth, or an increase in organic matter.",
                    "Refer to the manufacturer’s instructions for dosage quantities and procedures for adding to your pool."
                ],
                para_item2: [
                    "It is recommended to add other chemicals after shock treatment, once chlorine is back into range.",
                    "If you need to vacuum the pool to waste to remove any dead algae or other debris from the floor of the pool, it will save unnecessarily removing recently added chemicals during this process.",
                    "Test the water 24-48 hours after treatment.",
                    "Chlorine levels should be back within the recommended range of 1-3 ppm before allowing swimmers back into the pool."
                ]
            },
            img: shockStatement
        },
        {
            id: 6,
            title: "Potassium Monopersulphate",
            content: {
                para1: ["Potassium monopersulphate is used to lower combined chlorine levels in the pool."],
                para_ul: [
                    "Add the potassium monopersulphate to a large plastic bucket, then add water and thoroughly mix.",
                    "Pour the mixture gently (to avoid splashing) around the pool’s perimeter, proportionally distributing more in the deeper end than the shallow end."
                ],
                para_item2: []
            },
            img: potassiummono
        },
        {
            id: 7,
            title: "Buffer",
            content: {
                para1: ["Buffer also known as Sodium Bicarbonate, Bicarbonate of Soda or Baking Soda, helps to maintain the stability of your pool’s pH levels.",
                    "There are various products available to increase alkalinity such as Buffer, Alkalinity Increaser or Alkalinity Up."],
                para_ul: [
                    "They all contain sodium bicarbonate, commonly known as bicarbonate of soda or baking soda.",
                    "Baking soda is used in cooking, also as an antacid to treat indigestion, so it is regarded as a safe product.",
                    "Add the sodium bicarbonate to a large plastic bucket, then add water and thoroughly mix.",
                    "Pour the mixture gently (to avoid splashing) around the pool’s perimeter, proportionally distributing more in the deeper end than the shallow end."
                ],
                para_item2: []
            },
            img: buffer
        },
        {
            id: 8,
            title: "Calcium Increaser",
            content: {
                para1: ["To increase calcium hardness, calcium chloride is added to the water. Products available are known as Calcium Increaser, Calcium Up, or Hardness Increaser."],
                para_ul: [
                    "Add the calcium chloride to a large plastic bucket, then add water and thoroughly mix.",
                    "Pour the mixture gently (to avoid splashing) around the pool’s perimeter, proportionally distributing more in the deeper end than the shallow end."
                ],
                para_item2: []
            },
            img: calciumIncrease
        },
        {
            id: 9,
            title: "Calcium Decreaser",
            content: {
                para1: ["Reducing calcium levels in your pool is either done by part-draining your pool or using a calcium reducer or calcium hardness decreaser product."],
                para_ul: ["Refer to the manufacturer’s instructions for dosage quantities and procedures for adding to your pool."],
                para_item2: []
            },
            img: calciumDecrease
        },
        {
            id: 10,
            title: "Stabiliser (Cyanuric Acid)",
            content: {
                para1: ["Cyanuric acid is a chemical compound that is used to increase stabiliser levels in swimming pools."],
                para_ul: [
                    "Add the cyanuric acid to a large plastic bucket, then add water and thoroughly mix.",
                    "Pour the mixture gently (to avoid splashing) around the pool’s perimeter, proportionally distributing more in the deeper end than the shallow end."
                ],
                para_item2: []
            },
            img: stabiliser
        },
        {
            id: 11,
            title: "Algaecide",
            content: {
                para1: [
                    "Algaecide is available from several different manufacturers.",
                    "It’s normally used to help prevent algae in your pool and is not effective once algae have begun to develop.",
                    "Copper-based algaecide can be used to kill algae, but use in moderation to avoid excessive metals in your pool that can cause staining."
                ],
                para_ul: [
                    "Refer to the manufacturer’s instructions for dosage quantities and procedures for adding to your pool.",
                    "Do not swim for 24 hours after adding algaecide to your pool."
                ],
                para_item2: []
            },
            img: Algae
        },

        {
            id: 12,
            title: "Salt",
            content: {
                para1: ["Salt is sodium chloride, which produces chlorine through an electrolysis process in a salt water chlorination system.",],
                para_ul: [
                    "Salt comes in bags, distribute them evenly around the pool perimeter.",
                    "Over hours it will dissolve and become invisible in your pool water."
                ],

                para_item2: []
            },
            img: salt
        },
        {
            id: 13,
            title: "Minerals",
            content: {
                para1: ["Minerals usually contain magnesium and potassium chloride, both of which are also used to produce chlorine through an electrolysis process and chlorinator cell.",],
                para_ul: [
                    "Distribute the bags of minerals evenly around the pool perimeter.",
                    "The minerals will dissolve quickly to become invisible in your pool water.",
                    "Brushing any lumps to reduce their size will assist in dissolving."
                ],

                para_item2: []
            },
            img: minerals
        },

        {
            id: 14,
            title: "Phosphate Remover",
            content: {
                para1: ["Several chemical manufactures produce phosphate remover. Using this product removes phosphates from the water, which is the primary food source for algae.",],
                para_ul: [
                    "Refer to the manufacturer’s instructions for dosage quantities and procedures for adding to your pool."],

                para_item2: []
            },
            img: phosphate
        },

        {
            id: 15,
            title: "Metal Reducer",
            content: {
                para1: ["Various products are available that bind the metal ions in the water, preventing them from causing staining or irritation.",],
                para_ul: [
                    "Refer to the manufacturer’s instructions for dosage quantities and procedures for adding to your pool."],
                para_item2: []
            },
            img: metal
        },

    ];

    const additionalTipsArray = [
        {
            title: "Additional Tips for Successful Chemical Treatment of Your Pool",
            img: helpTips,
            tips: [
                {
                    id: 1,
                    heading: "Dissolve Before Adding",
                    content:
                        "When adding chemicals to your pool, always dissolve them in a bucket of water first, following the manufacturer’s instructions carefully. This helps prevent chemical reactions and ensures even distribution of the chemicals throughout the pool water.",
                },
                {
                    id: 2,
                    heading: "Chemicals First, Water Second",
                    content:
                        "When diluting chemicals in a bucket, always add chemicals to the bucket first, then add the water.",
                    note: "Adding water to chemicals helps prevent splashing and minimizes the risk of chemical splatter.",
                },
                {
                    id: 3,
                    heading: "Pre-Dissolve Granular Chlorine",
                    content:
                        "Granular chlorine should always be pre-dissolved in a bucket of water before adding it to the pool.",
                    note: "Adding granular chlorine directly to the pool water can result in localized concentration and may cause bleaching or staining of pool surfaces.",
                },
                {
                    id: 4,
                    heading: "Remove Pool Cleaner",
                    content:
                        "Before adding chemicals to your pool, remove any automatic pool cleaners or robotic cleaners from the pool.",
                    note: "Chemicals can damage pool cleaner components and reduce their effectiveness.",
                },
                {
                    id: 5,
                    heading: "Avoid Skimmer Box",
                    content:
                        "Never pour chemicals directly into the skimmer box of your pool.",
                    note: "Chemicals added directly to the skimmer box can damage the filtration system and may result in uneven distribution of chemicals throughout the pool water.",
                },
                {
                    id: 6,
                    heading: "Monitor Copper Levels",
                    content:
                        "If you’re using chlorine products in your pool, be mindful of the copper levels in your water.",
                    note: "High copper levels can cause water discolouration, staining, and reduced chlorine effectiveness.",
                    note1: "Avoid adding chlorine products to pools with copper levels higher than 0.2ppm."
                },
            ],
        },
    ]

    useEffect(()=>{
        setLoading(true);
      
        const timer = setTimeout(() => {
            setLoading(false);
          }, 1000);
      
          return () => clearTimeout(timer);
    },[location])

    return (
        <div className='Chemicals-container'>
            <AdminWrapper>
                {loading?<Loader/> :
                <div className='container'>
                    <div className='chemicals-outer'>
                        <div className='chemicals-left'>
                            <div>
                                <h3 className='text-center'>How to Add Chemicals to Your Pool</h3>
                                <p className='text-center pt-3 pb-3'>
                                    Testing and adding chemicals to balance your pool water are necessary to maintain a healthy swimming environment.
                                    You should test the pool water weekly during the summer and at least monthly during other non-swimming periods.
                                    Careful handling of pool chemicals is necessary for your safety. When adding chemicals, wear protective gear,
                                    avoid mixing and store them properly afterwards. Wait one hour between adding different chemicals and run the
                                    equipment during this time. Avoid adding chemicals immediately after each other, as this can cause reactions or cloudiness,
                                    depending on the chemical being added. Add chemicals to your pool in the same order as listed below.</p>
                                <img src={banner1} alt='chemical_banner' width="100%" />
                            </div>
                        </div>
                        <div className='chemicals-right'>
                            <div className="accordion">
                                {chemicalArr.map((item) => {
                                    return (
                                        <div className="accordion-item">
                                            <button className="accordion-header" onClick={() => toggleAccordion(item.id)}>
                                                <h6 className='m-0'>{item.title}</h6> <ChemicalsSVG />
                                            </button>
                                            <div className={`accordion-content ${openIndex === item.id ? "open" : ""}`}>
                                                {item.content.para1.map((para, index) => (
                                                    <p key={index}>{para}</p>
                                                ))}

                                                {item.content.para_ul.length > 0 && (
                                                    <ul className="list-disc pl-6">
                                                        {item.content.para_ul.map((point, index) => (
                                                            <li key={index}>{point}</li>
                                                        ))}
                                                    </ul>
                                                )}
                                                {item.content.para_item2.length > 0 && (
                                                    <div className="mt-3">
                                                        {item.content.para_item2.map((note, index) => (
                                                            <p key={index}>{note}</p>
                                                        ))}
                                                    </div>
                                                )}
                                                <div className='chemicals_img'>
                                                    <img src={item.img} alt='chemical_banner' />
                                                </div>

                                            </div>
                                        </div>
                                    )
                                })}

                                <div className="accordion-item">
                                    <button className="accordion-header" onClick={() => toggleAccordion(16)}>
                                        <h6 className='m-0'>{additionalTipsArray[0].title}</h6> <ChemicalsSVG />
                                    </button>
                                    <div className={`accordion-content ${openIndex === 16 ? "open" : ""}`}>
                                        {additionalTipsArray[0].tips.map((tip, i) => (
                                            <div key={tip.id} className='mb-4 additional-changes' >
                                                <h6 >{i + 1}. {tip.heading}</h6>
                                                <p >{tip.content}</p>
                                                {tip.note && <ul> <li className='ml-3'>{tip.note}</li></ul>}
                                                {tip.note1 && <ul> <li className='ml-3'>{tip.note1}</li></ul>}
                                            </div>
                                        ))}
                                        <div className='chemicals_img'>
                                            <img src={additionalTipsArray[0].img} alt='chemical_banner' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </AdminWrapper>
        </div>
    );
}

export default AddChemicalPools;

const ChemicalsSVG = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4" />
        </svg>
    )
}
