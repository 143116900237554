import React, { useContext, useState, useEffect } from "react";
import { Accordion, Button, Card, Form } from "react-bootstrap";
import { NotificationManager } from "react-notifications";

import AdminWrapper from "../admin/adminwrapper/wrapper";
import "./permissionlevel.css";
import { AuthDataContext } from "../../contexts/AuthDataContext";
import { userInstance } from "../../config/axios";
import { errorMsgTime, successMsgTime } from "../../functions/intervalTime";
import toastMessage from "../../functions/toastMessage";
import Loader from "../loader/loader";

const NewPermissionLayout = (props) => {
  const { userData, setUserData } = useContext(AuthDataContext);
  const [permissionsState, setpermissionsState] = useState([]);
  const [userPermissionsArray, setuserPermissionsData] = useState([]);
  const [run, setRun] = useState(false);
  const [disableCheckBox, setDisableCheckBox] = useState(false);
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line react/prop-types
  const { userId } = props;

  useEffect(() => {
    const newArray = [];
    let userPermissionsData = userData.userPermissions;
    userPermissionsData.forEach((el) => {
      if (el.class === "main-menu" && el.action !== "Admin") {
        newArray.push({
          ...el,
          child: userPermissionsData.filter(
            (elem) =>
              elem?.category === el.category &&
              (elem.class === "submenu-item" ||
                elem.class?.includes("sub-menu")),
          ),
        });
      }
    });
    setpermissionsState([...newArray]);
  }, [userData?.role]);

  const checkPermission = (level, id) => {
    const copy = { ...userPermissionsArray };
    let isCheck = false;
    if (copy[level]?.length > 0) {
      isCheck = copy[level]?.includes(id);
    }
    return isCheck;
  };

  const updatePermission = async ({ e, el, type, level }) => {
    try {
      setDisableCheckBox(true);
      console.log({ permissionsState });
      let isCheck = e.target.checked;
      let levelArray = [];
      levelArray = [el._id];
      if (type === "parent") {
        let { child } = el;
        let childsid = child.map((chiId) => chiId._id);
        if (childsid?.length > 0) {
          levelArray = [...levelArray, ...childsid];
        }
      } else if (type === "child") {
        if (isCheck) {
          let fIndex = permissionsState.findIndex(
            (elm) => elm.category === el.category && elm.class === "main-menu",
          );
          if (fIndex >= 0) {
            levelArray = [...levelArray, ...[permissionsState[fIndex]?._id]];
          }
        }
      }
      const dataPayload = {
        levelArray,
        level,
        eventType: isCheck ? "$push" : "$pullAll",
      };

      // console.log({ dataPayload });
      const response = await userInstance().put(
        "/userPermissions/addNewUserPermissions",
        dataPayload,
      );
      if (response) {
        toastMessage("success", "Permission Updated");
        setDisableCheckBox(false);
        if (userId) {
          getUserPermissions();
        }
      } else {
        toastMessage("error", "Something Went Wrong");
      }
    } catch (error) {
      setDisableCheckBox(false);
    }
  };

  useEffect(() => {
    if (userId) {
      setLoading(true);
      getUserPermissions("runLoader");
    }
  }, [userId]);

  const getUserPermissions = async (typeData) => {
    try {
      const result = await userInstance().get(
        `/userPermissions/getUserPermissionsById/${userId}`,
      );
      setLoading(false);
      const { code, usersPermissions } = result?.data;
      if (code === 200) {
        setuserPermissionsData(usersPermissions);
      }
      setRun(false);
    } catch (error) {
      setLoading(false);
      console.log("error ===>", error);
    }
  };

  const NOTSHOWACCORDION = [
    "Calculator",
    "Subscription Management1",
    "Calendar",
  ];
  console.log(permissionsState, "permissionsState");
  return (
    <AdminWrapper>
      {loading ? (
        <Loader />
      ) : (
        <div className="staff-container permission-new-layout">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="main-titles-mob">
                  <h3>Permissions & Access Level</h3>
                </div>
                <div id="table-scroll" className="staff-table table-scroll">
                  <div className="mob-table">
                    {permissionsState.map((el) => (
                      <>
                        {el.action === "Logout" ? null : (
                          <Accordion
                            className="mob-accordion"
                            key="0"
                            defaultActiveKey="0"
                          >
                            <Card>
                              <Card.Header>
                                {/* <div className="permission-new-section">
                                  <h6>Low</h6>
                                  <h6>Medium</h6>
                                  <h6>High</h6>
                                </div> */}

                                <div className="staff-main-heading heading-staff1">
                                  <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey="0"
                                  >
                                    {/* <i className="fas fa-chevron-circle-down" /> */}
                                    <i
                                      className="fas far fa-chevron-circle-down"
                                      style={{
                                        visibility:
                                          !(el.child.length > 0) ||
                                          NOTSHOWACCORDION.includes(el.action)
                                            ? "hidden"
                                            : "visible",
                                      }}
                                    />
                                    
                                    <span>{el.action}</span>
                                  </Accordion.Toggle>
                                  <div className="check-permission">
                                    <div className="permission-new-section">
                                      <h6 className="check-permission-low">Low</h6>
                                      <h6>Medium</h6>
                                      <h6 className="check-permission-high">High</h6>
                                      {/* <h6>B</h6>
                              <h6>P</h6>
                              <h6>S</h6> */}
                                    </div>
                                    <div className="permission-access">
                                      <div className="basic permission-basic1">
                                        <Form.Check
                                          type="checkbox"
                                          checked={checkPermission(
                                            "low",
                                            el._id,
                                          )}
                                          onChange={(e) =>
                                            updatePermission({
                                              e,
                                              el,
                                              type: "parent",
                                              level: "low",
                                            })
                                          }
                                          disabled={disableCheckBox}
                                        />
                                      </div>
                                      <div className="basic permission-basic2">
                                        <Form.Check
                                          type="checkbox"
                                          checked={checkPermission(
                                            "medium",
                                            el._id,
                                          )}
                                          onChange={(e) =>
                                            updatePermission({
                                              e,
                                              el,
                                              type: "parent",
                                              level: "medium",
                                            })
                                          }
                                          disabled={disableCheckBox}
                                        />
                                      </div>
                                      <div className="basic permission-basic3">
                                        <Form.Check
                                          type="checkbox"
                                          checked={checkPermission(
                                            "high",
                                            el._id,
                                          )}
                                          onChange={(e) =>
                                            updatePermission({
                                              e,
                                              el,
                                              type: "parent",
                                              level: "high",
                                            })
                                          }
                                          disabled={disableCheckBox}
                                        />
                                      </div>
                                      {/* <div className="basic">
                                    <Form.Check
                                      type="checkbox"
                                      checked={checkPermission('basic', el._id)}
                                      onChange={(e) => updatePermission({
                                        e, el, type: 'parent', level: 'basic',
                                      })}
                                    />
                                  </div>
                                  <div className="basic">
                                    <Form.Check
                                      type="checkbox"
                                      checked={checkPermission('premium', el._id)}
                                      onChange={(e) => updatePermission({
                                        e, el, type: 'parent', level: 'premium',
                                      })}
                                    />
                                  </div>
                                  <div className="basic">
                                    <Form.Check
                                      type="checkbox"
                                      checked={checkPermission('standard', el._id)}
                                      onChange={(e) => updatePermission({
                                        e, el, type: 'parent', level: 'standard',
                                      })}
                                    />
                                  </div> */}
                                    </div>
                                  </div>
                                </div>
                              </Card.Header>
                              {el?.child?.length > 0 &&
                                el.child.map((elem) =>
                                  ["Calculator", "Calendar", "Logout"].includes(
                                    elem?.category,
                                  ) ||
                                  elem.action ===
                                    "Chemical costs for predictive ordering" ? null : (
                                    <Accordion.Collapse
                                      eventKey="0"
                                      key={elem._id}
                                    >
                                      <Card.Body>
                                        <ul className="staff-list permission-child">
                                          <li className="staff-list-item">
                                            <div className="staff-box">
                                              <h3>{elem.action}</h3>
                                              <h5>
                                                <div className="check-permission">
                                                  <div className="permission-access">
                                                    <div className="basic">
                                                      <Form.Check
                                                        type="checkbox"
                                                        checked={checkPermission(
                                                          "low",
                                                          elem?._id,
                                                        )}
                                                        onChange={(e) =>
                                                          updatePermission({
                                                            e,
                                                            el: elem,
                                                            type: "child",
                                                            level: "low",
                                                          })
                                                        }
                                                        disabled={
                                                          disableCheckBox
                                                        }
                                                      />
                                                    </div>
                                                    <div className="basic">
                                                      <Form.Check
                                                        type="checkbox"
                                                        checked={checkPermission(
                                                          "medium",
                                                          elem?._id,
                                                        )}
                                                        onChange={(e) =>
                                                          updatePermission({
                                                            e,
                                                            el: elem,
                                                            type: "child",
                                                            level: "medium",
                                                          })
                                                        }
                                                        disabled={
                                                          disableCheckBox
                                                        }
                                                      />
                                                    </div>
                                                    <div className="basic">
                                                      <Form.Check
                                                        type="checkbox"
                                                        checked={checkPermission(
                                                          "high",
                                                          elem?._id,
                                                        )}
                                                        onChange={(e) =>
                                                          updatePermission({
                                                            e,
                                                            el: elem,
                                                            type: "child",
                                                            level: "high",
                                                          })
                                                        }
                                                        disabled={
                                                          disableCheckBox
                                                        }
                                                      />
                                                    </div>
                                                    {/* <div className="basic">
                                              <Form.Check
                                                type="checkbox"
                                                checked={checkPermission('basic', elem?._id)}
                                                onChange={(e) => updatePermission({
                                                  e, el: elem, type: 'child', level: 'basic',
                                                })}
                                              />
                                            </div>
                                            <div className="basic">
                                              <Form.Check
                                                type="checkbox"
                                                checked={checkPermission('premium', elem?._id)}
                                                onChange={(e) => updatePermission({
                                                  e, el: elem, type: 'child', level: 'premium',
                                                })}
                                              />
                                            </div>
                                            <div className="basic">
                                              <Form.Check
                                                type="checkbox"
                                                checked={checkPermission('standard', elem?._id)}
                                                onChange={(e) => updatePermission({
                                                  e, el: elem, type: 'child', level: 'standard',
                                                })}
                                              />
                                            </div> */}
                                                  </div>
                                                </div>
                                              </h5>
                                            </div>
                                          </li>
                                        </ul>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  ),
                                )}
                            </Card>
                          </Accordion>
                        )}
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </AdminWrapper>
  );
};
export default NewPermissionLayout;
