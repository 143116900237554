/* eslint-disable curly */
/* eslint-disable nonblock-statement-body-position */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable no-restricted-syntax */
/* eslint-disable func-names */
/* eslint-disable no-extend-native */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable radix */
/* eslint-disable no-console */
import { NotificationManager } from "react-notifications";
import { array } from "prop-types";
import axios from "axios";
import moment from "./moment";
import { userInstance } from "../config/axios";
import { errorMsgTime, successMsgTime } from "./intervalTime";
import { emailRegex } from "../config/keys";
import toastMessage from "./toastMessage";
import {
  convertDateTime,
  displayTime,
  getTimeZone2,
  getTodayTimeZone,
  udpateTime,
  updateEndTime,
} from "./timeDate";

// eslint-disable-next-line arrow-body-style
// eslint-disable-next-line import/prefer-default-export
export const showErrMsg = (error) => {
  let message = "Request could not be fulfilled.";
  if (error.response) {
    const { msg } = error.response.data;
    message = msg;
  }
  toastMessage("error", message);
};

export const validateDataWithKey = (data) => {
  let isValid = true;
  let emptyField = "";
  Object.keys(data).forEach((el) => {
    if (isValid) {
      console.log("data=>>", data[el]);

      if (!data[el]?.toString()?.trim()) {
        isValid = false;
        emptyField = el;
      }
    }
  });
  // console.log('data', data);
  return { isValid, emptyField };
};

// export const validateDataWithKeyWithZeroValue = (data) => {
//   let isValid = true;
//   let emptyField = '';
//   Object.keys(data).forEach((el) => {
//     if (isValid) {
//       if (data[el] === 0) {
//         console.log('el', data[el]);
//         isValid = false;
//         emptyField = el;
//       }
//     }
//   });
//   return { isValid, emptyField };
// };

// const convetIntoMinute = (time) => {
//   const arr = time.split(':');
//   return (parseInt(arr[0]) * 60) + parseInt(arr[1]);
// };
// const timeSplit = (time) => {
//   let splitData = time.toString().split(':');
//   let hours = splitData[0];
//   let minutes = splitData[1];
//   return { hours, minutes };
// };
// const getEndingTime = (startTime, workingTime) => {
//   const time1 = convetIntoMinute(startTime);
//   const time2 = parseInt(workingTime);
//   const totalTime = (time1 + time2);
//   let hour = parseInt((totalTime / 60), 10);
//   if (hour >= 24) {
//     hour -= 24;
//   }
//   const minute = totalTime % 60;
//   return `${hour}:${minute}`;
// };

// const formatTime = (time, index) => {
//   const { appId, eventDate } = queryString();
//   let splitTime = time.split(':');

//   Date.prototype.addDays = function (days) {
//     var date = new Date(this.valueOf());
//     date.setDate(date.getDate() + days);
//     return date;
//   };

//   var date = new Date();

//   let m = moment(date.addDays(index));
//   m.set({
//     hour: splitTime[0], minute: splitTime[1], second: 0, millisecond: 0,
//   }).toDate();
//   m = appId ? eventDate : m.format();
//   return m;
// };
export const calculateEndingTimeArr = (
  setState,
  index,
  levelData,
  type,
  timeZone,
) => {
  const { eventDate, appId } = queryString();
  const currentTimeDate = appId
    ? convertDateTime(eventDate)
    : getTodayTimeZone(timeZone);
  let mainState = JSON.parse(JSON.stringify(levelData));
  let copyState = [...mainState[index].service];
  let addDays =
    mainState[index]?.days?.split(" ")[0] === "Today"
      ? 0
      : parseInt(mainState[index]?.days?.split(" ")[0], 10);
  copyState.map((el, i) => {
    el.startTime = i === 0 ? el.startTime : copyState[i - 1].endTime;
    const start = udpateTime(currentTimeDate, el.startTime, true, addDays);
    el.start = start?.format("YYYY-MM-DDTHH:mm:ssZ");
    const end = updateEndTime(start, el.duration, true);
    el.endTime = end.format("HH:mm");
    el.end = end?.format("YYYY-MM-DDTHH:mm:ssZ");
    return el;
  });
  mainState[index].service = copyState;

  if (!type) {
    setState(() => [...mainState]);
  }
  return [...copyState];

  // });
};

// export const calculateEndingTimeArrSandFilter = (setState, levelData) => {
//   setState(() => {
//     let copyState = [...levelData];
//     // console.log('copyState', copyState);
//     copyState.map((el, i) => {
//       if (i === 0) {
//         el.endTime = getEndingTime(el.startTime, el.duration);
//         el.start = formatFilterTime(el.startTime);
//         el.end = formatFilterTime(el.endTime);
//         return el;
//       }
//       el.startTime = copyState[i - 1].endTime;
//       el.endTime = getEndingTime(el.startTime, el.duration);
//       el.start = formatFilterTime(el.startTime);
//       el.end = formatFilterTime(el.endTime);
//       return el;
//     });
//     return [...copyState];
//   });
// };

// const formatFilterTime = (time, index) => {
//   let splitTime = time.split(':');

//   Date.prototype.addDays = function (days) {
//     var date = new Date(this.valueOf());
//     date.setDate(date.getDate() + days);
//     return date;
//   };

//   var date = new Date();

//   let m = moment(date.addDays(0));
//   m.set({
//     hour: splitTime[0], minute: splitTime[1], second: 0, millisecond: 0,
//   }).toDate();

//   return m.format();
// };

export const checkAuth = (accessLevel, path, permissions, role) => {
  const { view } = queryString();
  // console.log('permissions', permissions, accessLevel, view);
  let isAuthorized = false;
  const filteredPermissions = permissions.filter((el) => el.route === path);
  // console.log('filteredPermissions', filteredPermissions);
  if (filteredPermissions?.length > 0) {
    isAuthorized = true;
    if (view && path === "/poolsetup") {
      const findIndex = filteredPermissions.findIndex((el) => el.view === view);
      if (findIndex >= 0) {
        isAuthorized = true;
      } else {
        isAuthorized = false;
      }
    }
  }

  if (role === "professional-user" && path === "/subscription-management") {
    isAuthorized = false;
  }

  // if (filteredPermissions.length) {
  //   const filteredLevel = filteredPermissions[0].level.filter((el) => el === accessLevel);
  //   if (filteredLevel.length) {
  //     isAuthorized = true;
  //   }
  // }
  return isAuthorized;
};

export const queryString = () => {
  let query = window.location.search.substr(1);
  let resultval = {};
  query.split("&").forEach((part) => {
    let item = part.split("=");
    resultval[item[0]] = decodeURIComponent(item[1]);
  });
  return resultval;
};

export const dateRange = (startDate, endDate, steps = 1) => {
  const dateArray = [];
  let currentDates = new Date(startDate);
  // let cc = 1;
  while (currentDates <= new Date(endDate)) {
    dateArray.push(new Date(currentDates));
    // Use UTC date to prevent problems with time zones and DST
    currentDates.setUTCDate(currentDates.getUTCDate() + steps);
    // console.log('index==>', cc, currentDates);
    // cc++;
  }
  return dateArray;
};
const datediff = (first, second) =>
  Math.round((second - first) / (1000 * 60 * 60 * 24));
export const timeIntervalDay = (
  occurence,
  interval,
  type,
  endType,
  startDate,
  specificDate,
) => {
  if (type === null) {
    let obj = {
      interval: parseInt(1),
      intervalSetp: 1,
      eventEndDate: startDate,
    };
    return obj;
  }
  if (type === "weekly") {
    if (endType === "On a Specific Date") {
      let diffDays = datediff(new Date(startDate), new Date(specificDate));
      let eventEndDate = new Date(startDate);
      eventEndDate.setDate(
        new Date(startDate).getDate() + parseInt(diffDays) + 1,
      );
      let obj = {
        interval: parseInt(diffDays),
        intervalSetp: parseInt(7 * interval),
        eventEndDate,
      };
      return obj;
    }
    if (endType === "Ongoing") {
      let intervalAllDay = (occurence - 1) * interval;
      let eventEndDate = new Date(startDate);
      eventEndDate.setDate(new Date(startDate).getDate() + parseInt(365));
      let obj = {
        interval: parseInt(intervalAllDay),
        intervalSetp: parseInt(7 * interval),
        eventEndDate,
      };
      return obj;
    }
    let intervalAllDay = (occurence - 1) * interval * 7;
    let eventEndDate = new Date(startDate);
    eventEndDate.setDate(
      new Date(startDate).getDate() + parseInt(intervalAllDay) + 1,
    );
    let obj = {
      interval: parseInt(intervalAllDay),
      intervalSetp: parseInt(7 * interval),
      eventEndDate,
    };
    return obj;
  }
  if (type === "daily") {
    if (endType === "On a Specific Date") {
      let diffDays = datediff(new Date(startDate), new Date(specificDate));
      let eventEndDate = new Date(startDate);
      eventEndDate.setDate(
        new Date(startDate).getDate() + parseInt(diffDays) + 1,
      );
      let obj = {
        interval: parseInt(diffDays),
        intervalSetp: interval,
        eventEndDate,
      };
      return obj;
    }
    if (endType === "Ongoing") {
      let intervalAllDay = (occurence - 1) * interval;
      let eventEndDate = new Date(startDate);
      console.log("startDate", startDate);
      eventEndDate.setDate(new Date(startDate).getDate() + parseInt(365));
      let obj = {
        interval: parseInt(intervalAllDay),
        intervalSetp: interval,
        eventEndDate,
      };
      return obj;
    }
    let intervalAllDay = (occurence - 1) * interval;
    let eventEndDate = new Date(startDate);
    eventEndDate.setDate(
      new Date(startDate).getDate() + parseInt(intervalAllDay),
    );
    let obj = {
      interval: parseInt(intervalAllDay),
      intervalSetp: interval,
      eventEndDate,
    };
    return obj;
  }
  if (type === "monthly") {
    let newDate = new Date(startDate);
    newDate = new Date(
      moment(newDate, "DD-MM-YYYY")
        .add(parseInt(parseInt(occurence * interval), 10), "months")
        .format("L"),
    );
    if (endType === "On a Specific Date") {
      let diffDays =
        new Date(specificDate).getDate() - new Date(startDate).getDate();
      let obj = {
        interval: parseInt(diffDays),
        intervalSetp: parseInt(30 * interval),
        eventEndDate: newDate,
      };
      return obj;
    }
    if (endType === "Ongoing") {
      let intervalAllDay = (occurence - 1) * interval * 30;
      let eventEndDate = new Date(startDate);
      eventEndDate.setDate(new Date(startDate).getDate() + parseInt(365));
      let obj = {
        interval: parseInt(intervalAllDay),
        intervalSetp: parseInt(7 * interval),
        eventEndDate,
      };
      return obj;
    }
    let intervalAllDay = (occurence - 1) * interval * 30;
    let obj = {
      interval: parseInt(intervalAllDay),
      intervalSetp: parseInt(30 * interval),
      eventEndDate: newDate,
    };
    return obj;
  }
};

export const eventDateRange = (startDate, endDate, steps = 1, id, type) => {
  const dateArray = [];
  let currentDates = new Date(startDate);
  currentDates.setHours(0, 0, 0, 0);
  while (currentDates <= new Date(endDate)) {
    let eventDate = moment(currentDates, "YYYY-MM-DD").toLocaleString();
    eventDate = moment(eventDate).format("YYYY-MM-DD");
    let myEvetnt = {
      date: new Date(eventDate).toISOString(),
      status: "booked",
      appointmentid: id,
    };
    dateArray.push(myEvetnt);
    if (type === "monthly") {
      currentDates.setMonth(currentDates.getMonth() + steps);
    } else {
      currentDates.setDate(currentDates.getDate() + steps);
    }
  }
  return dateArray;
};

export const getCalendarPath = () => {
  let staff_id = localStorage.getItem("staff_id");
  let date = localStorage.getItem("date");
  let view = localStorage.getItem("view");
  let backPreDate = localStorage.getItem("prebackDate") || "";
  const calcDate = date
    ? moment.utc(date).format("YYYY-MM-DD")
    : moment(new Date()).format("YYYY-MM-DD");
  let calendarUrl = `/calendar?activedate=${calcDate}&view=${view || "timeGridWeek"
    }&staff_id=${staff_id || ""}&appointmentId=${backPreDate}`;
  return calendarUrl;
};

export const formatNumber = (num) => {
  return String(num).padStart(2, '0');
}


// export const getCalendarPath = () => {
//   let staff_id = localStorage.getItem('staff_id');
//   let date = localStorage.getItem('date');
//   let view = localStorage.getItem('view');
//   let backPreDate = localStorage.getItem('prebackDate') || '';
//   console.log('date>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', {
//     date, staff_id, view, backPreDate,
//   });

//   // Ensure date is in UTC format
//   let utcDate = moment.utc(date || new Date());
//   let calendarUrl = `/calendar?activedate=${utcDate.format('YYYY-MM-DD')}&view=${view || 'timeGridWeek'}&staff_id=${staff_id || ''}&appointmentId=${backPreDate}`;
//   return calendarUrl;
// };

export const blockTimeRange = (
  startDate,
  endDate,
  steps = 1,
  duration,
  type,
  startTime,
) => {
  const dateArray = [];
  let currentDates = new Date(startDate);
  while (currentDates <= new Date(endDate)) {
    let eventDate = moment(currentDates).format("YYYY-MM-DD");
    dateArray.push(new Date(eventDate).toISOString());
    if (type === "monthly") {
      currentDates.setMonth(currentDates.getMonth() + steps);
    } else {
      currentDates.setDate(currentDates.getDate() + steps);
    }
  }
  return dateArray;
};

export const updateServerDate = (services, currentDates) => {
  // console.log('servicesservices', currentDates, services);
  services.forEach((el) => {
    let start = moment(currentDates).toDate();
    const time = el.startTime.split(":");
    start.setHours(time[0]);
    start.setMinutes(time[1]);
    start.setSeconds(0);
    el.start = moment(start).format("YYYY-MM-DDTHH:mm:ssZ");
    el.end = moment(
      new Date(start.setMinutes(start.getMinutes() + parseFloat(el.duration))),
    ).format("YYYY-MM-DDTHH:mm:ssZ");
  });
  return services;
};
export const compareDataObject = (
  payloadnew,
  oldObjectData,
  confirmType,
  currentDates,
) => {
  // console.log('newdate', payloadnew);
  // console.log('oldObjectData', oldObjectData);
  let frequencyObj = "";
  let returntype = true;
  if (
    JSON.stringify(payloadnew.dateData) ===
    JSON.stringify(oldObjectData.dateData)
  ) {
    console.log("0ooooooooooooooo==>", currentDates);
    let payloadnewObj = {
      appointmenttype: payloadnew.appointmenttype,
      client: payloadnew.client,
      dateData: payloadnew.dateData,
      totalData: payloadnew.totalData,
      requiredday: payloadnew.requiredday || "",
    };
    let payloadOldObj = {
      appointmenttype: oldObjectData.appointmenttype,
      client: oldObjectData.client,
      dateData: oldObjectData.dateData,
      totalData: oldObjectData.totalData,
      requiredday: oldObjectData.requiredday,
    };
    if (JSON.stringify(payloadOldObj) === JSON.stringify(payloadnewObj)) {
      returntype = true;
    } else {
      returntype = false;
    }
  } else {
    let payloadnewObj = {
      appointmenttype: payloadnew.appointmenttype,
      client: payloadnew.client,
      dateData: payloadnew.dateData,
      totalData: payloadnew.totalData,
      requiredday: payloadnew.requiredday || "",
      // repeats: payloadnew.repeats,
    };
    let payloadOldObj = {
      appointmenttype: oldObjectData.appointmenttype,
      client: oldObjectData.client,
      dateData: oldObjectData.dateData,
      totalData: oldObjectData.totalData,
      requiredday: oldObjectData.requiredday,
      // repeats: oldObjectData.repeats,
    };
    if (JSON.stringify(payloadOldObj) === JSON.stringify(payloadnewObj)) {
      returntype = true;
    } else {
      returntype = false;
    }
  }
  let oldRepeats = {
    endType: oldObjectData.dateData[0].endType,
    frequencyMsg: oldObjectData.dateData[0].frequencyMsg,
    interval_multiplier: oldObjectData.dateData[0].interval_multiplier,
    interval_type: oldObjectData.dateData[0].interval_type,
    // msg: oldObjectData.dateData[0].msg,
    occurence: oldObjectData.dateData[0].occurence,
    occurenceMsg: oldObjectData.dateData[0].occurenceMsg,
  };
  let newRepeats = {
    endType: payloadnew.dateData[0].endType,
    frequencyMsg: payloadnew.dateData[0].frequencyMsg,
    interval_multiplier: payloadnew.dateData[0].interval_multiplier,
    interval_type: payloadnew.dateData[0].interval_type,
    // msg: payloadnew.dateData[0].msg,
    occurence: payloadnew.dateData[0].occurence,
    occurenceMsg: payloadnew.dateData[0].occurenceMsg,
  };
  if (JSON.stringify(oldRepeats) === JSON.stringify(newRepeats)) {
    frequencyObj = {
      endType: null,
      frequencyMsg: "",
      interval_multiplier: null,
      interval_type: null,
      msg: "One-off appointment | Doesn't repeat",
      occurence: null,
      occurenceMsg: "",
    };
  } else {
    frequencyObj = {
      endType: payloadnew.dateData[0].endType,
      frequencyMsg: payloadnew.dateData[0].frequencyMsg,
      interval_multiplier: payloadnew.dateData[0].interval_multiplier,
      interval_type: payloadnew.dateData[0].interval_type,
      msg: payloadnew.dateData[0].msg,
      occurence: payloadnew.dateData[0].occurence,
      occurenceMsg: payloadnew.dateData[0].occurenceMsg,
    };
  }
  // console.log('returntype', returntype, servicArray, frequencyObj);
  return {
    returntype,
    frequencyObj,
  };
};

// export const updateServerDateTime = (services, currentDates, updateTime, timeZone) => {
//   console.log('uptime--------->', {
//     services, currentDates, updateTime, timeZone,
//   });
//   const date = getTimeZone2(timeZone, currentDates);
//   console.log('-------->', { date });

//   let upTime = updateTime;
//   const newStafId = localStorage.getItem('staff_id');
//   const newStaffName = localStorage.getItem('staff_name');

//   services.forEach((el, index) => {
//     if (index === 0) {
//       upTime = updateTime;
//     } else {
//       upTime = displayTime(timeZone, services[index - 1].end, 'HH:mm'); // moment(new Date(services[index - 1].end)).format('HH:mm');
//     }
//     let time = upTime.split(':');
//     let start = moment(currentDates).toDate();
//     start.setHours(time[0]);
//     start.setMinutes(time[1]);
//     start.setSeconds(0);
//     el.staffId = newStafId;
//     el.staffName = newStaffName;
//     el.start = getTimeZone2(timeZone, start); // moment(start).format('YYYY-MM-DDTHH:mm:ssZ');
//     el.end = updateEndTime(timeZone, start, el.duration); // moment(new Date(start.setMinutes(start.getMinutes() + parseFloat(el.duration)))).format('YYYY-MM-DDTHH:mm:ssZ');
//     el.startTime = upTime;
//   });
//   return services;
// };

export const updateServerDateTime = (
  services,
  currentDates,
  updateTime,
  timeZone,
) => {
  const newStafId = localStorage.getItem("staff_id");
  const newStaffName = localStorage.getItem("staff_name");
  services.forEach((el, index) => {
    const date =
      index === 0
        ? getTimeZone2(timeZone, currentDates)
        : services[index - 1].end;
    const timeArr = date.split("T")[1].split(":");
    const time = `${timeArr[0]}:${timeArr[1]}`;
    const dateObj = convertDateTime(date);
    el.staffId = newStafId;
    el.staffName = newStaffName;
    el.start = date;
    el.end = updateEndTime(dateObj, el.duration);
    el.startTime = time;
  });
  return services;
};

export const updateServiceDateTime = (chemicalServices, lastitem, type) => {
  const end = type === "addapp" ? lastitem : lastitem?.end || {};
  let upTime = "";
  chemicalServices.forEach((el, index) => {
    if (index === 0) {
      upTime = moment(new Date(end)).format("HH:mm");
    } else {
      upTime = moment(new Date(chemicalServices[index - 1].end)).format(
        "HH:mm",
      );
    }
    let time = upTime.split(":");
    let start = moment(end).toDate();
    start.setHours(time[0]);
    start.setMinutes(time[1]);
    start.setSeconds(0);
    el.start = moment(start).format("YYYY-MM-DDTHH:mm:ssZ");
    let serviceEndTime = moment(
      new Date(start.setMinutes(start.getMinutes() + parseFloat(el.duration))),
    ).format("YYYY-MM-DDTHH:mm:ssZ");
    el.end = serviceEndTime;
    el.endTime = moment(new Date(serviceEndTime)).format("HH:mm");
    el.startTime = upTime;
  });
  return chemicalServices;
};

export const addZero = (value, type) => {
  if (value && value === "N/A") {
    if (!type) {
      value = "";
    }
    return value;
  }

  if (!value) {
    return "";
  }

  return value.split(".")[1]
    ? value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : parseFloat(value)
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const upcommingDateRange = (
  arr,
  step,
  type,
  date,
  id,
  activeIndex,
  rescheduleType,
  interval_multiplier,
) => {
  const repeatLasIndex = arr.slice(-1);
  const dateArray = [];
  let lastDate = "";
  arr.forEach((el, index) => {
    let starDate = new Date(date);
    starDate.setHours(0, 0, 0, 0);
    if (type === "daily") {
      let setStep = parseInt(step, 10) * index;
      const update = starDate.setDate(starDate.getDate() + setStep);
      if (rescheduleType === "current") {
        lastDate = repeatLasIndex ? new Date(repeatLasIndex[0].date) : "";
      } else {
        lastDate = update;
      }
      let myEvetnt = {
        date: moment(update).format("YYYY-MM-DDTHH:mm:ssZ"),
        status: "booked",
        appointmentid: id,
      };
      dateArray.push(myEvetnt);
    } else if (type === "weekly") {
      let setStep = parseInt(step, 10) * index * 7;
      const update = starDate.setDate(starDate.getDate() + setStep);
      console.log(step, setStep, new Date(update));
      if (rescheduleType === "current") {
        lastDate = repeatLasIndex ? new Date(repeatLasIndex[0].date) : "";
      } else {
        lastDate = update;
      }
      let myEvetnt = {
        date: moment(update).format("YYYY-MM-DDTHH:mm:ssZ"),
        status: "booked",
        appointmentid: id,
      };
      dateArray.push(myEvetnt);
    } else if (type === "monthly") {
      let setStep = parseInt(step, 10) * index;
      const update = starDate.setMonth(starDate.getMonth() + setStep);
      if (rescheduleType === "current") {
        lastDate = repeatLasIndex ? new Date(repeatLasIndex[0].date) : "";
      } else {
        lastDate = update;
      }
      let myEvetnt = {
        date: moment(update).format("YYYY-MM-DDTHH:mm:ssZ"),
        status: "booked",
        appointmentid: id,
      };
      dateArray.push(myEvetnt);
    }
  });
  return { dateArray, lastDate };
};

export const RescheduleUpdateMsg = (
  newDateArray,
  msgIntervalType,
  lastDate,
  oldLastdate,
  oldRepeated,
  activeIndex,
  rescheduleType,
  repeats,
) => {
  let newmsg = "";
  let oldmsg = "";
  const allRepeateArr = [...repeats];
  const remainRepeated = allRepeateArr.splice(activeIndex, 1);
  const oldRepeatedsize = repeats.length - remainRepeated.length;
  let oldoccurenceMsg;
  let oldendType;
  let oldinterval_multitplier;
  let oldinterval_type;
  let oldfrequencyMsg;
  let oldoccurence;
  let StartDates = "";
  if (activeIndex === "0" && oldRepeatedsize > 0) {
    oldmsg =
      newDateArray[0].endType === "ongoing"
        ? `Repeats every ${newDateArray[0].interval_multiplier} ${msgIntervalType} | Ongoing`
        : newDateArray[0].occurenceMsg !== "On a Specific Date"
          ? `Repeats every ${newDateArray[0].interval_multiplier
          } ${msgIntervalType} | Ends after ${oldRepeatedsize} times on ${moment(
            lastDate,
          ).format("dddd")}, ${moment(lastDate).format("D MMMM YYYY")}`
          : `Repeats every ${newDateArray[0].interval_multiplier
          } ${msgIntervalType} | Ends on ${moment(lastDate).format(
            "dddd",
          )}, ${moment(lastDate).format("D MMMM YYYY")}`;
    oldoccurenceMsg = `After ${oldRepeatedsize} times`;
    oldoccurence = oldRepeatedsize;
    StartDates = repeats[1].date;
  } else if (activeIndex === "0" && oldRepeatedsize === 1) {
    oldmsg = "One-off appointment | Doesn't repeat";
    oldoccurenceMsg = null;
    oldendType = null;
    oldinterval_multitplier = null;
    oldinterval_type = null;
    oldfrequencyMsg = "";
    oldoccurence = null;
  } else {
    const upcomingArr = repeats.slice(activeIndex);
    const oldRepeatedLength = repeats.length - upcomingArr.length;
    if (oldRepeatedLength > 1) {
      oldmsg =
        newDateArray[0].endType === "ongoing"
          ? `Repeats every ${newDateArray[0].interval_multiplier} ${msgIntervalType} | Ongoing`
          : newDateArray[0].occurenceMsg !== "On a Specific Date"
            ? `Repeats every ${newDateArray[0].interval_multiplier
            } ${msgIntervalType} | Ends after ${oldRepeatedLength} times on ${moment(
              oldLastdate,
            ).format("dddd")}, ${moment(oldLastdate).format("D MMMM YYYY")}`
            : `Repeats every ${newDateArray[0].interval_multiplier
            } ${msgIntervalType} | Ends on ${moment(oldLastdate).format(
              "dddd",
            )}, ${moment(oldLastdate).format("D MMMM YYYY")}`;
      oldoccurenceMsg = `After ${oldRepeatedLength} times`;
      oldoccurence = oldRepeatedLength;
    }
    // oldoccurence = oldRepeatedsize;
  }
  newmsg =
    newDateArray[0].endType === "ongoing"
      ? `Repeats every ${newDateArray[0].interval_multiplier} ${msgIntervalType} | Ongoing`
      : newDateArray[0].occurenceMsg !== "On a Specific Date"
        ? `Repeats every ${newDateArray[0].interval_multiplier
        } ${msgIntervalType} | Ends after ${newDateArray[0].occurence
        } times on ${moment(lastDate).format("dddd")}, ${moment(
          lastDate,
        ).format("D MMMM YYYY")}`
        : `Repeats every ${newDateArray[0].interval_multiplier
        } ${msgIntervalType} | Ends on ${moment(lastDate).format(
          "dddd",
        )}, ${moment(lastDate).format("D MMMM YYYY")}`;
  // console.log('check====>', newmsg, oldmsg, oldoccurenceMsg, oldendType, oldinterval_multitplier, oldinterval_type, oldfrequencyMsg, oldoccurence, StartDates);
  return {
    newmsg,
    oldmsg,
    oldoccurenceMsg,
    oldendType,
    oldinterval_multitplier,
    oldinterval_type,
    oldfrequencyMsg,
    oldoccurence,
    StartDates,
  };
};

export const updateOldServiceDateTime = async (id, dates) => {
  const response = await userInstance().get(
    `/appointment/getAppointmentById?id=${id}`,
  );
  const { appointmentdetail } = response.data;
  const { dateData } = appointmentdetail || {};
  if (dateData.length > 0) {
    const oldNewServicesData = updateServiceDateTime(
      dateData[0].service,
      dates,
    );
    return oldNewServicesData;
  }
};
export const matchDataObject = (newclients, newDataObj, appointmenttypes) => {
  const { client, dateData, appointmenttype } =
    JSON.parse(localStorage.getItem("currentapp")) || {};
  let matchtype = true;
  let matchFrequency = true;
  if (newDataObj.length === 1) {
    const oldfrequencyMsg = {
      endType: dateData[0].endType,
      frequencyMsg: dateData[0].frequencyMsg,
      interval_multiplier: dateData[0].interval_multiplier,
      interval_type: dateData[0].interval_type,
      occurence: dateData[0].occurence,
      appointmenttype,
    };
    const newfrequencyMsg = {
      endType: newDataObj[0].endType,
      frequencyMsg: newDataObj[0].frequencyMsg,
      interval_multiplier: newDataObj[0].interval_multiplier,
      interval_type: newDataObj[0].interval_type,
      occurence: newDataObj[0].occurence,
      appointmenttype: appointmenttypes,
    };
    if (JSON.stringify(newfrequencyMsg) !== JSON.stringify(oldfrequencyMsg)) {
      matchFrequency = false;
    }
  }
  if (
    JSON.stringify(newDataObj) === JSON.stringify(dateData) &&
    JSON.stringify(client) === JSON.stringify(newclients)
  ) {
    matchtype = false;
  }
  return { matchtype, matchFrequency };
};

export const filterAppointmentObj = (dataObj) => {
  const { datetype, rangeStartDate, rangeEndDate } = dataObj || {};
  const dates = moment(new Date()).format("YYYY-MM-DD");
  const curr = new Date();
  if (datetype === "yesterday") {
    const lt = new Date(dates);
    const gt = new Date(dates).setDate(new Date(dates).getDate() - 1);
    const obj = {
      startDate: new Date(gt).toISOString(),
      endDate: new Date(gt).toISOString(),
      summaryDate: `${moment(gt).format("ddd, D MMM yyyy")} - ${moment(
        gt,
      ).format("ddd, D MMM yyyy")}`,
    };
    return obj;
  }
  if (datetype === "today") {
    const lt = new Date(dates);
    const gt = new Date(dates).setDate(new Date(dates).getDate() + 1);
    const obj = {
      startDate: new Date(lt).toISOString(),
      endDate: new Date(lt).toISOString(),
      summaryDate: `${moment(lt).format("ddd, D MMM yyyy")} - ${moment(
        lt,
      ).format("ddd, D MMM yyyy")}`,
    };
    return obj;
  }
  if (datetype === "tomorrow") {
    const lt = new Date(dates).setDate(new Date(dates).getDate() + 1);
    const gt = new Date(dates).setDate(new Date(dates).getDate() + 2);
    const obj = {
      startDate: new Date(lt).toISOString(),
      endDate: new Date(lt).toISOString(),
      summaryDate: `${moment(lt).format("ddd, D MMM yyyy")} - ${moment(
        lt,
      ).format("ddd, D MMM yyyy")}`,
    };
    return obj;
  }
  if (datetype === "week") {
    // const first = curr.getDate() - curr.getDay();
    // const last = first + 8;
    // const firstday = moment(new Date(curr.setDate(first + 1))).format('YYYY-MM-DD');
    // const lastday = moment(curr.setDate(last)).format('YYYY-MM-DD');
    const filterStart = new Date(
      moment().startOf("isoWeek").format("YYYY-MM-DD"),
    );
    const filterEnd = new Date(moment().endOf("isoWeek").format("YYYY-MM-DD"));
    const obj = {
      startDate: new Date(
        moment().endOf("isoWeek").format("YYYY-MM-DD"),
      ).toISOString(),
      endDate: new Date(
        moment().startOf("isoWeek").format("YYYY-MM-DD"),
      ).toISOString(),
      summaryDate: `${moment(filterStart).format("ddd, D MMM yyyy")} - ${moment(
        filterEnd,
      ).format("ddd, D MMM yyyy")}`,
    };
    return obj;
  }
  if (datetype === "month") {
    const firstDay = moment(
      new Date(curr.getFullYear(), curr.getMonth(), 1),
    ).format("YYYY-MM-DD");
    const lastDay = moment(
      new Date(curr.getFullYear(), curr.getMonth() + 1, 0),
    ).format("YYYY-MM-DD");
    const obj = {
      startDate: new Date(lastDay).toISOString(),
      endDate: new Date(firstDay).toISOString(),
      summaryDate: `${moment(firstDay).format("ddd, D MMM yyyy")} - ${moment(
        lastDay,
      ).format("ddd, D MMM yyyy")}`,
    };
    return obj;
  }
  if (datetype === "year") {
    const obj = {
      startDate: new Date(
        moment().endOf("year").format("YYYY-MM-DD"),
      ).toISOString(),
      endDate: new Date(
        moment().startOf("year").format("YYYY-MM-DD"),
      ).toISOString(),
      summaryDate: `${moment()
        .startOf("year")
        .format("ddd, D MMM yyyy")} - ${moment()
          .endOf("year")
          .format("ddd, D MMM yyyy")}`,
    };
    return obj;
  }
  if (datetype === "lastweek") {
    const obj = {
      startDate: new Date(
        moment().subtract(1, "weeks").endOf("isoWeek").format("YYYY-MM-DD"),
      ).toISOString(),
      endDate: new Date(
        moment().subtract(1, "weeks").startOf("isoWeek").format("YYYY-MM-DD"),
      ).toISOString(),
      summaryDate: `${moment()
        .subtract(1, "weeks")
        .startOf("isoWeek")
        .format("ddd, D MMM yyyy")} - ${moment()
          .subtract(1, "weeks")
          .endOf("isoWeek")
          .format("ddd, D MMM yyyy")}`,
    };
    return obj;
  }
  if (datetype === "lastyear") {
    const obj = {
      startDate: new Date(
        moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD"),
      ).toISOString(),
      endDate: new Date(
        moment().subtract(1, "year").endOf("year").format("YYYY-MM-DD"),
      ).toISOString(),
      summaryDate: `${moment()
        .subtract(1, "year")
        .startOf("year")
        .format("ddd, D MMM yyyy")} -
      ${moment().subtract(1, "year").endOf("year").format("ddd, D MMM yyyy")}`,
    };
    return obj;
  }
  if (datetype === "nextweek") {
    const obj = {
      startDate: new Date(
        moment().add(1, "weeks").endOf("isoWeek").format("YYYY-MM-DD"),
      ).toISOString(),
      endDate: new Date(
        moment().add(1, "weeks").startOf("isoWeek").format("YYYY-MM-DD"),
      ).toISOString(),
      summaryDate: `${moment()
        .add(1, "weeks")
        .startOf("isoWeek")
        .format("ddd, D MMM yyyy")} - ${moment()
          .add(1, "weeks")
          .endOf("isoWeek")
          .format("ddd, D MMM yyyy")}`,
    };
    return obj;
  }
  if (datetype === "nextyear") {
    const obj = {
      startDate: new Date(
        moment().add(1, "year").endOf("year").format("YYYY-MM-DD"),
      ).toISOString(),
      endDate: new Date(
        moment().add(1, "year").startOf("year").format("YYYY-MM-DD"),
      ).toISOString(),
      summaryDate: `${moment()
        .add(1, "year")
        .startOf("year")
        .format("ddd, D MMM yyyy")} - ${moment()
          .add(1, "year")
          .endOf("year")
          .format("ddd, D MMM yyyy")}`,
    };
    return obj;
  }
  if (datetype === "weekdate") {
    const obj = {
      startDate: new Date(
        moment(new Date()).format("YYYY-MM-DD"),
      ).toISOString(),
      endDate: new Date(
        moment().startOf("week").format("YYYY-MM-DD"),
      ).toISOString(),
      summaryDate: `${moment()
        .startOf("week")
        .format("ddd, D MMM yyyy")} - ${moment(new Date()).format(
          "ddd, D MMM yyyy",
        )}`,
    };
    return obj;
  }
  if (datetype === "monthdate") {
    const obj = {
      startDate: new Date(
        moment(new Date()).format("YYYY-MM-DD"),
      ).toISOString(),
      endDate: new Date(
        moment().clone().startOf("month").format("YYYY-MM-DD"),
      ).toISOString(),
      summaryDate: `${moment()
        .clone()
        .startOf("month")
        .format("ddd, D MMM yyyy")} - ${moment(new Date()).format(
          "ddd, D MMM yyyy",
        )}`,
    };
    return obj;
  }
  if (datetype === "yeardate") {
    const obj = {
      startDate: new Date(
        moment(new Date()).format("YYYY-MM-DD"),
      ).toISOString(),
      endDate: new Date(
        moment().startOf("year").format("YYYY-MM-DD"),
      ).toISOString(),
      summaryDate: `${moment()
        .startOf("year")
        .format("ddd, D MMM yyyy")} - ${moment(new Date()).format(
          "ddd, D MMM yyyy",
        )}`,
    };
    return obj;
  }
  if (datetype === "daterange") {
    const obj = {
      startDate: new Date(
        moment(new Date(rangeEndDate)).format("YYYY-MM-DD"),
      ).toISOString(),
      endDate: new Date(
        moment(new Date(rangeStartDate)).format("YYYY-MM-DD"),
      ).toISOString(),
      summaryDate: `${moment(rangeStartDate).format(
        "ddd, D MMM yyyy",
      )} - ${moment(rangeEndDate).format("ddd, D MMM yyyy")}`,
    };
    return obj;
  }
  return {};
};

export const filterProductChemicals = ({
  datetype,
  rangeStartDate,
  rangeEndDate,
}) => {
  console.log("this is the Step 1");
  const dates = moment(new Date()).format("YYYY-MM-DD");
  const curr = new Date();
  if (datetype === "yesterday") {
    const lt = new Date(dates);
    const gt = new Date(dates).setDate(new Date(dates).getDate() - 1);
    const obj = {
      startDate: new Date(gt).toISOString(),
      endDate: new Date(gt).toISOString(),
      summaryDate: `${moment(gt).format("ddd, D MMM yyyy")} - ${moment(
        gt,
      ).format("ddd, D MMM yyyy")}`,
    };
    return obj;
  }
  if (datetype === "today") {
    const lt = new Date(dates);
    const gt = new Date(dates).setDate(new Date(dates).getDate() + 1);
    const obj = {
      startDate: new Date(lt).toISOString(),
      endDate: new Date(lt).toISOString(),
      summaryDate: `${moment(lt).format("ddd, D MMM yyyy")} - ${moment(
        lt,
      ).format("ddd, D MMM yyyy")}`,
    };
    return obj;
  }
  if (datetype === "tomorrow") {
    const lt = new Date(dates).setDate(new Date(dates).getDate() + 1);
    const gt = new Date(dates).setDate(new Date(dates).getDate() + 2);
    const obj = {
      startDate: new Date(lt).toISOString(),
      endDate: new Date(lt).toISOString(),
      summaryDate: `${moment(lt).format("ddd, D MMM yyyy")} - ${moment(
        lt,
      ).format("ddd, D MMM yyyy")}`,
    };
    return obj;
  }
  if (datetype === "week") {
    const filterStart = new Date(
      moment().startOf("isoWeek").format("YYYY-MM-DD"),
    );
    const filterEnd = new Date(moment().endOf("isoWeek").format("YYYY-MM-DD"));
    const obj = {
      startDate: new Date(
        moment().endOf("isoWeek").format("YYYY-MM-DD"),
      ).toISOString(),
      endDate: new Date(
        moment().startOf("isoWeek").format("YYYY-MM-DD"),
      ).toISOString(),
      summaryDate: `${moment(filterStart).format("ddd, D MMM yyyy")} - ${moment(
        filterEnd,
      ).format("ddd, D MMM yyyy")}`,
    };
    return obj;
  }
  if (datetype === "month") {
    const firstDay = moment(
      new Date(curr.getFullYear(), curr.getMonth(), 1),
    ).format("YYYY-MM-DD");
    const lastDay = moment(
      new Date(curr.getFullYear(), curr.getMonth() + 1, 0),
    ).format("YYYY-MM-DD");
    const obj = {
      startDate: new Date(lastDay).toISOString(),
      endDate: new Date(firstDay).toISOString(),
      summaryDate: `${moment(firstDay).format("ddd, D MMM yyyy")} - ${moment(
        lastDay,
      ).format("ddd, D MMM yyyy")}`,
    };
    return obj;
  }
  if (datetype === "year") {
    const obj = {
      startDate: new Date(
        moment().endOf("year").format("YYYY-MM-DD"),
      ).toISOString(),
      endDate: new Date(
        moment().startOf("year").format("YYYY-MM-DD"),
      ).toISOString(),
      summaryDate: `${moment()
        .startOf("year")
        .format("ddd, D MMM yyyy")} - ${moment()
          .endOf("year")
          .format("ddd, D MMM yyyy")}`,
    };
    return obj;
  }
  if (datetype === "lastweek") {
    const obj = {
      startDate: new Date(
        moment().subtract(1, "weeks").endOf("isoWeek").format("YYYY-MM-DD"),
      ).toISOString(),
      endDate: new Date(
        moment().subtract(1, "weeks").startOf("isoWeek").format("YYYY-MM-DD"),
      ).toISOString(),
      summaryDate: `${moment()
        .subtract(1, "weeks")
        .startOf("isoWeek")
        .format("ddd, D MMM yyyy")} - ${moment()
          .subtract(1, "weeks")
          .endOf("isoWeek")
          .format("ddd, D MMM yyyy")}`,
    };
    return obj;
  }
  if (datetype === "lastyear") {
    const obj = {
      startDate: new Date(
        moment().subtract(1, "year").endOf("year").format("YYYY-MM-DD"),
      ).toISOString(),
      endDate: new Date(
        moment().subtract(1, "year").startOf("year").format("YYYY-DD-MM"),
      ).toISOString(),
      summaryDate: `${moment()
        .subtract(1, "year")
        .startOf("year")
        .format("ddd, D MMM yyyy")} - ${moment()
          .subtract(1, "year")
          .endOf("month")
          .format("ddd, D MMM yyyy")}`,
    };
    return obj;
  }
  if (datetype === "nextweek") {
    const obj = {
      startDate: new Date(
        moment().add(1, "weeks").endOf("isoWeek").format("YYYY-MM-DD"),
      ).toISOString(),
      endDate: new Date(
        moment().add(1, "weeks").startOf("isoWeek").format("YYYY-MM-DD"),
      ).toISOString(),
      summaryDate: `${moment()
        .add(1, "weeks")
        .startOf("isoWeek")
        .format("ddd, D MMM yyyy")} - ${moment()
          .add(1, "weeks")
          .endOf("isoWeek")
          .format("ddd, D MMM yyyy")}`,
    };
    return obj;
  }
  if (datetype === "nextyear") {
    const obj = {
      startDate: new Date(
        moment().add(1, "year").endOf("year").format("YYYY-MM-DD"),
      ).toISOString(),
      endDate: new Date(
        moment().add(1, "year").startOf("year").format("YYYY-MM-DD"),
      ).toISOString(),
      summaryDate: `${moment()
        .add(1, "year")
        .startOf("year")
        .format("ddd, D MMM yyyy")} - ${moment()
          .add(1, "year")
          .endOf("year")
          .format("ddd, D MMM yyyy")}`,
    };
    return obj;
  }
  if (datetype === "weekdate") {
    const obj = {
      startDate: new Date(
        moment(new Date()).format("YYYY-MM-DD"),
      ).toISOString(),
      endDate: new Date(
        moment().startOf("week").format("YYYY-MM-DD"),
      ).toISOString(),
      summaryDate: `${moment()
        .startOf("week")
        .format("ddd, D MMM yyyy")} - ${moment(new Date()).format(
          "ddd, D MMM yyyy",
        )}`,
    };
    return obj;
  }
  if (datetype === "monthdate") {
    const obj = {
      startDate: new Date(
        moment(new Date()).format("YYYY-MM-DD"),
      ).toISOString(),
      endDate: new Date(
        moment().clone().startOf("month").format("YYYY-MM-DD"),
      ).toISOString(),
      summaryDate: `${moment()
        .clone()
        .startOf("month")
        .format("ddd, D MMM yyyy")} - ${moment(new Date()).format(
          "ddd, D MMM yyyy",
        )}`,
    };
    return obj;
  }
  if (datetype === "yeardate") {
    const obj = {
      startDate: new Date(
        moment(new Date()).format("YYYY-MM-DD"),
      ).toISOString(),
      endDate: new Date(
        moment().startOf("year").format("YYYY-MM-DD"),
      ).toISOString(),
      summaryDate: `${moment()
        .startOf("year")
        .format("ddd, D MMM yyyy")} - ${moment(new Date()).format(
          "ddd, D MMM yyyy",
        )}`,
    };
    return obj;
  }
  if (datetype === "daterange") {
    const obj = {
      startDate: new Date(
        moment(new Date(rangeEndDate)).format("YYYY-MM-DD"),
      ).toISOString(),
      endDate: new Date(
        moment(new Date(rangeStartDate)).format("YYYY-MM-DD"),
      ).toISOString(),
      summaryDate: `${moment(rangeStartDate).format(
        "ddd, D MMM yyyy",
      )} - ${moment(rangeEndDate).format("ddd, D MMM yyyy")}`,
    };
    return obj;
  }
  return {};
};

export const handleTime = (greenSplitName, data, type) => {
  // console.log('data', data);
  let gType = "";
  if (type) {
    gType = type;
  }

  let convertedInMins = "";
  if (!data) {
    return convertedInMins;
  }
  let time = data.toString();

  if (time.includes(".")) {
    let splitData = data.split(".");
    convertedInMins =
      gType === "drain"
        ? parseFloat(splitData[0]) * 60 + parseFloat(splitData[1] * 6) + 60
        : parseFloat(splitData[0]) * 60 + parseFloat(splitData[1] * 6);
  } else {
    convertedInMins = parseFloat(data) * 60;
  }

  return Math.ceil(convertedInMins / 5) * 5;
};
function tConvert(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? " am" : " pm"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}

export const dateFormats = (date, appointmentTime, serviceTime) => {
  let formateDate = "";
  if (
    moment(new Date()).format("YYYY-MM-DD") ===
    moment(new Date(date)).format("YYYY-MM-DD")
  ) {
    // console.log('moment', moment());
    // console.log('moment', new Date('2023-01-22T02:30:00+05:30'));
    // console.log('ghante nikal', moment(serviceTime).startOf('day').fromNow());
    formateDate = `Maintenance is scheduled for Today at ${tConvert(
      appointmentTime,
    )}`;
  } else if (
    moment().add(1, "days").format("YYYY-MM-DD") ===
    moment(new Date(date)).format("YYYY-MM-DD")
  ) {
    formateDate = `Maintenance is scheduled for Tomorrow ${tConvert(
      appointmentTime,
    )}`;
  } else if (
    moment().subtract(1, "days").format("YYYY-MM-DD") ===
    moment(new Date(date)).format("YYYY-MM-DD")
  ) {
    formateDate = `Maintenance was scheduled for Yesterday at ${tConvert(
      appointmentTime,
    )}`;
  } else if (
    moment().subtract(2, "days").format("YYYY-MM-DD") ===
    moment(new Date(date)).format("YYYY-MM-DD")
  ) {
    formateDate = ` Maintenance was scheduled Two days ago at ${tConvert(
      appointmentTime,
    )}`;
  } else if (
    moment().add(3, "days").format("YYYY-MM-DD") ===
    moment(new Date(date)).format("YYYY-MM-DD")
  ) {
    formateDate = ` Maintenance scheduled for Three days at ${tConvert(
      appointmentTime,
    )}`;
  } else {
    formateDate = `Maintenance was scheduled at ${moment(new Date(date)).format(
      "ddd, D MMM",
    )} at ${tConvert(appointmentTime)}`;
  }

  return formateDate;
};

export const currencyComma = (value) => {
  let res = value;
  if (res) {
    res = value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return res;
};

export const makingAddress = (ans) => {
  let str = "";
  let index = 0;
  // eslint-disable-next-line guard-for-in
  for (const prop in ans) {
    if (ans[prop]) {
      ans[prop] = ans[prop].trim();
      if (ans[prop][ans[prop].length - 1] === ",") {
        ans[prop] = ans[prop].slice(0, -1);
      }
      if (index === 0) {
        str += ans[prop];
      } else {
        str += `, ${ans[prop]}`;
      }
      index++;
    }
  }
  return str;
};

export const convertQuantUnit = (chemical, unit) => {
  // console.log(chemical, unit);
  let chemValue = parseFloat(chemical).toFixed(1);
  let chemUnit = unit;
  let actualValue = chemValue;
  if (parseFloat(chemical) < 1) {
    chemValue = Math.ceil((parseFloat(chemical) * 1000) / 100) * 100;
    actualValue = chemValue / 1000;
    chemUnit =
      unit === "fl oz"
        ? "fl oz"
        : unit === "lb"
          ? "oz"
          : unit === "litres"
            ? "ml"
            : "gm";
    if (chemValue === 1000) {
      chemValue = (chemValue / 1000).toFixed(1);
      actualValue = chemValue;
      chemUnit = unit === "litres" ? "litre" : "kg";
    }
  }

  console.log("hhddd", { chemValue, chemUnit, actualValue });

  return { chemValue, chemUnit, actualValue };
};

export const convertQuantUnitCalci = (chemical, unit, valueType) => {
  let chemValue = parseFloat(chemical).toFixed(1);
  let chemUnit = unit;
  let actualValue = chemValue;
  if (parseFloat(chemical) < 1) {
    chemValue = Math.ceil((parseFloat(chemical) * 1000) / 100) * 100;
    actualValue = chemValue / 1000;
    chemUnit = unit === "litres" ? "ml" : "gm";
    if (chemValue === 1000) {
      chemValue = (chemValue / 1000).toFixed(1);
      actualValue = chemValue;
      chemUnit = unit === "litres" ? "litre" : "kg";
    }
    if (!valueType && chemUnit === "ml") {
      chemValue = Math.ceil(actualValue);
      actualValue = chemValue;
    }
  }
  if (!valueType) {
    chemValue = Math.ceil(chemValue);
    if (actualValue > 1) actualValue = Math.ceil(actualValue);
  }

  return { chemValue, chemUnit, actualValue };
};

export const calculatingDosage = (dose, size, secDose) => {
  const res = (parseFloat(dose) / 40000) * parseFloat(size);
  let finalRes = convertQuantUnit(res).actualValue;
  if (secDose) {
    finalRes = convertQuantUnit(
      (parseFloat(finalRes) * secDose) / 100,
    ).actualValue;
  }
  return finalRes;
};

export const calculatChemicalProductCost = (item) => {
  let totalCost;
  let totalMargin;
  let totalLabourAmount;
  let totalLabour;
  let totalDuration;
  item.forEach((el, i) => {
    totalCost = item
      .map((el2) =>
        el2.reduce(
          (pre, next) =>
            parseFloat(pre || 0) +
            parseFloat(next.renderCost ? next.renderCost : 0),
          0,
        ),
      )
      .reduce((pre, next) => pre + next);
    totalMargin = item
      .map((el2) =>
        el2.reduce(
          (pre, next) =>
            parseFloat(pre || 0) +
            parseFloat(next.laborMargin ? next.laborMargin : 0),
          0,
        ),
      )
      .reduce((pre, next) => pre + next);
    totalLabourAmount = item
      .map((el2) =>
        el2.reduce(
          (pre, next) =>
            parseFloat(pre || 0) +
            parseFloat(next.totalLabour ? next.totalLabour : 0),
          0,
        ),
      )
      .reduce((pre, next) => pre + next);
    totalLabour = totalLabourAmount + totalMargin;
    totalDuration = item
      .map((el2) =>
        el2.reduce(
          (pre, next) =>
            parseFloat(pre || 0) +
            parseFloat(next.duration ? next.duration : 0),
          0,
        ),
      )
      .reduce((pre, next) => pre + next);
    // console.log({ subtotalDuration: totalDuration, subtotalLabour: totalLabour, subtotalCost: totalCost });
  });
  return {
    subtotalDuration: totalDuration,
    subtotalLabour: totalLabour,
    subtotalCost: totalCost,
  };
};

export const removeComma = (value) => {
  let res = value;
  if (res) {
    res = value?.toString().replace(/,/g, "");
  }
  return res;
};
export const getCalendarPath2 = (appointmentID) => {
  // console.log('appointmentID====>', { appointmentID });
  let staff_id = localStorage.getItem("staff_id");
  let date = localStorage.getItem("date");
  let view = localStorage.getItem("view");
  let backPreDate = appointmentID || localStorage.getItem("prebackDate");
  const calcDate = date
    ? moment.utc(date).format("YYYY-MM-DD")
    : moment(new Date()).format("YYYY-MM-DD");
  let calendarUrl = `/calendar?activedate=${calcDate}&view=${view || "timeGridWeek"
    }&staff_id=${staff_id || ""}&appointmentId=${appointmentID}`;
  return calendarUrl;
};

export const updateReschduleChemicals = (chemicalServices, lastitem) => {
  const { end } = lastitem || {};
  let upTime = "";
  let copyChemicalServices = chemicalServices;
  if (copyChemicalServices && copyChemicalServices.length > 0) {
    copyChemicalServices[0].testResult[0].service.forEach((el, index) => {
      if (index === 0) {
        upTime = moment(new Date(end)).format("HH:mm");
      } else {
        upTime = moment(
          new Date(
            copyChemicalServices[0].testResult[0].service[index - 1].end,
          ),
        ).format("HH:mm");
      }
      let time = upTime.split(":");
      let start = moment(end).toDate();
      start.setHours(time[0]);
      start.setMinutes(time[1]);
      start.setSeconds(0);
      el.start = moment(start).format("YYYY-MM-DDTHH:mm:ssZ");
      let serviceEndTime = moment(
        new Date(
          start.setMinutes(start.getMinutes() + parseFloat(el.duration)),
        ),
      ).format("YYYY-MM-DDTHH:mm:ssZ");
      el.end = serviceEndTime;
      el.endTime = moment(new Date(serviceEndTime)).format("HH:mm");
      el.startTime = upTime;
      el.staffId = localStorage.getItem("staff_id");
      el.staffName = localStorage.getItem("staff_name");
    });
    return copyChemicalServices;
  }
  return "";
};
export const getFirstServiceTime = (dates, time) => {
  const firstTime = time.split(":");
  let start = moment(dates).toDate();
  start.setHours(firstTime[0]);
  start.setMinutes(firstTime[1]);
  start.setSeconds(0);
  const upservTime = moment(start).format("YYYY-MM-DDTHH:mm:ssZ");
  return upservTime;
};

// For the average GST of services array.
// Note:- Always pass the single appointment aray in averageGstCalculation function.
// **Start**
function roundToTwo(num) {
  // eslint-disable-next-line prefer-template
  const result = +(Math.round(num + "e+2") + "e-2");
  return result;
}
export const averageGstCalculation = (appointmentData) => {
  let cost = [];
  let tax = [];
  // console.log('I am here-----------');
  if (appointmentData[0].service.length > 0) {
    // parsFLoat tha tax and cost(cost is render_cost here)
    appointmentData[0].service.forEach((el) => {
      cost.push(parseFloat(el.renderCost === "N/A" ? 0 : el.renderCost));
      if (el.tax) {
        tax.push(parseFloat(el.tax));
      } else {
        tax.push(10);
      }
    });
    // divide taxes with 100
    const newTax = tax.map((el) => el / 100);
    // Findning new numbers by multiply tax and cost then add them together
    let totalNewCost = 0;
    newTax.forEach((el, index) => {
      totalNewCost += el * cost[index];
    });
    // add old all old cost-
    const totalCost = cost.reduce((partialSum, a) => partialSum + a, 0);
    // Avergae Percentage Formula
    const averagePercentGst = (totalNewCost / totalCost) * 100;
    const perfectGstPer = roundToTwo(averagePercentGst);
    // console.log('done-----');
    return { perfectGstPer, totalCost };
  }
  return 0;
};
// **End**

export const calculatChemicalCost = (item) => {
  let totalCost = 0;
  let totalMargin = 0;
  let totalLabourAmount = 0;
  let totalLabour = 0;
  let totalDuration = 0;
  if (item && item.length > 0) {
    item.forEach((el) => {
      totalDuration += parseInt(el.duration, 10);
      totalCost += parseFloat(el.renderCost);
      totalLabourAmount += parseFloat(el.totalLabour || 0);
      totalMargin += parseFloat(el.laborMargin);
    });
    totalLabour = totalLabourAmount + totalMargin;
  }
  return {
    subtotalDuration: totalDuration,
    subtotalLabour: totalLabour,
    subtotalCost: totalCost,
  };
};

export const calculatServiceProCost = (item) => {
  let totalCost = 0;
  let totalMargin = 0;
  let totalLabourAmount = 0;
  let totalLabour = 0;
  let totalDuration = 0;
  let subSummary = {};
  item.forEach((el) => {
    totalDuration += parseInt(el.duration, 10);
    totalCost += parseFloat(el.renderCost || 0);
    totalLabourAmount += parseFloat(el.totalLabour || 0);
    totalMargin += parseFloat(el.laborMargin || 0);
  });
  totalLabour = totalLabourAmount + totalMargin;
  subSummary.totalCost = addZero(parseFloat(totalCost).toFixed(2));
  subSummary.totalDuration = totalDuration;
  subSummary.totalLabour = addZero(parseFloat(totalLabour).toFixed(2));
  subSummary.multiTotal = totalCost;
  subSummary.totalMargin = addZero(parseFloat(totalMargin).toFixed(2));
  subSummary.totalLabourOnly = addZero(
    parseFloat(totalLabourAmount).toFixed(2),
  );
  return { subSummary };
};

const updategrupeServiceDateTime = (chemicalServices, end) => {
  let upTime = "";
  const newStafId = localStorage.getItem("staff_id");
  const newStaffName = localStorage.getItem("staff_name");
  chemicalServices.forEach((el, index) => {
    if (index === 0) {
      upTime = moment(new Date(end)).format("HH:mm");
    } else {
      upTime = moment(new Date(chemicalServices[index - 1].end)).format(
        "HH:mm",
      );
    }
    let time = upTime.split(":");
    let start = moment(end).toDate();
    start.setHours(time[0]);
    start.setMinutes(time[1]);
    start.setSeconds(0);
    el.start = moment(start).format("YYYY-MM-DDTHH:mm:ssZ");
    let serviceEndTime = moment(
      new Date(start.setMinutes(start.getMinutes() + parseFloat(el.duration))),
    ).format("YYYY-MM-DDTHH:mm:ssZ");
    el.end = serviceEndTime;
    el.endTime = moment(new Date(serviceEndTime)).format("HH:mm");
    el.startTime = upTime;
    el.staffId = newStafId;
    el.staffName = newStaffName;
  });
  return chemicalServices;
};

export const updategrupeGrupeApp = (allArray, newDate) => {
  let newDates = newDate;
  const visitIndex = localStorage.getItem("visitIndex");
  let arrayNew1 = [];
  let arrayNew2 = [];
  let CopyNewGropeApp = [];
  let partCount = 0;
  const appTypes = allArray[0].appointment_calc_type;
  const gape =
    appTypes === "Black-Spot Algae" || appTypes === "Drain Pool"
      ? 2
      : appTypes === "Green Pool"
        ? 4
        : 1;
  // console.log('newDateccc=>', {
  //   newDate, visitIndex, allArray, gape,
  // });
  if (visitIndex !== "1") {
    const copyallArray = [...allArray];
    arrayNew1 = copyallArray.splice(0, parseInt(visitIndex, 10));
    partCount = arrayNew1.length - 1;
  }
  const dateGapeDay = gape * partCount;
  newDates = moment(new Date(newDates))
    .subtract(dateGapeDay, "days")
    .format("YYYY-MM-DDTHH:mm:ssZ");
  allArray.forEach((el, indexs) => {
    let el22 = el;
    const servicesArray = el.dateData[0].service;
    const upDate = moment(new Date(newDates)).format("YYYY-MM-DD");
    el22.dateData[0].service = updategrupeServiceDateTime(
      servicesArray,
      newDates,
    );
    el22.dateData[0].date = new Date(upDate).toISOString();
    newDates = moment(new Date(newDates))
      .add(gape, "days")
      .format("YYYY-MM-DDTHH:mm:ssZ");
    CopyNewGropeApp.push(el22);
  });
  return CopyNewGropeApp;
};

export const getDaysArray = (tofrom) => {
  const indexArray = tofrom.split("-");
  const to = indexArray[0];
  const from = indexArray[1];
  const dayList = [];
  const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  for (let i = parseInt(to); i <= parseInt(from); i++) {
    let day = weekday[i];
    dayList.push(day);
  }
  return dayList;
};

export const searchClientFun = async (searchTxt) => {
  try {
    if (searchTxt) {
      const response = await userInstance().get(
        `/customer/searchCustomer?value=${searchTxt}`,
      );
      console.log("response", response);
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllCustomersFun = async () => {
  try {
    const response = await userInstance().get("/customer/getCustomersAll");
    return response.data;
  } catch (error) {
    //   console.log(error);
  }
};

export const numberFromString = (value, not) => {
  // console.log('value==>', value);
  let str = value;
  const matches = str.match(/(\d+)/);
  if (matches) {
    let number = parseFloat(matches[0]);
    if (!not && (str?.includes("grams") || str?.includes("ml"))) {
      number /= 1000;
    }

    return number;
  }
  // return 0 means there is no number in the input value. Use check according to 0 where you call. Don't change it from here
  return 0;
};

export function lowerFirstLetter(string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export const capitalizeFirstLetter = (string) => {
  if (!string) {
    return "";
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const removeCodeFromNumber = (number) => {
  const splitValue = number.includes("+61") ? "+61" : "+1";
  const splitData = number.split(splitValue);
  console.log("splitData", splitData);
  return {
    countryCode: splitValue,
    updatedNo: splitData[splitData?.length - 1]?.trim(),
  };
};

export const gstChemicalsCalculations = (services) => {
  let totalNewCost = 0;
  services.forEach((el) => {
    totalNewCost +=
      (parseFloat(el?.renderCost) * parseFloat(el?.tax || 0)) / 100;
  });
  return totalNewCost;
};

// csv uplaods objcets creation and validations  Start-->>>>

export const checkValidationCSVClient = (objData, fileName) => {
  // destrucutree key value pairs for validation
  const {
    customerType,
    companyName,
    firstname,
    lastname,
    mobileNo,
    landlineNo,
    email,
    streetAddress,
    suburb,
    state,
    country,
    invoiceSendType,
    countryCode,
  } = objData;
  // create object for validation of the keys. If required keys not found then return false also check value is empty or not
  const validationObj = {
    "customer type": customerType,
    "first name": firstname,
    "last name": lastname,
    "mobile no": mobileNo,
    "street address": streetAddress,
    suburb,
    state,
    country,
  };
  if (customerType !== "Householder") {
    validationObj[`${customerType} name`] = companyName;
  }
  validationObj["invoice sending type"] = invoiceSendType;
  let { isValid, emptyField } = validateDataWithKey(validationObj);
  objData.name = [firstname, lastname].join(" ");
  // Now checking the values of keys to be valid or not like email phone etc.
  if (isValid) {
    if (email) {
      if (emailRegex.test(String(objData.email).toLowerCase())) {
        let isMobileValid = true;
        let isLandlineValid = true;
        // formatting  mobile number for databse schema
        objData.mobileNo = `+${objData.mobileNo}`;
        objData.mobileNo = `${objData.mobileNo.substr(
          0,
          3,
        )} ${objData.mobileNo.substr(3, 3)} ${objData.mobileNo.substr(
          6,
          3,
        )} ${objData.mobileNo.substr(9, 3)}`;
        // formatting  landline number for databse schema
        objData.landlineNo = `+${objData.landlineNo}`;
        objData.landlineNo = `${objData.landlineNo.substr(
          0,
          3,
        )} ${objData.landlineNo.substr(3, 1)} ${objData.landlineNo.substr(
          4,
          4,
        )} ${objData.landlineNo.substr(8, 4)}`;
        if (objData.mobileNo) {
          if (objData.mobileNo.startsWith("+61")) {
            if (objData.mobileNo.charAt(4) === "0") {
              objData.mobileNo = objData.mobileNo.replace("0", "");
              isMobileValid = true;
            }
            if (objData.landlineNo.charAt(4) === "0") {
              objData.landlineNo = objData.landlineNo.replace("0", "");
              isLandlineValid = true;
            }
            if (objData.landlineNo.trim().length !== 15) {
              isLandlineValid = false;
            }
            if (objData.mobileNo.charAt(4) !== "4") {
              isMobileValid = false;
            }
          }
          // if mobile valid then retun the obj
          if (isMobileValid) {
            delete objData.countryCode;
            if (fileName) {
              objData.csvName = fileName;
            }
            return objData;
          }
        }
      }
    }
  }
  return {};
};

export const checkServiceValidations = (data, fileName, type) => {
  if (data) {
    const subcategoryCopy = data;
    const {
      name,
      serviceDescription,
      duration,
      cost,
      retailCost,
      tax,
      category,
      appointmentChecklist,
      totalStock,
    } = subcategoryCopy;
    const validationObj = {
      name,
      description: serviceDescription,
      duration,
      tax,
      category,
      appointmentChecklist,
    };
    const priceTypo =
      type === "product" || type === "inventory" ? "buy cost" : "sell price";
    if (!category?.toLowerCase().includes("misc")) {
      validationObj[priceTypo] = cost;
    }
    // console.log('cost', cost);
    if (type === "product" || type === "inventory") {
      if (!category?.toLowerCase().includes("misc")) {
        validationObj["retail cost"] = retailCost;
      }
      subcategoryCopy.retailCost = parseFloat(
        subcategoryCopy?.retailCost?.replace(/,/g, ""),
      ).toFixed(2);
    }
    subcategoryCopy.cost = parseFloat(
      subcategoryCopy?.cost?.replace(/,/g, ""),
    ).toFixed(2);

    let { isValid, emptyField } = validateDataWithKey(validationObj);
    if (isValid) {
      if (
        (type === "product" || type === "inventory") &&
        parseFloat(subcategoryCopy.cost) >
        parseFloat(subcategoryCopy.retailCost)
      ) {
        isValid = false;
      }
      subcategoryCopy.tax = parseFloat(
        subcategoryCopy?.tax?.replace(/,/g, ""),
      ).toFixed(2);

      if (isValid) {
        if (
          (type === "product" || type === "inventory") &&
          subcategoryCopy?.tax?.length === 0
        ) {
          isValid = false;
          toastMessage("error", "Please Enter tax details");
        }
      }
      // Adding s_uid and category id to sub categories
      let randomStr = (
        Math.floor(Math.random() * 9 + 1) +
        Date.now() +
        (Math.random() + 1).toString(36).substring(7)
      ).toString();
      subcategoryCopy.s_uId = randomStr;
      // subcategoryCopy.categoryId = selectedCategoryId._id;

      if (isValid) {
        // console.log('subcategoryCopy', subcategoryCopy);
        const reqObj = {
          ...subcategoryCopy,
        };
        if (!category.toLowerCase().includes("misc")) {
          reqObj.name = `${subcategoryCopy.name} $${addZero(
            subcategoryCopy[
            type === "product" || type === "inventory" ? "retailCost" : "cost"
            ],
          )}`;
        }
        if (type === "service") {
          const {
            barCode,
            categoryId,
            currentStockItems,
            supplier,
            supplyUnit,
            ...serviceReqObj
          } = reqObj;
          return reqObj;
        }
        const prodData = {
          subcategory: reqObj,
          stockData: [],
          type: "product",
          totalStock: parseFloat(totalStock || 0),
        };
        return prodData;
      }
    } else {
      return {};
      // NotificationManager.error(`Please enter ${emptyField} for ${type}`, 'Message', errorMsgTime);
    }
  } else {
    return {};
    // NotificationManager.error('Please enter product name', 'Message', errorMsgTime);
  }
  return {};
};

const checkStaffvalidations = (data, fileName, type) => {
  const staffData = { ...data };
  const { firstname, lastname, email, accessLevel, location } = staffData;
  try {
    const { isValid, emptyField } = validateDataWithKey({
      "first name": firstname,
      "last name": lastname,
      email,
      "access level": accessLevel,
      location,
    });
    if (isValid) {
      let isMobileValid = true;
      let isLandlineValid = true;
      let errorMsg = "";
      if (emailRegex.test(String(staffData.email).toLowerCase())) {
        const payload = { ...staffData, email: email.toLowerCase() };
        payload.mobileNo = `+${payload.mobileNo}`;
        payload.mobileNo = `${payload.mobileNo.substr(
          0,
          3,
        )} ${payload.mobileNo.substr(3, 3)} ${payload.mobileNo.substr(
          6,
          3,
        )} ${payload.mobileNo.substr(9, 3)}`;
        // formatting  landline number for databse schema
        payload.landlineNo = `+${payload.landlineNo}`;
        payload.landlineNo = `${payload.landlineNo.substr(
          0,
          3,
        )} ${payload.landlineNo.substr(3, 1)} ${payload.landlineNo.substr(
          4,
          4,
        )} ${payload.landlineNo.substr(8, 4)}`;
        if (payload.mobileNo) {
          if (payload.mobileNo.startsWith("+61")) {
            if (payload.mobileNo.charAt(4) === "0") {
              payload.mobileNo = payload.mobileNo.replace("0", "");
              isMobileValid = true;
            }
            if (payload.landlineNo.charAt(4) === "0") {
              payload.landlineNo = payload.landlineNo.replace("0", "");
              isLandlineValid = true;
            }
            if (payload.landlineNo.trim().length !== 15) {
              isLandlineValid = false;
            }
            if (payload.mobileNo.charAt(4) !== "4") {
              isMobileValid = false;
            }
          }
          // if mobile valid then retun the obj
          if (isMobileValid) {
            delete payload.countryCode;
            if (fileName) {
              payload.csvName = fileName;
            }
            return payload;
          }
          return {};
        }
        return {};
      }

      console.log("Email not valid");
      return {};
    }
    console.log("not valid");
    return {};
  } catch (error) {
    console.log("error--", error);
    return {};
  }
};

export const checkKeysAndCreateData = (data, fileName, csvType) => {
  const mainData = [];
  // const { data } = e;
  const keys = data[0];
  let readyToUpload = true;
  let errorAtRows = "";
  for (let i = 1; i < data.length; i++) {
    if (keys.length === data[i].length) {
      let obj = {};
      for (let j = 0; j < data[i].length; j++) {
        let str = keys[j];
        let value = lowerFirstLetter(str);
        obj[value] = data[i][j]?.toString();
        obj.csvName = fileName || "";
      }
      // function for checking validations
      let objData = {};
      if (csvType === "customer") {
        objData = checkValidationCSVClient(obj, fileName);
      } else if (
        csvType === "service" ||
        csvType === "product" ||
        csvType === "inventory"
      ) {
        objData = checkServiceValidations(obj, fileName, csvType);
      } else if (csvType === "staff") {
        objData = checkStaffvalidations(obj, fileName, csvType);
      }
      const checkObj = Object.keys(objData).length === 0;
      if (!checkObj) {
        mainData.push(objData);
      } else {
        readyToUpload = false;
        errorAtRows = `${errorAtRows}, ${(i + 1).toString()}`;
      }
    }
  }
  return { mainData, readyToUpload, errorAtRows };
};

export const handlePress = (event) => {
  // eslint-disable-next-line no-restricted-globals
  if (event.key !== " " && !isNaN(event.key)) {
    event.preventDefault();
    return false;
  }
  return true;
};
const removeComma2 = (value) => {
  let res = value;
  if (res) {
    res = value.toString().replace(/,/g, "");
  }
  return parseFloat(res);
};

const getGst = (cost, pre) => {
  const gst = (parseFloat(cost) * parseFloat(pre || 0)) / 100;

  return gst;
};

export const PredictiveOrderTotala = (itemList, role, tax) => {
  const total = itemList?.reduce(
    (pre, next) =>
      parseFloat(pre || 0) +
      parseFloat(next.overAllCost ? removeComma2(next?.overAllCost) : 0),
    0,
  );

  // it will be use when there will be gst wrong
  // const total2 = itemList?.reduce(
  //   (pre, next) => parseFloat(pre || 0) + parseFloat(next.withoutGstOverAllCost ? removeComma2(next?.withoutGstOverAllCost) : 0),
  //   0,
  // );

  // const gsttotal = itemList?.reduce(
  //   (pre, next) => parseFloat(pre || 0)
  //     + parseFloat(next.overAllCost ? getGst(removeComma2(next?.overAllCost), 11) : 0),
  //   0,
  // );
  // console.log('=>>>>>>>>>>>>>>>>>>', { longTotal: total2.toFixed(2) });

  let gsttotal = 0;
  if (role !== "professional-user") {
    const gst2Total = (total / tax).toFixed(2);
    gsttotal = total - Number(gst2Total);
    // console.log('=>>>>>>>>>>>>>>>>>>', { longTotal: total2.toFixed(2), gst2Total });
  } else {
    gsttotal = total / 11;
  }

  console.log({ total, gsttotal });

  const subtotal = total - gsttotal;
  return {
    gst: gsttotal?.toFixed(2),
    subtotal: subtotal?.toFixed(2),
    total: total?.toFixed(2),
  };
};

export const chemicalsUnitDropdown = [
  {
    renderName: "Potassium Monopersulphate",
    dropDownOptions: ["500 grams", "25 kg"],
  },
  {
    renderName: "Granular Chlorine",
    dropDownOptions: ["1 kg", "2 kg", "4 kg", "10 kg", "25 kg", "50 kg"],
  },
  {
    renderName: "Soda Ash",
    dropDownOptions: ["2 kg", "25kg"],
  },
  {
    renderName: "Hydrochloric Acid",
    dropDownOptions: ["1 litre", "5 litres", "15 litres"],
  },
  {
    renderName: "Liquid Chlorine",
    dropDownOptions: ["1 litre", "5 litres", "15 litres"],
  },
  {
    renderName: "Liquid Stabiliser",
    dropDownOptions: ["1 litre", "2 litres", "25 litres"],
  },
  {
    renderName: "Sodium Thiosulphate",
    dropDownOptions: ["500 grams", "10 kg", "25 kg"],
  },
  {
    renderName: "Calcium Raiser",
    dropDownOptions: ["500 grams", "2 kg", "4 kg", "25 kg"],
  },
  {
    renderName: "Buffer",
    dropDownOptions: ["2 kg", "4 kg", "25kg"],
  },
  {
    renderName: "Stabiliser",
    dropDownOptions: ["1 kg", "2 kg", "25 kg"],
  },
  {
    renderName: "Phosphate Remover",
    dropDownOptions: ["2.5 litres", "20 litres"],
  },
  {
    renderName: "Salt (20 kg)",
    dropDownOptions: ["20 kg"],
  },
  {
    renderName: "Magna Minerals (10 kg)",
    dropDownOptions: ["10 kg"],
  },
  {
    renderName: "Aquatherepe Transform (10 kg)",
    dropDownOptions: ["10kg"],
  },
  {
    renderName: "Zodiac Pool Long Life Algaecide",
    dropDownOptions: ["1 litre", "2.5 litres", "5 litres", "25 litres"],
  },
  {
    renderName: "Flock",
    dropDownOptions: ["2 kg"],
  },
  {
    renderName: "Aquatherepe Minerals (10 kg)",
    dropDownOptions: ["10 kg"],
  },
  {
    renderName: "Clarifier (green pool)",
    dropDownOptions: ["1 litre"],
  },
  {
    renderName: "Clarifier",
    dropDownOptions: ["1 litre"],
  },
  {
    renderName: "Bulk Hydrochloric Acid",
    dropDownOptions: ["15 litres"],
  },
  {
    renderName: "Bulk Liquid Chlorine",
    dropDownOptions: ["15 litres"],
  },
  {
    renderName: "Fine Glass Media (15 kg)",
    dropDownOptions: ["15 kg"],
  },
  {
    renderName: "Coarse Glass Media (15 kg)",
    dropDownOptions: ["15 kg"],
  },
  {
    renderName: "Sand Media (20 kg)",
    dropDownOptions: ["20 kg"],
  },
  {
    renderName: "Zodiac Metal Reducer",
    dropDownOptions: ["1 litre"],
  },
  {
    renderName: "Dry Acid",
    dropDownOptions: ["3 kg"],
  },
  {
    renderName: "Black-Spot Algae Remover",
    dropDownOptions: ["1 kg"],
  },
  {
    renderName: "Zodiac Calcium Down",
    dropDownOptions: ["1 litre"],
  },
];

export const getUnit = (unitValue, stockValue) => {
  let string = unitValue;
  if (!string) {
    return "";
  }
  if (stockValue > 1) {
    string += "s";
  }
  // string += 's';
  if (unitValue.includes("litre")) string = stockValue > 1 ? "litres" : "litre";
  if (
    unitValue.includes("kg") ||
    unitValue.includes("bag") ||
    unitValue.includes("gram")
  )
    string = "kg";
  if (unitValue.includes("item")) string = stockValue > 1 ? "items" : "item";
  return string;
};
export const getUnitNew = (unitValue, stockValue) => {
  let string = unitValue;
  if (!string) {
    return "";
  }

  if (unitValue.includes("litre")) string = "litre";
  if (
    unitValue.includes("kg") ||
    unitValue.includes("bag") ||
    unitValue.includes("gram")
  ) {
    string = "kg";
  }

  if (unitValue.includes("item")) {
    string = "item";
  }
  return string;
};
export const removeSfromString = (str) => {
  if (str.charAt(str.length - 1) === "s") {
    str = str.substring(0, str.length - 1);
    return str;
  }
  return str;
};

export const getPerUnitFromUnitValue = (str) => str.split(/(\d+)/)[2];

export const makeAddressOrderDetail = (ans) => {
  let str = "";
  let index = 0;
  // eslint-disable-next-line guard-for-in
  for (const prop in ans) {
    if (ans[prop]) {
      ans[prop] = ans[prop].trim();
      if (ans[prop][ans[prop].length - 1] === ",") {
        ans[prop] = ans[prop].slice(0, -1);
      }
      if (index === 0) {
        str += ans[prop];
      } else if (prop === "state" && ans[prop]) {
        str += ` ${ans[prop]?.substr(0, 3)?.toUpperCase()}`;
      } else if (prop === "postcode" && ans[prop]) {
        str += ` ${ans[prop]}`;
      } else if (prop === "country" && ans[prop]) {
        str += ` | ${ans[prop]}`;
      } else {
        str += `, ${ans[prop]}`;
      }
      index++;
    }
  }
  return str;
};

export const isAnyFieldBlank = (actualArray, arr) => {
  let blankValues = [];
  actualArray.forEach((el) => {
    arr.forEach((elem) => {
      if (el[elem] === "." || !el[elem]?.trim()) {
        blankValues.push(el.name);
      }
    });
  });
  return blankValues;
};

export const coverBlankField = (value, name, arr) => {
  if (value) value = value.toString();
  if (!value) return "";
  if ((value === "." || !value?.trim()) && arr.indexOf(name) >= 0) {
    return "error-text";
  }
  return "";
};

export const onKeyDownNumber = (event) => {
  if (event.key === "+" || event.key === "-" || event.key === "e") {
    event.preventDefault();
  }
};
export const onKeyDownNumberWithDecimcal = (event) => {
  if (
    event.key === "+" ||
    event.key === "-" ||
    event.key === "e" ||
    event.key === "."
  ) {
    event.preventDefault();
  }
};

export const getNearestInt = (val, arr, gstPrice, unitType, predType) => {
  console.log("*****", { gstPrice });
  // console.log({ arr });

  const roundOff = Math.ceil(val);
  let minimumDiffrence = Number.MAX_SAFE_INTEGER;
  let nearestInteger = -1;
  let division = -1;
  let cost = 0;
  let renderVolume = "";
  let withoutGstCost = 0;
  // arr.forEach((el) => {
  //   const num = numberFromString(el);
  //   if (name === 'Salt') console.log(num);
  //   if (roundOff % num === 0 && num > nearestInteger) {
  //     nearestInteger = num;
  //   }
  //   division = roundOff / nearestInteger;
  // });

  // if (name === 'Salt')console.log({ nearestInteger, division });

  arr.forEach((el) => {
    const curDivision = Math.ceil(roundOff / el.volume);
    const currentTotal = curDivision * el.volume;
    const currentDiffrence = currentTotal - val;

    if (currentDiffrence <= minimumDiffrence) {
      minimumDiffrence = currentDiffrence;
      nearestInteger = el.volume;
      cost = el.sellprice;
      console.log(el.renderVolume);
      renderVolume = el.renderVolume;
      division = curDivision;
    }
  });

  // changes By Randhir
  withoutGstCost = cost;
  cost = Number((cost * gstPrice).toFixed(2));
  if (predType === "today" && unitType === "per bag") {
    division = val;
  }

  return {
    minimumDiffrence,
    nearestInteger,
    division,
    cost,
    renderVolume,
    withoutGstCost,
  };
};

export const alphaOnly = (a) => {
  var b = "";
  for (var i = 0; i < a.length; i++) {
    if (a[i] >= "A" && a[i] <= "z") b += a[i];
  }
  return b;
};
export const updateSingleServiceDateTime = (
  serArra,
  editServiceIndex,
  addduration,
) => {
  const dateFormat = "YYYY-MM-DDTHH:mm:ssZ";
  let compyserArray = [...serArra];
  const { startTime, start, duration } = serArra[editServiceIndex] || {};
  let newduration = "15";
  let start2 = moment(start).toDate();
  const time = startTime.split(":");
  start2.setHours(time[0]);
  start2.setMinutes(time[1]);
  start2.setSeconds(0);
  if (editServiceIndex !== 0) {
    newduration = parseInt(duration) + addduration;
  }
  compyserArray[editServiceIndex].start = moment(start2).format(dateFormat);
  compyserArray[editServiceIndex].end = moment(
    new Date(start2.setMinutes(start2.getMinutes() + parseFloat(newduration))),
  ).format(dateFormat);
  compyserArray[editServiceIndex].duration = newduration;
  compyserArray[editServiceIndex].previousDuration = newduration;
  return compyserArray;
};

export const validateCard = (values) => {
  let errors = "";
  let isValid = true;
  if (values.cardNum) {
    if (values.cardNum.length < 10 || values.cardNum.length > 20) {
      errors = "Invalid Card Number";
      isValid = false;
      return { isValid, errors };
    }
  }
  if (values.expirydate) {
    if (values.expirydate.length !== 5) {
      errors = "Invalid Expire Date";
      isValid = false;
      return { isValid, errors };
    }
    let str = values.expirydate;
    const myArr = str.split("/");
    console.log("myArr =>", myArr);
    if (myArr && myArr.length > 0) {
      const month = Number(myArr[0]);
      const year = Number(myArr[1]);
      if (Number.isNaN(month)) {
        errors = "Invalid Expire Date";
        isValid = false;
        return { isValid, errors };
      }

      if (Number.isNaN(year)) {
        errors = "Invalid Expire Date";
        isValid = false;
        return { isValid, errors };
      }

      const now = new Date();
      const currYear = now.getUTCFullYear();
      if (
        month > 12 ||
        month < 1 ||
        year < Number(currYear.toString().substr(-2))
      ) {
        errors = "Invalid Expire Date";
        isValid = false;
        return { isValid, errors };
      }
    }
  }

  if (values.cvv) {
    if (values.cvv.length !== 3) {
      errors = "Invalid CVC Number";
      isValid = false;
      return { isValid, errors };
    }
  }
  return { isValid, errors };
};
