// SettingsPage.js
import React, { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { FaSortAmountDownAlt } from "react-icons/fa";
import { FaSortAmountUp } from "react-icons/fa";
import { isMobile } from "react-device-detect";
import ScheduleCard from "../components/scheduleCard";
import { Button, IconButton, SearchBar, Select } from "../components/forms"; // Assuming you have these components
import { RiCalendarScheduleLine } from "react-icons/ri";
import AddScheduleModal from "./addScheduleModal";
import SelectField from "../../chatbotSettings/components/selectField";
import { USER_TYPES_ARRAY } from "../constant";
import { USER_TYPES } from "../../subscription/utils";
import RemoveModal from "./removeScheduleModal";
import { server } from "../../../config/keys";
import { userInstance } from "../../../config/axios";
import toastMessage from "../../../functions/toastMessage";

import './styles.css'
import DuplicateModal from "./duplicateModal";
import EnableModal from "./enableModal";
import { VscDebugRestart } from "react-icons/vsc";

const defaultFilter = {
  userType: USER_TYPES.allUsers
}

const ScheduleList = (props) => {
  const { emailSchedules, setEmailSchedules, getEmailSchedules } = props;

  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(defaultFilter);
  const [filteredEmailSchedules, setFilteredEmailSchedules] = useState(emailSchedules);
  const [showModal, setShowModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isRemoving, setIsRemoving] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [isEnabling, setIsEnabling] = useState(false);
  const [showEnableModal, setShowEnableModal] = useState(false);
  const [isResort, setIsResort] = useState(false);


  const handleMoveUp = (index) => {
    if (index > 0) {
      setFilteredEmailSchedules((prev) => {
        const newSchedules = [...prev];
        [newSchedules[index - 1], newSchedules[index]] = [newSchedules[index], newSchedules[index - 1]];
        return newSchedules;
      });
    }
  };

  const handleMoveDown = (index) => {
    if (index < filteredEmailSchedules.length - 1) {
      setFilteredEmailSchedules((prev) => {
        const newSchedules = [...prev];
        [newSchedules[index], newSchedules[index + 1]] = [newSchedules[index + 1], newSchedules[index]];
        return newSchedules;
      });
    }
  };

  const handleEdit = (data) => {
    setIsEditMode(true);
    setSelectedItem(data);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedItem(null);
  }

  const handleDelete = (data) => {
    setSelectedItem(data);
    setShowRemoveModal(true);
  };

  const handleRemoveModalClose = () => {
    setShowRemoveModal(false);
    setSelectedItem(null);
  }

  const handleDeleteItem = async (id) => {
    try {
      setIsRemoving(true);
      let response = await userInstance().delete(
        `${server}/emailSchedules/${id}`,
      );
      const { msg } = response.data;
      if (response.status === 200) {
        let _emailSchedules = emailSchedules.slice();
        let findIndex = _emailSchedules.findIndex((item) => item._id === id);
        if (findIndex !== -1) {
          _emailSchedules.splice(findIndex, 1);
          setEmailSchedules(_emailSchedules);
          handleRemoveModalClose();
          toastMessage("success", msg);
        } else {
          toastMessage("error", "There is no a schedule with this id.");
        }
      } else {
        toastMessage("error", msg);
      }
    } catch (error) {
      console.log("error", error);
    }
    setIsRemoving(false);
  };

  const handleEnable = (data) => {
    setShowEnableModal(true);
    setSelectedItem(data);
  };

  const handleEnableModalClose = () => {
    setShowEnableModal(false);
    setSelectedItem(null);
  }

  const handleEnableItem = async (id, isEnable) => {
    try {
      setIsEnabling(true);
      let response = await userInstance().put(
        `${server}/emailSchedules/${selectedItem._id}`,
        { isEnable },
      );
      const { msg } = response.data;
      if (response.status === 200) {
        let _emailSchedules = emailSchedules.slice();
        let findIndex = _emailSchedules.findIndex((item) => item._id === id);
        if (findIndex !== -1) {
          _emailSchedules[findIndex].isEnable = isEnable;
          setEmailSchedules(_emailSchedules);
        }
        handleEnableModalClose();
        toastMessage("success", msg);

      } else {
        toastMessage("error", msg);
      }
    } catch (error) {
      console.log("error", error);
    }
    setIsEnabling(false);
  }

  const handleDuplicateModalClose = () => {
    setShowDuplicateModal(false);
    setSelectedItem(null);
  }

  const handleDuplicate = (data) => {
    setShowDuplicateModal(true);
    setSelectedItem(data);
  }

  const handleDuplicateItem = async (id) => {
    try {
      setIsDuplicating(true);
      let response = await userInstance().put(
        `${server}/emailSchedules/duplicate/${id}`,
      );
      const { msg, emailSchedule } = response.data;
      if (response.status === 200) {
        setEmailSchedules((prev) => [...prev, emailSchedule]);
        handleDuplicateModalClose();
        toastMessage("success", msg);
      } else {
        toastMessage("error", msg);
      }
    } catch (error) {
      console.log("error", error);
    }
    setIsDuplicating(false);
  }

  const handleClickAddBtn = () => {
    setSelectedItem(null);
    setIsEditMode(false);
    setShowModal(true);
  }

  const handleChangeUserType = (value) => {
    setFilter((prev) => ({ ...prev, userType: value }));
  }

  const handleSearchSchedule = (value) => {
    setSearch(value);
    if (value !== "") {
      let _filteredEmailSchedules = filteredEmailSchedules.filter((item) => item.subject.toLowerCase().includes(value.toLowerCase()));
      setFilteredEmailSchedules(_filteredEmailSchedules);
    } else {
      let _filteredEmailSchedules = emailSchedules.filter((item) => item.userType === filter.userType);
      setFilteredEmailSchedules(_filteredEmailSchedules);
    }
  }

  const handleReStore = () => {
    let _filteredEmailSchedules = filteredEmailSchedules.slice();
    _filteredEmailSchedules = _filteredEmailSchedules.reverse();
    setFilteredEmailSchedules(_filteredEmailSchedules);
    setIsResort((prev) => !prev);
  }

  useEffect(() => {
    let _filteredEmailSchedules = emailSchedules.filter((item) => item.userType === filter.userType);
    setFilteredEmailSchedules(_filteredEmailSchedules);

  }, [emailSchedules, filter]);

  return (
    <div className="container py-4">
      <div className="d-flex justify-content-between gap-2 mb-2 flex-wrap">
        <div className={isMobile ? "w-100" : "w-50"}>
          <SearchBar placeholder="Search by subject" value={search} onChange={(e) => handleSearchSchedule(e.target.value)} className="w-100" />
        </div>
        <div className={`d-flex justify-content-between gap-2 ${isMobile ? "w-100" : "w-auto"} `}>
          <IconButton onClick={() => getEmailSchedules()}><VscDebugRestart className="p-0 text-primary" size={22} /></IconButton>
          <IconButton onClick={() => handleReStore()}>
            {isResort ? <FaSortAmountUp className="p-0 text-primary" size={22} /> : <FaSortAmountDownAlt className="p-0 text-primary" size={22} />}

          </IconButton>
          <SelectField data={USER_TYPES_ARRAY} selectedValue={filter.userType} handleChangeSelect={handleChangeUserType} />
          <Button onClick={handleClickAddBtn}>
            <FiPlus size={16} className="mr-2 p-0" /><span>Add</span>
          </Button >
        </div>
      </div>
      <div className="schedule-list">
        {filteredEmailSchedules.map((schedule, index) => (
          <ScheduleCard
            key={schedule._id}
            schedule={schedule}
            index={index}
            onMoveUp={handleMoveUp}
            onMoveDown={handleMoveDown}
            onEdit={handleEdit}
            onDelete={handleDelete}
            onDuplicate={handleDuplicate}
            onEnable={handleEnable}
            isFirst={index === 0}
            isLast={index === filteredEmailSchedules.length - 1}
          />
        ))}
      </div>
      <AddScheduleModal
        show={showModal}
        handleClose={handleClose}
        isEditMode={isEditMode}
        emailSchedules={emailSchedules}
        setEmailSchedules={setEmailSchedules}
        selectedItem={selectedItem}
        filter={filter}
      />
      <RemoveModal
        isRemoving={isRemoving}
        show={showRemoveModal}
        handleClose={handleRemoveModalClose}
        handleDeleteItem={handleDeleteItem}
        selectedItem={selectedItem}
        subject=""
        icon={<RiCalendarScheduleLine size={20} />}
      />
      <DuplicateModal
        isDuplicating={isDuplicating}
        show={showDuplicateModal}
        handleClose={handleDuplicateModalClose}
        handleDuplicateItem={handleDuplicateItem}
        selectedItem={selectedItem}
        subject=""
        icon={<RiCalendarScheduleLine size={20} />}
      />
      <EnableModal
        isEnabling={isEnabling}
        show={showEnableModal}
        handleClose={handleEnableModalClose}
        handleEnableItem={handleEnableItem}
        selectedItem={selectedItem}
        subject=""
        icon={<RiCalendarScheduleLine size={20} />}
      />
    </div>
  );
};

export default ScheduleList;