/* eslint-disable operator-linebreak */
import toastMessage from "../../functions/toastMessage";

export const COUNTRIES = {
  AU: 'Australia',
  US: 'United States'
}

export const billing_data = {
  "United States": [
    {
      Option: "Option 1",
      description: "10 Day Free Trial",
    },
    {
      Option: "Option 2",
      description: "1 Year - US$179.52 (= $14.96/month x 12 months)",
    },
    {
      Option: "Option 3",
      description: "2 Year - US$341.28 (= $14.22/month x 24 months)",
    },
    {
      Option: "Option 4",
      description: "3 Year - US$484.92 (= $13.47/month x 36 months)",
    },
  ],
  Australia: [
    {
      Option: "Option 1",
      description: " 10 Day Free Trial",
    },
    {
      Option: "Option 2",
      description: " 1 Year - AU$264.00 (= $22.00/month x 12 months)",
    },
    {
      Option: "Option 3",
      description: " 2 Years - AU$462.00  (= $20.90/month x 24 months)",
    },
    {
      Option: "Option 4",
      description: " 3 Years - AU$594.00  (= $19.80/month x 36 months)",
    },
  ],
};
export const billing_rules = {
  "United States": [
    {
      duration: 0,
      includedPools: 1,
      includedUsers: 2,
      cost: 0,
      description: "10 Day Free Trial (= no cost)",
    },
    {
      duration: 12,
      includedPools: 1,
      includedUsers: 2,
      cost: 179.52,
      description: "1 Year - $179.52 (= 12 months pre-paid)",
    },
    {
      duration: 24,
      includedPools: 1,
      includedUsers: 2,
      cost: 341.28,
      description: "2 Year - $341.28 (= 24 months pre-paid)",
    },
    {
      duration: 36,
      includedPools: 1,
      includedUsers: 2,
      cost: 484.92,
      description: "3 Year - $484.92 (= 36 months pre-paid)",
    },
  ],
  Australia: [
    {
      duration: 0,
      includedPools: 1,
      includedUsers: 2,
      cost: 0,
      description: "10 Day Free Trial (= no cost)",
    },
    {
      duration: 12,
      includedPools: 1,
      includedUsers: 2,
      cost: 264.0,
      description: " 1 Year - AU$264.00 (= 12 months pre-paid)",
    },
    {
      duration: 24,
      includedPools: 1,
      includedUsers: 2,
      cost: 462.0,
      description: " 2 Years - AU$462.00  (= 24 months pre-paid)",
    },
    {
      duration: 36,
      includedPools: 1,
      includedUsers: 2,
      cost: 594.0,
      description: " 3 Years - AU$594.00  (= 36 months pre-paid)",
    },
  ],
};

export const billing_pools = {
  Australia: [
    {
      num: 1,
      description: "1 Pool - Included",
      price: 0,
    },
    {
      num: 2,
      description: "2 Pools - AU$5.00 per month",
      price: 5.0,
    },
    {
      num: 3,
      description: "3 Pools - AU$10.00 per month",
      price: 10.0,
    },
    {
      num: 4,
      description: "4 Pools - AU$15.00 per month",
      price: 15.0,
    },
    {
      num: 5,
      description: "5 Pools - AU$20.00 per month",
      price: 20.0,
    },
  ],
  "United States": [
    {
      num: 1,
      description: "1 Pool - Included",
      price: 0,
    },
    {
      num: 2,
      description: "2 Pools - US$3.35 per month",
      price: 3.35,
    },
    {
      num: 3,
      description: "3 Pools - US$6.70 per month",
      price: 6.70,
    },
    {
      num: 4,
      description: "4 Pools - US$10.05 per month",
      price: 10.05,
    },
    {
      num: 5,
      description: "5 Pools - US$13.40 per month",
      price: 13.40,
    },
  ],
};

export const billing_users = {
  Australia: [
    {
      num: 2,
      description: "2 Users - Included",
      price: 0,
    },
    {
      num: 3,
      description: "3 Users - AU$5.00 per month",
      price: 5.0,
    },
    {
      num: 4,
      description: "4 Users - AU$10.00 per month",
      price: 10.0,
    },
    {
      num: 5,
      description: "5 Users - AU$15.00 per month",
      price: 15.0,
    },
  ],
  "United States": [
    {
      num: 2,
      description: "2 Users - Included",
      price: 0,
    },
    {
      num: 3,
      description: "3 Users - US$3.35 per month",
      price: 3.35,
    },
    {
      num: 4,
      description: "4 Users - US$6.70 per month",
      price: 6.70,
    },
    {
      num: 5,
      description: "5 Users - US$10.05 per month",
      price: 10.05,
    },
  ],
};

export const durationCheck = (durationTime, duration, endsubscriptionDate) => {
  const condition =
    durationTime !== duration && new Date(endsubscriptionDate) > Date.now();
  if (condition) {
    toastMessage(
      "error",
      "You cannot change the subscription before Expiration Date",
    );
    return false;
  }
  return true;
};

export const poolCheck = (pools, oldPools, endsubscriptionDate) => {
  const condition =
    pools < oldPools && new Date(endsubscriptionDate) > Date.now();
  if (condition) {
    toastMessage(
      "error",
      "You cannot downgrade the Number of Pools before Expiration Date",
    );
    return false;
  }
  return true;
};

export const userCheck = (user, oldUsers, endsubscriptionDate) => {
  const condition =
    user < oldUsers && new Date(endsubscriptionDate) > Date.now();
  if (condition) {
    toastMessage(
      "error",
      "You cannot downgrade the Number of Users before Expiration Date",
    );
    return false;
  }
  return true;
};

export const checkRemainTime = (endDate) => {
  const endDateObject = new Date(endDate);
  const currentDate = new Date();
  // +1 was added in order to include the current month also
  const monthsDiff =
    (endDateObject.getFullYear() - currentDate.getFullYear()) * 12 +
    (endDateObject.getMonth() - currentDate.getMonth());

  return monthsDiff;
};

export const calculateTotalCost = (baseCost, remainDuration, poolNum, userNum, countryName) => {
  let finalCost = 0;

  let userCost = billing_users[countryName][0].price;
  if (Number(userNum) > 1) {
    userCost = billing_users[countryName].find((item) => item.num === Number(userNum))?.price;
  }
  let poolCost = billing_pools[countryName][0].price;
  if (Number(poolNum) > 0) {
    poolCost = billing_pools[countryName].find((item) => item.num === Number(poolNum))?.price;
  }

  let extraUserCost = userCost * Number(remainDuration);
  let extraPoolCost = poolCost * Number(remainDuration);

  finalCost = parseFloat(baseCost + extraPoolCost + extraUserCost).toFixed(2);

  return finalCost;
}


export const USER_TYPES = {
  allUsers: 'allUsers',
  currentSubscribers: 'currentSubscribers',
  allCurrentTrialUsers: 'allCurrentTrialUsers',
  currentTrialUsersWithCard: 'currentTrialUsersWithCard',
  currentTrialUsersNoCard: 'currentTrialUsersNoCard',
  allUnsubscribedTrialUsers: 'allUnsubscribedTrialUsers',
  unsubscribedTrialUsersWithCard: 'unsubscribedTrialUsersWithCard',
  unsubscribedTrialUsersNoCard: 'unsubscribedTrialUsersNoCard',
  unsubscribedSubscribers: 'unsubscribedSubscribers'
}

export const SUBSCRIPTION_PAGE_FILTER_OBJECT = {
  allUsers: {
    id: 'b7G3zXl9KqH2mP5',
    title: 'All Users',
    searchValue: USER_TYPES.allUsers
  },
  currentSubscribers: {
    id: 'Xy7vG9bQ3tP1kL2m',
    title: 'Current Subscribers',
    searchValue: USER_TYPES.currentSubscribers
  },
  allCurrentTrialUsers: {
    id: 'zM9kL1uQ4tXy5jWb',
    title: 'All Current Trial Users',
    searchValue: USER_TYPES.allCurrentTrialUsers
  },
  currentTrialUsersWithCard: {
    id: 'X3pTq9eB2nV8mL0f',
    title: 'Current Trial Users (With Card)',
    searchValue: USER_TYPES.currentTrialUsersWithCard
  },
  currentTrialUsersNoCard: {
    id: 'A8fKjB4xQwT7sD5v',
    title: 'Current Trial Users (No Card)',
    searchValue: USER_TYPES.currentTrialUsersNoCard
  },
  allUnsubscribedTrialUsers: {
    id: 'U6nVjF2eL8pQzR9h',
    title: 'All Unsubscribed Trial Users',
    searchValue: USER_TYPES.allUnsubscribedTrialUsers
  },
  unsubscribedTrialUsersWithCard: {
    id: 'P0mYkN5sG3hB8tW7',
    title: 'Unsubscribed Trial Users (With Card)',
    searchValue: USER_TYPES.unsubscribedTrialUsersWithCard
  },
  unsubscribedTrialUsersNoCard: {
    id: 'iF6vJ8zU5sA1pQ2y',
    title: 'Unsubscribed Trial Users (No Card)',
    searchValue: USER_TYPES.unsubscribedTrialUsersNoCard
  },
  unsubscribedSubscribers: {
    id: 'oP4rL9yW3uN6eQ7v',
    title: 'Unsubscribed Subscribers',
    searchValue: USER_TYPES.unsubscribedSubscribers
  },
}

export const SUBSCRIPTION_PAGE_FILTER_ARRAY = [
  {
    id: 'Xy7vG9bQ3tP1kL2m',
    title: 'Current Subscribers',
    searchValue: USER_TYPES.currentSubscribers
  },
  {
    id: 'zM9kL1uQ4tXy5jWb',
    title: 'All Current Trial Users',
    searchValue: USER_TYPES.allCurrentTrialUsers
  },
  {
    id: 'X3pTq9eB2nV8mL0f',
    title: 'Current Trial Users \(With Card\)',
    searchValue: USER_TYPES.currentTrialUsersWithCard
  },
  {
    id: 'A8fKjB4xQwT7sD5v',
    title: 'Current Trial Users (No Card)',
    searchValue: USER_TYPES.currentTrialUsersNoCard
  },
  {
    id: 'U6nVjF2eL8pQzR9h',
    title: 'All Unsubscribed Trial Users',
    searchValue: USER_TYPES.allUnsubscribedTrialUsers
  },
  {
    id: 'P0mYkN5sG3hB8tW7',
    title: 'Unsubscribed Trial Users (With Card)',
    searchValue: USER_TYPES.unsubscribedTrialUsersWithCard
  },
  {
    id: 'iF6vJ8zU5sA1pQ2y',
    title: 'Unsubscribed Trial Users (No Card)',
    searchValue: USER_TYPES.unsubscribedTrialUsersNoCard
  },
  {
    id: 'oP4rL9yW3uN6eQ7v',
    title: 'Unsubscribed Subscribers',
    searchValue: USER_TYPES.unsubscribedSubscribers
  },
]
