import React from "react";
import { FaSearch } from "react-icons/fa";

export const Button = ({ children, onClick }) => {
  return (
    <button
      type="button"
      className="btn btn-outline-secondary bg-white text-black"
      style={{
        padding: '0 16px',
        borderRadius: '0.5rem',
        fontSize: "14px",
        minWidth: "fit-content",
      }}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export const NumberFieldinlineLabel = (props) => {
  const { label, inputProps } = props;

  return (
    <div className="d-flex align-items-center gap-2">
      <div>
        <input
          {...inputProps}
          type="text"
          style={{
            fontSize: "14px",
            borderRadius: "4px",
            border: "1px solid #ccc",
            padding: "0px 4px 0px 4px",
            textAlign: 'end',
            width: '100%'
          }}
        />
      </div>
      <div>
        <span
          style={{ fontSize: "14px" }}
        >
          {label}
        </span>
      </div>
    </div>
  );
};

export const IconButton = ({ children, onClick }) => {
  return (
    <button
      type="button"
      style={{
        background: 'transparent',
        border: "none"
      }}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export const SearchBar = ({ placeholder = "Search...", value, onChange, className = "" }) => {
  return (
    <div className={`input-group ${className}`}>
      <div className="input-group-prepend">
        <span className="input-group-text bg-white border-right-0" style={{ height: '36px', padding: '0.5rem', borderRadius: '0.8rem 0 0 0.8rem' }}>
          <FaSearch className="text-muted" />
        </span>
      </div>
      <input
        type="text"
        className="form-control border-left-0"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        style={{ height: '36px', boxShadow: 'none', padding: '0.5rem', borderRadius: '0 0.8rem 0.8rem 0', fontSize: '1rem' }}
      />
    </div>
  );
};

export const Select = ({ value, onChange, children }) => (
  <select
    value={value}
    onChange={onChange}
    className="w-full"
    style={{ padding: '0.4rem', boxShadow: 'none', borderRadius: '0.5rem' }}
  >
    {children}
  </select>
);

