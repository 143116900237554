import { USER_TYPES } from "../subscription/utils";

// Settings Tabs
export const SCHEDULE_TAB = "schedule_tab";
export const STATUS_TRACKER_TAB = "status_tacker_tab";

export const USER_TYPES_ARRAY = [
  {
    id: "a8yR9Xv3Qs",
    label: 'All Users',
    value: USER_TYPES.allUsers
  },
  {
    id: "j9B4Kz2wMn",
    label: "Current Subscribers",
    value: USER_TYPES.currentSubscribers
  },
  {
    id: "7uL0pV5qHt",
    label: 'Current Trial Users (With Card)',
    value: USER_TYPES.currentTrialUsersWithCard
  },
  {
    id: "f5E3qJ2sXr",
    label: 'Unsubscribed Trial Users (With Card)',
    value: USER_TYPES.unsubscribedTrialUsersWithCard
  },
  {
    id: "n4Z8mU1aCy",
    label: 'Current Trial Users (No Card)',
    value: USER_TYPES.currentTrialUsersNoCard
  },
  {
    id: "Q1v9Yb4kFp",
    label: 'Unsubscribed Trial Users (No Card)',
    value: USER_TYPES.unsubscribedTrialUsersNoCard
  },
  {
    id: "e8M0hS7wDr",
    label: "Unsubscribed Subscriber",
    value: USER_TYPES.unsubscribedSubscribers
  }
]

export const SCHEDULE_TYPES = {
  IMMEDIATELY: 0,
  DELAY_AFTER_SPECIFIC_EVENT_DATE: 1,
  TIME_AFTER_X_DAYS_AFTER_SPECIFIC_EVENT_DATE: 2,
  BEFORE_SPECIFIC_EVENT_DATE: 3,
  TIME_BEFORE_X_DAYS_BEFORE_SPECIFIC_EVENT_DATE: 4
};

export const SCHEDULE_TYPE_LABELS = {
  [SCHEDULE_TYPES.IMMEDIATELY]: "Immediately",
  [SCHEDULE_TYPES.DELAY_AFTER_SPECIFIC_EVENT_DATE]: "Delay after specific event date",
  [SCHEDULE_TYPES.TIME_AFTER_X_DAYS_AFTER_SPECIFIC_EVENT_DATE]: "Exact time after X days from specific event date",
  [SCHEDULE_TYPES.BEFORE_SPECIFIC_EVENT_DATE]: "Before specific event date",
  [SCHEDULE_TYPES.TIME_BEFORE_X_DAYS_BEFORE_SPECIFIC_EVENT_DATE]: "Exact time before X days from specific event date",
};

export const SCHEDULE_TYPE_ARRAY = [
  {
    id: 'a7fK9zL1bQ',
    label: SCHEDULE_TYPE_LABELS[SCHEDULE_TYPES.IMMEDIATELY],
    value: SCHEDULE_TYPES.IMMEDIATELY
  },
  {
    id: 'Lm8Zp6bWQr',
    label: SCHEDULE_TYPE_LABELS[SCHEDULE_TYPES.DELAY_AFTER_SPECIFIC_EVENT_DATE],
    value: SCHEDULE_TYPES.DELAY_AFTER_SPECIFIC_EVENT_DATE
  },
  {
    id: 'yT5f2sD8Hk',
    label: SCHEDULE_TYPE_LABELS[SCHEDULE_TYPES.TIME_AFTER_X_DAYS_AFTER_SPECIFIC_EVENT_DATE],
    value: SCHEDULE_TYPES.TIME_AFTER_X_DAYS_AFTER_SPECIFIC_EVENT_DATE
  },
  {
    id: 'V7jQ1Fw0Xz',
    label: SCHEDULE_TYPE_LABELS[SCHEDULE_TYPES.BEFORE_SPECIFIC_EVENT_DATE],
    value: SCHEDULE_TYPES.BEFORE_SPECIFIC_EVENT_DATE
  },
  {
    id: 'h6U9t2Gx4B',
    label: SCHEDULE_TYPE_LABELS[SCHEDULE_TYPES.TIME_BEFORE_X_DAYS_BEFORE_SPECIFIC_EVENT_DATE],
    value: SCHEDULE_TYPES.TIME_BEFORE_X_DAYS_BEFORE_SPECIFIC_EVENT_DATE
  }
];

export const EVENT_TYPES = {
  USER_CREATED: 0,
  USER_FIRST_LOGIN: 1,
  SUBSCRIPTION_STARTED: 2,
  SUBSCRIPTION_ENDED: 3,
  SUBSCRIPTION_CANCELED: 4,
};

export const EVENT_TYPE_LABELS = {
  [EVENT_TYPES.USER_CREATED]: "User Created",
  [EVENT_TYPES.USER_FIRST_LOGIN]: "User First Login",
  [EVENT_TYPES.SUBSCRIPTION_STARTED]: "Subscription Started",
  [EVENT_TYPES.SUBSCRIPTION_ENDED]: "Subscription Ended",
  [EVENT_TYPES.SUBSCRIPTION_CANCELED]: "Subscription Canceled",
};

export const EVENT_TYPE_ARRAY = [
  {
    id: 'a1B2c3D4e5',
    label: EVENT_TYPE_LABELS[EVENT_TYPES.USER_CREATED],
    value: EVENT_TYPES.USER_CREATED,
  },
  {
    id: 'f6G7h8I9j0',
    label: EVENT_TYPE_LABELS[EVENT_TYPES.USER_FIRST_LOGIN],
    value: EVENT_TYPES.USER_FIRST_LOGIN,
  },
  {
    id: 'k1L2m3N4o5',
    label: EVENT_TYPE_LABELS[EVENT_TYPES.SUBSCRIPTION_STARTED],
    value: EVENT_TYPES.SUBSCRIPTION_STARTED,
  },
  {
    id: 'p6Q7r8S9t0',
    label: EVENT_TYPE_LABELS[EVENT_TYPES.SUBSCRIPTION_ENDED],
    value: EVENT_TYPES.SUBSCRIPTION_ENDED,
  },
  {
    id: 'u1V2w3X4y5',
    label: EVENT_TYPE_LABELS[EVENT_TYPES.SUBSCRIPTION_CANCELED],
    value: EVENT_TYPES.SUBSCRIPTION_CANCELED,
  },
];
