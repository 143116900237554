import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import moment from "../../functions/moment";
import AdminWrapper from "../admin/adminwrapper/wrapper";
import arrow from "../../assets/home/arrowdown.png";
import { userInstance } from "../../config/axios";
import {
  showErrMsg,
  queryString,
  getCalendarPath,
  removeComma,
} from "../../functions/index";
import CalculatorSalt from "../calculator/calculatorsalt";
import CalculatorReadings from "../calculator/calculatorReadings";
import "../calculator/calculator.css";
import {
  displayCacliPrices,
  LoadExistValue,
} from "../calculator/calculatorUtils";
import Loader from "../loader/loader";

const waterTestHistory = () => {
  const initialValidationData = {
    poolWidth: false,
    poolHeight: false,
    shalowEnd: false,
    deepEnd: false,
    diameter: false,
    originalSaltLvl: false,
    bagsSaltAdded: false,
    currentSaltLevel: false,
  };

  const defaultChemicalDisplayState = {
    freeChlorine: true,
    totalChlorine: true,
    pH: true,
    totalAlkalinity: true,
    calciumHardness: true,
    stabiliser: true,
    copper: true,
    phosphates: true,
    salt: true,
  };
  const [calciDisplayChemicals, setCalciChemicalsDisplay] = useState(
    defaultChemicalDisplayState,
  );

  const historyBack = useHistory();
  const [validationData, setValData] = useState(initialValidationData);
  const [historyList, sethistoryList] = useState([]);
  const [result, setResult] = useState(false);
  const [selectedTest, setSelectedTest] = useState(null);
  // const queryParams = new URLSearchParams(window.location.search);
  const [selectedPage, setSelectedPage] = useState(1);
  const { data: Id, routeType, name, back, skipValue, appId } = queryString();
  const [skip, setSkip] = useState(0);
  const [pageCounts, setPageCounts] = useState(1);
  const limit = 30;
  const [loading, setLoading] = useState(true);
  const getWaterHistory = async () => {
    try {
      const appointId = appId || "";
      const response = await userInstance().get(
        `/watertest/getWaterHistory?limit=${limit}&skip=${skip}&appId=${appointId}`,
      );
      setLoading(false);
      const { waterList, msg, count } = response.data;
      if (msg === "Success") {
        setPageCounts(count);
        sethistoryList(waterList);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleBackPage = () => {
    if (routeType === "calendar") {
      historyBack.push(`${getCalendarPath()}&clientid=${Id}`);
    } else if (routeType === "client" || routeType?.includes("edit")) {
      if (!back) {
        historyBack.push(
          `/client?clientid=${Id}&name=${name}&typeRoute=${routeType}&skipValuee=${skipValue}`,
        );
      } else {
        historyBack.push(
          `/client?clientid=${Id}&name=${name}&typeRoute=${routeType}&back=${back}`,
        );
      }
    }
  };
  useEffect(() => {
    getWaterHistory();
  }, [skip, limit]);
  const pageCount = Math.ceil(pageCounts / limit);
  const handlePageClick = ({ selected }) => {
    setSkip(selected * limit);
    setSelectedPage(selected + 1);
  };
  const getUnit = (val) => {
    const inGrams = Math.ceil(Math.trunc(val * 1000) / 100) * 100;
    const data = val < 1 ? (inGrams === 1000 ? "kg" : "grams") : "kg";
    return data;
  };
  const getInGrams = (chemical) => {
    const inGrams = Math.ceil(Math.trunc(chemical * 1000) / 100) * 100;
    let chemicalVal =
      chemical < 1
        ? inGrams === 1000
          ? chemical.toFixed(1)
          : inGrams
        : chemical.toFixed(1);

    let unit = getUnit(chemical);

    if (parseFloat(chemicalVal) < 1) {
      chemicalVal *= 1000;
      unit = "grams";
    }

    return { unit, chemicalVal };
  };
  const handleViewWaterTest = (data) => {
    // console.log('data', data);
    const { history } = data;
    setSelectedTest(history);
    setResult(true);
    if (history?.calciDisplayChemicals) {
      setCalciChemicalsDisplay(history?.calciDisplayChemicals);
    } else {
      setCalciChemicalsDisplay(defaultChemicalDisplayState);
    }
  };
  const handleInputs = () => false;
  const handleBackFromPrint = () => {
    setSelectedTest("");
    setResult(false);
  };

  const updateMsg = (calMessage, message, type, extraValue) => {
    let mes = message;
    if (!message && extraValue) {
      mes = extraValue;
    }
    if (type === "Add") {
      const str = mes?.replace("Add", "");
      mes = str;
    }
    if (calMessage?.includes("-")) {
      mes = calMessage;
    }
    return mes;
  };
  return (
    <AdminWrapper>
      {!result && !selectedTest ? (
        loading ? (
          <Loader />
        ) : (
          <div className=" pool-set-up invoices-list water-history">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="back-option">
                    <span />
                    {Id && (
                      <i
                        className="fa fa-times"
                        aria-hidden="true"
                        onClick={handleBackPage}
                      />
                    )}
                  </div>
                  <div className="main-titles-mob">
                    <h3>Water Test History</h3>
                  </div>

                  {historyList?.length > 0 ? (
                    <div className="invoices-table pool-chemistry-box">
                      {historyList &&
                        historyList.map((el, index) => {
                          let { createdAt, history } = el || {};
                          let {
                            calVariable,
                            poolInputs,
                            msg,
                            calciDisplayChemicals: caliDisplay,
                          } = history || {};
                          let {
                            alkalinity,
                            calciumHardness,
                            chlorine,
                            combinedChlorine,
                            ph,
                            phosphates,
                            salt,
                            stabiliser,
                            copper,
                          } = poolInputs || {};
                          let {
                            costPh,
                            costChroline,
                            costPhophates,
                            costStabiliser,
                            costAlkalinity,
                            granularChlorine,
                            sodiumThiosulphate,
                            zodiacOxy,
                            costCombinedChlorine,
                            hydrochloricAcidPh,
                            sodaAsh,
                            buffer,
                            hydrochloricAcidAlk,
                            calciumRaiser,
                            costCalcium,
                            addStabiliser,
                            phosphateRemover,
                            addSalt,
                            costSalt,
                            costCopper,
                            costSaltConvert,
                            costAlgaecide,
                            costClarifierMain,
                            costFlock,
                          } = calVariable || calVariable;
                          let {
                            chlorineMsg,
                            combinedChlorineMsg,
                            phMsg,
                            alkMsg,
                            calciumHardnessMsg,
                            stabiliserMsg,
                            phosphateMsg,
                            saltMsg,
                            copperMsg,
                            algaecideMsg,
                            clarifierMsg,
                            flockMsg,
                            saltMsgConvert,
                          } = msg || {};
                          const stabiliserarr = stabiliserMsg
                            .replace("Add", "")
                            .split(" ");
                          stabiliserMsg = `${stabiliserarr[1]} ${stabiliserarr[2] === "grams" ? "g" : stabiliserarr[2]} CYA`;

                          chlorineMsg = updateMsg(
                            calVariable?.chlorineMsg,
                            chlorineMsg,
                            "Add",
                            sodiumThiosulphate || granularChlorine,
                          );
                          combinedChlorineMsg = updateMsg(
                            calVariable?.combinedChlorineMsg,
                            combinedChlorineMsg,
                            "Add",
                          );
                          phMsg = updateMsg(
                            calVariable?.phMsg,
                            phMsg,
                            "Add",
                          );
                          alkMsg = updateMsg(
                            calVariable?.alkMsg,
                            alkMsg,
                            "Add",
                          );
                          calciumHardnessMsg = updateMsg(
                            calVariable?.calciumHardnessMsg,
                            calciumHardnessMsg,
                          );
                          stabiliserMsg = updateMsg(
                            calVariable?.stabiliserMsg,
                            stabiliserMsg,
                            "",
                          );
                          phosphateMsg = updateMsg(
                            calVariable?.phosphateMsg,
                            phosphateMsg,
                            "Add",
                          );
                          saltMsg = updateMsg(
                            calVariable?.saltMsg,
                            saltMsg,
                            "Add",
                          );
                          copperMsg = updateMsg(
                            calVariable?.copperMsg,
                            copperMsg,
                            "Add",
                          );
                          algaecideMsg = updateMsg(
                            calVariable?.algaecideMsg,
                            algaecideMsg,
                            "Add",
                          );
                          clarifierMsg = updateMsg(
                            calVariable?.clarifierMsg,
                            clarifierMsg,
                            "Add",
                          );
                          flockMsg = updateMsg(
                            calVariable?.flockMsg,
                            flockMsg,
                            "Add",
                          );
                          saltMsgConvert = updateMsg(
                            calVariable?.saltMsgConvert,
                            saltMsgConvert,
                            "Add",
                          );

                          const totalCost =
                            LoadExistValue(removeComma(costChroline)) +
                            LoadExistValue(removeComma(costPh)) +
                            LoadExistValue(removeComma(costAlkalinity)) +
                            LoadExistValue(removeComma(costCalcium)) +
                            LoadExistValue(removeComma(costStabiliser)) +
                            LoadExistValue(removeComma(costSalt)) +
                            LoadExistValue(removeComma(costSaltConvert)) +
                            LoadExistValue(removeComma(costCombinedChlorine)) +
                            LoadExistValue(removeComma(costAlgaecide)) +
                            LoadExistValue(removeComma(costClarifierMain)) +
                            LoadExistValue(removeComma(costFlock)) +
                            LoadExistValue(removeComma(costCopper));

                          calVariable.totalCost = totalCost;

                          return (
                            <>
                              <div className="water-history-table">
                                <div className="water-table-date">
                                  {moment(createdAt).format("DD")}
                                  <br />
                                  {moment(createdAt).format("MMM")} <br />
                                  <span>
                                    {moment(createdAt).format("YYYY")}
                                  </span>
                                </div>
                                <div className="table-section">
                                  <div className="invoices-table-left">
                                    <div className="invoices-head">
                                      <h6>Chemical</h6>
                                      <h6>Reading</h6>
                                      <h6>Quantity Added</h6>
                                      <h6>Cost</h6>
                                    </div>
                                    <div className="invoice-body">
                                      <p>Free CI</p>
                                      <p>{chlorine || "0"}</p>
                                      <p>{chlorineMsg}</p>
                                      <p>
                                        $
                                        {displayCacliPrices(
                                          removeComma(costChroline),
                                          "",
                                        )}
                                      </p>
                                    </div>
                                    {caliDisplay?.combinedChlorine !==
                                      false && (
                                      <div className="invoice-body">
                                        <p>Comb CI</p>
                                        <p>{combinedChlorine || "0"}</p>
                                        <p>
                                          {/* {zodiacOxy}
                              g Gran CI */}
                                          {combinedChlorineMsg}
                                        </p>
                                        <p>
                                          $
                                          {displayCacliPrices(
                                            removeComma(costCombinedChlorine),
                                            "",
                                          )}
                                        </p>
                                      </div>
                                    )}
                                    <div className="invoice-body">
                                      <p>pH</p>
                                      <p>{ph || "7.5"}</p>
                                      <p>{phMsg}</p>
                                      <p>
                                        $
                                        {displayCacliPrices(
                                          removeComma(costPh),
                                          "",
                                        )}
                                      </p>
                                    </div>
                                    <div className="invoice-body">
                                      <p>Total Alk</p>
                                      <p>{alkalinity || "0"}</p>
                                      <p>
                                        {/* {buffer || hydrochloricAcidAlk}
                              g Gran CI */}
                                        {alkMsg}
                                      </p>
                                      <p>
                                        $
                                        {displayCacliPrices(
                                          removeComma(costAlkalinity),
                                          "",
                                        )}
                                      </p>
                                    </div>
                                    {caliDisplay?.calciumHardness !== false && (
                                      <div className="invoice-body">
                                        <p>Hard</p>
                                        <p>{calciumHardness || "0"}</p>
                                        <p>
                                          {/* {calciumRaiser}
                              g Gran CI */}
                                          {calciumHardnessMsg}
                                        </p>
                                        <p>
                                          $
                                          {displayCacliPrices(
                                            removeComma(costCalcium),
                                            "",
                                          )}
                                        </p>
                                      </div>
                                    )}
                                    <div className="invoice-body">
                                      <p>Stabliser</p>
                                      <p>{stabiliser || "0"}</p>
                                      <p>
                                        {/* {addStabiliser}
                              g Gran CI */}

                                        {stabiliserMsg}
                                      </p>
                                      <p>
                                        $
                                        {displayCacliPrices(
                                          removeComma(costStabiliser),
                                          "",
                                        )}
                                      </p>
                                    </div>
                                    {caliDisplay?.copper !== false && (
                                      <div className="invoice-body">
                                        <p>Copper</p>
                                        <p>{copper || "0"}</p>
                                        <p>{copperMsg}</p>
                                        <p>
                                          $
                                          {displayCacliPrices(
                                            removeComma(costCopper),
                                            "",
                                          )}
                                        </p>
                                      </div>
                                    )}
                                    {caliDisplay?.phosphates !== false && (
                                      <div className="invoice-body">
                                        <p>Phos</p>
                                        <p>{phosphates || "0"}</p>
                                        <p>
                                          {/* {phosphateRemover || phosphateMsg}
                              g Gran CI */}
                                          {phosphateMsg}
                                        </p>
                                        <p>
                                          $
                                          {displayCacliPrices(
                                            removeComma(costPhophates),
                                            "",
                                          )}
                                        </p>
                                      </div>
                                    )}
                                    {caliDisplay?.salt !== false && (
                                      <div className="invoice-body">
                                        <p>Salt</p>
                                        <p>{salt || "0"}</p>
                                        <p>
                                          {/* {addSalt}
                              g Gran CI */}
                                          {saltMsg}
                                        </p>
                                        <p>
                                          $
                                          {displayCacliPrices(
                                            removeComma(costSalt),
                                            "",
                                          )}
                                        </p>
                                      </div>
                                    )}
                                    {saltMsgConvert ? (
                                      <div className="invoice-body">
                                        <p>Aquatherepe</p>
                                        <p>4000</p>
                                        <p>
                                          {/* {addSalt}
                                g Gran CI */}
                                          {saltMsgConvert}
                                        </p>
                                        <p>
                                          $
                                          {displayCacliPrices(
                                            removeComma(costSaltConvert),
                                            "",
                                          )}
                                        </p>
                                      </div>
                                    ) : null}
                                    {clarifierMsg ? (
                                      <div className="invoice-body">
                                        <p>Clarifier</p>
                                        <p>N/A</p>
                                        <p>
                                          {/* {addSalt}
                                g Gran CI */}
                                          {clarifierMsg}
                                        </p>
                                        <p>
                                          $
                                          {displayCacliPrices(
                                            removeComma(costClarifierMain),
                                            "",
                                          )}
                                        </p>
                                      </div>
                                    ) : null}
                                    {algaecideMsg ? (
                                      <div className="invoice-body">
                                        <p>Algaecide</p>
                                        <p>N/A</p>
                                        <p>
                                          {/* {addSalt}
                                g Gran CI */}
                                          {algaecideMsg}
                                        </p>
                                        <p>
                                          $
                                          {displayCacliPrices(
                                            removeComma(costAlgaecide),
                                            "",
                                          )}
                                        </p>
                                      </div>
                                    ) : null}
                                    {flockMsg ? (
                                      <div className="invoice-body">
                                        <p>Flock</p>
                                        <p>N/A</p>
                                        <p>
                                          {/* {addSalt}
                                g Gran CI */}
                                          {flockMsg}
                                        </p>
                                        <p>
                                          $
                                          {displayCacliPrices(
                                            removeComma(costFlock),
                                            "",
                                          )}
                                        </p>
                                      </div>
                                    ) : null}
                                    <div className="history-total">
                                      <h6>
                                        Total: $
                                        {(
                                          parseFloat(
                                            removeComma(totalCost),
                                            "",
                                          ) || 0
                                        ).toFixed(2)}
                                      </h6>
                                    </div>
                                  </div>
                                  <div
                                    className="invoices-table-right"
                                    onClick={() => handleViewWaterTest(el)}
                                  >
                                    <img src={arrow} alt="arrow" />
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      <div className="user-pagination">
                        <ReactPaginate
                          previousLabel="← Previous"
                          nextLabel="Next →"
                          onPageChange={handlePageClick}
                          containerClassName="pagination-chk"
                          previousLinkClassName="pagination__link"
                          nextLinkClassName="pagination__link"
                          disabledClassName="pagination__link--disabled"
                          activeClassName="pagination__link--active"
                          // marginPagesDisplayed={-2}
                          // pageRangeDisplayed={0}
                          pageCount={pageCount}
                          forcePage={selectedPage - 1}
                        />
                        <div className="no-of-page">
                          Page {selectedPage} of {pageCount}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="invoices-table pool-chemistry-box">
                      <div className="placehoder-text">
                        No Water History Found
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <>
          <Helmet>
            <html className="calculator-main" lang="en" />
          </Helmet>
          <div
            className={`content-container bg-lightblue calculator-container ${
              result ? "page-two" : ""
            }`}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12-desktop">
                  <div className="back-option calc-option">
                    <span />
                    {/* <Link> */}
                    <i
                      className="fa fa-times"
                      aria-hidden="true"
                      onClick={handleBackFromPrint}
                    />
                    {/* </Link> */}
                  </div>
                  <div className="form-container display">
                    <div className="title">
                      <h3>Water Test History</h3>
                    </div>
                    <form>
                      <CalculatorReadings
                        calVariable={selectedTest?.calVariable}
                        poolInputs={selectedTest?.poolInputs}
                        chlorinationType={selectedTest?.chlorinationType}
                        serviceAnalysis={selectedTest?.serviceAnalysis}
                        manualValue={selectedTest?.manualValue}
                        waterTestOptions={selectedTest?.waterTestOptions}
                        handleInputs={handleInputs}
                        result={result}
                        greenSplitName={selectedTest?.greenSplitName}
                        validationData={validationData}
                        calciDisplayChemicals={calciDisplayChemicals}
                      />
                    </form>
                    <CalculatorSalt
                      calVariable={selectedTest?.calVariable}
                      poolInputs={selectedTest?.poolInputs}
                      chlorinationType={selectedTest?.chlorinationType}
                      getInGrams={getInGrams}
                      getUnit={getUnit}
                      serviceAnalysis={selectedTest?.serviceAnalysis}
                      manualValue={selectedTest?.manualValue}
                      waterTestOptions={selectedTest?.waterTestOptions}
                      selectedModel={selectedTest?.selectedModel}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </AdminWrapper>
  );
};
export default waterTestHistory;
